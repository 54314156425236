import React, { useState, useEffect } from 'react'
import MyProfileSection from '../MyProfileSection'

const MyProfileMyInterests = ({
    allAnswers=[],
    clickable=false,
    handleChipClick
}) => {
    const [header, setHeader] = useState('My Interests')
    const [chips, setChips] = useState([])
    let identifiers =["15", "19"]

    const getDetailsByIdentifiers = ()=>{
        let arrChips = [];
        for(let j=0; j<identifiers.length;j++){
            let identifier = identifiers[j]
            let chipsObj = null;
            allAnswers.forEach(answerItem => {
                if(answerItem?.answer?.question?.identifier === identifier){
                    chipsObj = {};
                    chipsObj.label = answerItem?.answer?.value;
                    chipsObj.QuestionID = answerItem?.answer?.question?.id
                    chipsObj.identifier = identifier
                    chipsObj.clickable = clickable
                    chipsObj.type = 'Questions'
                    
                }
            });
            if(chipsObj){
                arrChips.push(chipsObj)
            }
        }
        setChips(arrChips)
    }
    useEffect(()=>{
        if(allAnswers && allAnswers?.length>0){
            getDetailsByIdentifiers()
        }
        
    },[allAnswers])
    return (
        <> 
        {chips && chips.length > 0 && (
            <MyProfileSection
            heading={header}
            description=''
            arrChips={chips}
            handleChipClick={handleChipClick}
            />
        )}
        </>
     );
}
 
export default MyProfileMyInterests;