import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputField from '../../atoms/InputField';
import {
  ButtonType,
  ButtonVariant,
  CustomButton,
} from '../../atoms/CustomButton';
import { usePostShareApp } from '../../services/mutations';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import Loader from '../../molecule/Loader';
import CustomAlert from '../../atoms/CustomAlert';
import { useNavigate } from 'react-router-dom';
import close_icon from '../../assets/close_icon.svg';

import { routeUrls } from '../../utils/constants';
const ShareApp = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const shareApp = usePostShareApp();
  const nav = useNavigate();
  const handleInput = (evt) => {
    setError('');
    onEmailChange(evt.target.value);
  };
  const ValidateEmail = (email) => {
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };
  const onEmailChange = (_email) => {
    setEmail(_email);
    if (!ValidateEmail(_email)) {
      setError('Please provide a valid email');
    }
  };
  const onAlertClose = () => {
    setAlertMsg('');
    setError('');
    setEmail('');
  };
  const handleShareApp = () => {
    if (email.length <= 0) {
      setError('Email is required field!');
    } else {
      setLoading(true);
      let data = {
        email: email,
        invitee_name: 'Friend',
      };

      shareApp.mutate(data, {
        onSuccess: (response) => {
          setLoading(false);
          setAlertMsg('Your Invitation has been sent successfully.');
          console.log('response', response);
        },
        onError: (error) => {
          setLoading(false);
          setError(error?.error || 'Something went wrong. Please try again.');
        },
      });
    }
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      <Grid container>
        <Grid
          container
          xs={12}
          sx={{
            mt: isWideScreen ? 20 : 14,
            color: '#444444',
            display: 'flex',
            justifyContent: 'center', // Center content within the parent grid
            position: 'relative', // Allows for absolute positioning
          }}>
          <Grid item>
            <Typography className={styles.shareApp}>Share App</Typography>
          </Grid>

          <Grid
            item
            sx={{
              position: 'absolute', // Position close icon absolutely
              right: 20, // Align it to the extreme right with some margin
              top: 2,
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
            onClick={handleClose}
            className={styles.closeIcon}>
            <Typography>
              <img src={close_icon} alt="Close" />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} m={1}>
          <hr
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              border: 'none',
              height: '0.5px',
              // marginBottom: '5px',
              backgroundColor: '#cccccc',
            }}
          />
        </Grid>

        <Grid
          item
          m={3}
          sx={{
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '30px',
          }}
          className={styles.title_message}>
          <Typography className={styles.title_message}>
            {' '}
            Help us to build the Introw community,
            <br />
            Share the app with friends{' '}
          </Typography>
        </Grid>

        <Grid container m={1}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
              marginLeft: '20px',
            }}
            color={' #444444'}>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontFamily: 'Gill Sans Nova',
                letterSpacing: '0.03em',
                fontSize: `${scaleByRatio(12)}px`,
              }}>
              Email
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              // marginTop:'10px',
              marginBottom: '10px',
              marginLeft: '10px',
              marginRight: '10px',
            }}>
            <InputField
              id={'Email'}
              name={'Email'}
              labelId={''}
              type={'email'}
              // id={'inputData'}
              class_name={styles.name}
              minHeightPX={'35'}
              value={email}
              width="1000px"
              handleChange={(event) => handleInput(event)}
              placeholder={'Email (Required)'}
            />
          </Grid>

          {error && error?.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Typography
                textAlign="left"
                className={styles.error}
                style={{
                  fontSize: `${scaleByRatio(12)}px`,
                  lineHeight: `${scaleByRatio(40)}px`,
                }}>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          xs={12}
          //   alignContent={'center'}
          mt={4}
          display={'flex'}
          justifyContent={'center'}>
          {/* <CustomButton 
          text={'Share App'} 
          customStyle='yes'
          variant={ButtonVariant.contained}
          type={ButtonType.yes}
          onClick={handleShareApp} /> */}
          <Button
            variant="contained"
            onClick={handleShareApp}
            sx={{
              backgroundColor: '#F8A233',
              color: '#FFFFFF',
              fontFamily: 'Gill Sans Nova',
              fontWeight: '900',
              textTransform:'none',
              fontSize: '14px',
              padding: '10px 48px',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#F8A233',
              },
              '&:active': {
                backgroundColor: '#F8A233',
              },
              '&:focusVisible': {
                backgroundColor: '#F8A233',
              },
            }}>
            Share App
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ShareApp;
