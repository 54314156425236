import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import {ButtonVariant, ButtonType, CustomButton} from '../../atoms/CustomButton'
import ArrowLeft from '../../assets/ArrowLeft.svg'
import {AnswerType, HeightinFt, HeightinInch} from '../../utils/constants'
import InputField from '../../atoms/InputField';
import SelectDropDown from '../../atoms/SelectDropdown'
import CheckBoxWithLabel from '../../atoms/CheckBoxWithLabel'
import { scaleByRatio } from '../../utils/helper';
import AppLogoMin from '../../assets/AppLogoMin.svg';

const QuestionHeight = ({
    questionText,
    answerType,
    onBackClicked,
    handleOnNextClicked,
    error,
    answer='120',
    showBackButton = true,
    btnSubmitText='Next',
    showVisibility,
    isVisible=false,
    onVisibleClick,
    checkBoxLabel,
    showSteps=false,
    currentStep,
    totalSteps,
    showAppIcon=true
}) => {
    const [heightInFt, setHeightInFt] = useState(4)
    const [heightInInch, setHeightInInch] = useState(0)
    const [heightError, setHeightError] = useState(error)

    const onHeightInFtChange = (e) => {
        console.log('onHeightInFtChange: ', e.target.value)
        // let heightFt = e.target.value;
        // heightFt = heightFt.replace("'",'')
        // let heightRegex = /^(\s*|\d+)$/;
        // if(heightRegex.test(heightFt)) {
        //     setHeightInFt(heightFt+"'")
        // }
        let heightFt = e.target.value;
        setHeightInFt(heightFt)
    }

    const onHeightInInchChange = (e) => {
        
        // let heightIn = e.target.value;
        // heightIn = heightIn.replace('"','')
        // let heightRegex = /^(\s*|\d+)$/;
        // if(heightRegex.test(heightIn)) {
        //     setHeightInInch(heightIn+'"')
        // }
        let heightIn = e.target.value;
        setHeightInInch(heightIn)
    }

    const onNextClicked = ()=>{
        if(heightInFt===0){
            setHeightError('Provide a valid input for height in feet.')
            return
        }else{
            let heightIn = parseInt(heightInFt)*12 + parseInt(heightInInch);

            let height = 2.54 * heightIn

            handleOnNextClicked(height)
        }

        
    }

    useEffect(()=>{
        if(parseInt(answer)>0){
            let heightin = Math.round(parseInt(answer) / 2.54)
            let heightft = Math.floor(heightin/12)
            setHeightInFt(Math.floor(heightft))
            let remainingInch = heightin - (Math.floor(heightft)*12)
            setHeightInInch(Math.round(remainingInch))
            // let height = parseInt(answer)
            // let heightft = height/30.48;
            // setHeightInFt(Math.floor(heightft))
            // let heightin = Math.floor((heightft - Math.floor(heightft))*12)
            // setHeightInInch(heightin)
        }

    },[answer])

    return ( 
        <>
        {showAppIcon && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer} marginTop={-14} marginBottom={2}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                width: `90%`,
                marginBottom:'40px',
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
      )}
        {showBackButton && (
            <Grid container direction="row" justifyContent="left" alignItems="flex-end">
                <Grid item className={styles.logoContainer}>
                    <img src={ArrowLeft} 
                    alt={'Back icon'} 
                    className={styles.logoback} 
                    onClick={onBackClicked}
                    width={scaleByRatio(12)} height={scaleByRatio(12)}
                    />
                </Grid>
                <Grid item >
                    <Typography textAlign='left' className={styles.back} onClick={onBackClicked}
                    style={{
                        fontSize: `${scaleByRatio(12)}px`,
                        paddingLeft: `${scaleByRatio(10)}px`,
                        paddingBottom: `${scaleByRatio(2)}px`,
                    }}
                    >
                    Back
                    </Typography>
                </Grid>
            </Grid>
        )}
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <Grid item >
                <Typography textAlign='left' className={styles.question}
                style={{
                    fontSize: `${scaleByRatio(22)}px`,
                    lineHeight: `${scaleByRatio(28.64)}px`,
                    letterSpacing: '0.06em', 
                    marginLeft: '15px',
                }}
                >
                {questionText}
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item xs={6} style={{paddingRight:'5px'}}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                {/* <InputField
                name='heightInFt'
                id={'heightInFt'}
                value={heightInFt}
                placeholder={''}
                handleChange={onHeightInFtChange}
                type={'text'}
                class_name={styles.inputHeight}
                tabIndex="1"
                /> */}
                <SelectDropDown
                id={'heightInFeet'}
                value={heightInFt}
                handleChange={onHeightInFtChange}
                labelId={""}
                menuItems={HeightinFt}
                />
            </Grid>
            <Grid item xs={6} style={{paddingLeft:'5px'}}>
                {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
                {/* <InputField
                name='heightInInch'
                id={'heightInInch'}
                value={heightInInch}
                placeholder={''}
                handleChange={onHeightInInchChange}
                type={'text'}
                class_name={styles.inputHeight}
                tabIndex="2"
                /> */}
                <SelectDropDown
                id={'heightInInch'}
                value={heightInInch}
                handleChange={onHeightInInchChange}
                labelId={""}
                menuItems={HeightinInch}
                />
            </Grid>
        </Grid>
        {showVisibility && (
            <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                <CheckBoxWithLabel
                isChecked={isVisible}
                labelText={checkBoxLabel}
                handleChange={onVisibleClick}
                />
            </Grid>
        )}
        {heightError && heightError?.length>0 && (
            <Grid container direction="row" justifyContent="left" alignItems="flex-end">
                <Typography textAlign='left' className={styles.error}
                style={{
                    fontSize:`${scaleByRatio(12)}px`,
                    lineHeight:`${scaleByRatio(40)}px`
                }}
                >
                {heightError}
                </Typography>
            </Grid>
        )}
        <Grid container direction="row" justifyContent="end" alignItems="flex-end">
            <Grid item xs={6}>
                {showSteps && ( 
                <Typography textAlign={"left"} 
                className={styles.pagination}
                style={{
                    padding:`${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                    fontSize:`${scaleByRatio(25)}px`,
                    lineHeight:`${scaleByRatio(34)}px`
                }}>
                    {currentStep}/{totalSteps}
                </Typography>
                )}
            </Grid>
            <Grid item xs={6} justifyContent="right" style={{
                display:'flex'
            }}>
                <CustomButton 
                text={btnSubmitText}
                variant={ButtonVariant.contained}
                type={ButtonType.primary}
                onClick={onNextClicked}
                customStyle={`yes`}
                />
            </Grid>
        </Grid>
        </>
     );
}
 
export default QuestionHeight;