import Box from '@mui/material/Box';
import HomeHeader from '../../organism/HomeHeader';
import HomeLeftSideMenu from '../../organism/HomeLeftSideMenu';
import styles from './index.module.scss';

const HomePageLayout = ({
  handleMenuClicked,
  selectedMenu,
  children,
  showRerenderHome,
  isNewNotificationPresent = false,
}) => {
  return (
    <Box sx={{ display: 'flex' }} className="myBox">
      <HomeHeader
        handleMenuClicked={handleMenuClicked}
        selectedMenu={selectedMenu}
        isNewNotificationPresent={isNewNotificationPresent}
        showRerenderHome={showRerenderHome}
      />
      <HomeLeftSideMenu
        handleMenuClicked={handleMenuClicked}
        selectedMenu={selectedMenu}
        isNewNotificationPresent={isNewNotificationPresent}
      />
      <Box component="main" sx={{ flexGrow: 1 }} className={styles.myBoxMain}>
        {children}
      </Box>
    </Box>
  );
};

export default HomePageLayout;
