import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomSwitch from '../../atoms/CustomSwitch';
import SwitchActive from '../../assets/SwitchActive.svg';
import SwitchInactive from '../../assets/SwitchInactive.svg';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import Stack from '@mui/material/Stack';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { scaleByRatio } from '../../utils/helper';
import DownArrow from '../../assets/DownArrow.svg';
import UpArrow from '../../assets/UpArrow.svg';
import FriendActivityList from '../../organism/FriendActivityList';
import { useGetFriendActivity } from '../../services/mutations';
import CustomAlert from '../../atoms/CustomAlert';

const FriendListItem = ({
  friendDetails,
  callBackDeleteFriend,
  callBackActiveInactive,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showData, setShowData] = useState(false);
  console.log('friendDetails: ', friendDetails);
  const getFriendActivity = useGetFriendActivity();
  const [alertMsg, setAlertMsg] = useState('');
  const [friendActivityList, setFriendActivityList] = useState([]);
  const open = Boolean(anchorEl);
  const handleChange = (status) => {
    console.log('handleChange: ', status);
    if(friendDetails?.status === 'A'){
    callBackActiveInactive(status, friendDetails?.id);
    }
    else{
      setAlertMsg('You cannot activate your friend because they have not yet accepted the invitation.');
    }
  };
  const handleMoreClicked = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    // console.log('handleDelete::',friendDetails?.id)
    handleClose(null);
    callBackDeleteFriend(friendDetails?.id);
  };

  const fetchFriendActivityList = () => {
    // let data = {
    //   dater: localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
    // };
    console.log('friendDetails?.id:', friendDetails?.id);
    console.log('getFriendActivity:--->>>');
    getFriendActivity.mutate(friendDetails?.id, {
      onSuccess: (response) => {
        console.log('getUsersFriendListActivity success:', response);
        console.log('getUsersFriendList success:', response?.data?.payload);
        setFriendActivityList(response?.data?.payload);
      },
      onError: (error) => {
        // setLoading(false);
        console.log('getUsersFriendList error:', error);
      },
    });
  };

  useEffect(() => {
    fetchFriendActivityList();
  }, []);
  const onAlertClose = () => {
    setAlertMsg('');
  };
  return (
    <>
      <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        columnSpacing={0.5}
        className={styles.root}
        style={{
          paddingTop: `${scaleByRatio(15)}px`,
        }}>
        <div className={styles.friendsFirstDiv}>
          <Grid
            item
            justifyContent="left"
            style={{ paddingTop: '5px' }}
            className={styles.friendImg}>
            <Typography textAlign="left">
              <img
                src={friendDetails?.profile_pic || Profile_Avatar}
                alt="profile"
              />
            </Typography>
          </Grid>
          <Grid
            item
            justifyContent="left"
            style={{ }}>
            {/* <Grid container direction="column" justifyContent="left"> */}
            <Stack
              direction="column"
              spacing={scaleByRatio(1.8)}
              className={styles.stackClass}
              flexWrap="wrap">
              <Grid item xs={12} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.profileName}>
                  {friendDetails?.first_name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                alignItems="flex-start"
                style={{ marginTop: '0px' }}>
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.lastUpdate}>
                  Last Active{' '}
                  {dayjs(friendDetails?.updated_on).format('YYYY-MM-DD HH:mm')}
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems="flex-start" style={{marginTop:'5px'}}>
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.lastUpdate}
                  style={{
                    fontSize: `${scaleByRatio(10)}px`,
                  }}>
                  <CustomSwitch
                    txtSwitchOn={'ACTIVE'}
                    txtSwitchOff={'INACTIVE'}
                    switchWidth={140}
                    checked={
                      friendDetails?.active === true ||
                      friendDetails?.paused === false
                    }
                    handleChange={handleChange}
                    iconThumb={SwitchActive}
                    iconThumbUncheck={SwitchInactive}
                  />
                </Typography>
              </Grid>
            </Stack>
            {/* </Grid> */}
          </Grid>
        </div>
        <div className={styles.friendsSecDiv}>
          <Grid item xs={1} justifyContent="right" alignItems="flex-end">
            <Typography
              fullwidth
              textAlign="right"
              justifyContent="right"
              // style={{ float: 'right' }}
              >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleMoreClicked}
                sx={{ color: '#E67C39', display: { xs: 'block' }, padding:'5px 8px 0px 0px' }}>
                <MoreVertOutlinedIcon />
              </IconButton>
            </Typography>
          </Grid>
          {/* <Grid
            display="flex"
            justifyContent={'right'}
            alignItems={'flex-end'}
            marginBottom={'-45px'}
            // marginLeft={'-150px'}
            marginRight={'15px'}
            className={styles.description}
            >
            {/* <Grid>see activity</Grid> */}

            {/* <Grid
              item
              display={'flex'}
              justifyContent={'flex-end'}
              alignContent={'flex-end'}
              onClick={() => setShowData(!showData)}
              style={{ cursor: 'pointer' }}
              // mt={-2}
              >
               {showData ? (
                <img
                  src={UpArrow}
                  alt="dropIcon"
                  width={'60px'}
                  style={{ marginRight: '15px',marginBottom:'-20px' }}
                />
              ) : (
                <img
                  src={DownArrow}
                  alt="upIcon"
                  width={'60px'}
                  style={{ marginRight: '15px',marginBottom:'-20px' }}
                />
              )} 
            </Grid> 
          </Grid> */}
        </div>
        <Grid container item xs={12} justifyContent="space-between">
          <Typography textAlign="left" className={styles.description} 
          // paddingLeft={2}
          maxWidth={'70%'}
          >
            {friendDetails?.description}
          </Typography>
          <Typography textAlign="right" className={styles.description} paddingRight={2} onClick={() => setShowData(!showData)}>
            see activity
            </Typography>
        </Grid>
        {showData && (
          <Grid
          marginTop={'10px'}
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-end">
            {friendActivityList.map((activity) => {
              return (
                <FriendActivityList activity={activity} id={activity.id} />
              );
            })}
            {/* <FriendActivityList />} */}
          </Grid>
        )}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {/* <MenuItem onClick={handleClose}>
          Edit
        </MenuItem> */}
        <MenuItem onClick={handleDelete} className={styles.menuItem}>
          Delete
        </MenuItem>
      </Menu>
      </>
      <hr style={{color:"#cccccc",maxWidth:'98%', borderColor:'#cccccc'}} />
      
    </>
  );
};

export default FriendListItem;
