import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import {Box, Grid, IconButton} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import styles from './index.module.scss';
import { ASSETS, landingHeaderMenu, routeUrls } from '../../utils/constants';
import AppLogo from '../../assets/AppLogo.svg';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { scaleByRatio } from '../../utils/helper';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const drawerWidth = 240;

const LandingHeader = (props) => {
  const nav = useNavigate();
  const handleMenuItemClick = (linkPath) => {
    if (linkPath.startsWith('#')) {
      const element = document.querySelector(linkPath);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      nav(linkPath);
    }
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center' }}
      backgroundColor="#000">
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src={AppLogo}
          alt="App Icon"
          className={styles.leftMenuAppIcon}
          style={{
            height: `${scaleByRatio(73)}px`,
            paddingTop: `${scaleByRatio(10)}px`,
            paddingBottom: `${scaleByRatio(10)}px`,
            backgroundColor: '#000',
          }}
        />
      </Typography>
      <Divider />
      <Grid container item direction="row" justifyContent="flex-end" alignItems="center">
        <IconButton
          //  onClick={handleDrawerClose}
        >
          <CloseOutlinedIcon style={{ height: '16px', width: '16px', fill: '#000000', marginRight: '18px', background: '#ffffff', borderRadius: '50%', padding: '2px' }} />
        </IconButton>
      </Grid>
      <Divider />
      <List>
        {landingHeaderMenu.map((page) => (
          <ListItem key={page.label} disablePadding>
            <ListItemButton
              onClick={() => handleMenuItemClick(page.path)}
              sx={{ textAlign: 'center' }}>
              {/* <ListItemText className={styles.menuItem} primary={page.label} /> */}
              <Typography
                textAlign="left"
                className={styles.menuItem}
                style={{
                  fontSize: `${scaleByRatio(16)}px`,
                  lineHeight: `${scaleByRatio(24)}px`,
                }}>
                {page.label}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItem key={'Join'} disablePadding>
                <Button 
                        variant="outlined" 
                        className={styles.btnJoin} 
                        onClick={()=>nav(routeUrls.join)}
                        style={{
                          // width: `${scaleByRatio(101)}px`,
                          fontSize: `${scaleByRatio(20)}px`,
                          lineHeight: `${scaleByRatio(24)}px`,
                          marginLeft:'10px',
                          minHeight:'56px'
                        }}
                    >Sign in/Sign up</Button>
            </ListItem> */}
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar className={styles.header}>
        <Toolbar className="">
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1,
              display:'flex',
              justifyContent: 'center',
              marginRight:'-24px',
              marginTop:'10px',
              paddingLeft: '0px' }}>
            <img
              src={AppLogo}
              alt="App Icon"
              className={styles.appIcon}
              style={{
                height: `${scaleByRatio(73)}px`,
                paddingTop: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(10)}px`,
              }}
            />
          </Typography>
          <Box
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
            className={styles.menuContainer}>
            {landingHeaderMenu.map((page) => (
              <MenuItem
                key={page.label}
                onClick={() => handleMenuItemClick(page.path)}>
                <Typography
                  textAlign="center"
                  className={styles.menuItem}
                  style={{
                    fontSize: `${scaleByRatio(20)}px`,
                    lineHeight: `${scaleByRatio(24)}px`,
                  }}>
                  {page.label}
                </Typography>
              </MenuItem>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
            <Button
              variant="outlined"
              className={styles.btnJoin}
              onClick={() => nav(routeUrls.join)}
              style={{
                // width: `${scaleByRatio(101)}px`,
                fontSize: `${scaleByRatio(20)}px`,
                lineHeight: `${scaleByRatio(24)}px`,
                minHeight: '56px',
              }}>
              Sign in/Sign up
            </Button>
          </Box>
          {/* <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, color:'orange', display: { xs:"block", sm: "none" } }}
                    
                > */}
          <MenuIcon
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              color: '#E66433',
              display: { xs: 'block', sm: 'none' },
            }}
          />
          {/* <MoreVertOutlinedIcon /> */}
          {/* </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'right'}
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#000',
          },
        }}>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default LandingHeader;
