import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import SingleChoiceSelectionButton from '../../atoms/SingleChoiceSelectionButton';

import List from '@mui/material/List';
import ListItemCheckBox from '../../molecule/ListItemCheckbox';
import { AnswerType } from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import InputField from '../../atoms/InputField';

let listItemClass = {
  display: 'flex',
  height: '48px',
  padding: '8px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '73px',
  borderBottom: '1px solid #F6F7F7',
};

let textClass = {
  color: '#FFFFFF',
  fontFamily: 'Gill Sans Nova',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '32px' /* 200% */,
};

const ProfilePetData = ({
  arrQuestion,
  onNextClicked,
  error,
  showBackButton = true,
  onBackClicked,
  selectedValue,
}) => {
  const [questionArr, setQuestionArr] = useState(arrQuestion);
  const [showQuestions, setShowQuestions] = useState(false);
  const [enableYes, setYesEnable] = useState(null);
  const [enableNo, setNoEnable] = useState(null);
  const [enablePrefer, setPreferEnable] = useState(null);
  const [ans, setAns] = useState([]);
  console.log('qr..@', questionArr);
  const [petError, setPetError] = useState(error);
  const handleNextClicked = () => {
    console.log('ans.length ', questionArr);
    console.log('ans.length ', ans.length, ans);
    if (!ans.length) {
      // return setPetError('Please provide your input.');
      return onNextClicked([questionArr[0].question_choices[1]]);
    } else if (showQuestions && ans.length <= 1) {
      // return setPetError('Please provide your input.');
      return onNextClicked([questionArr[0].question_choices[1]]);
    }
    // if (enableYes) {
    //   if (!ans.length) {
    //     return setPetError('Please provide your input.');
    //     // return onNextClicked(questionArr[0].question_choices[1]);
    //   } else if (showQuestions && ans.length <= 1) {
    //     return setPetError('Please provide your input.');
    //     // return onNextClicked(questionArr[0].question_choices[1]);
    //   }
    // }
    setPetError('');
    if (!showQuestions) {
      return onNextClicked(ans);
    }

    onNextClicked(ans);
  };
  const handleAnswerChecked = (val, _question_id, _question_choice_type) => {
    //setLoading(false)
    setPetError('');
    console.log('handleAnswerChecked val: ', val);
    console.log('handleAnswerChecked _question_id: ', _question_id);
    console.log(
      'handleAnswerChecked _question_choice_type: ',
      _question_choice_type,
    );
    let selectedQuestionindex = questionArr.findIndex(
      (question) => question.id === _question_id,
    );
    if (_question_choice_type === AnswerType.SC) {
      let arrAnswerSC = [];
      arrAnswerSC.push(val);
      let arr = [];
      arr = [...questionArr];
      //questionArr[selectedQuestionindex].setStoredAnswerDetails = arrAnswerSC;
      arr[selectedQuestionindex].setStoredAnswerDetails = arrAnswerSC;
      console.log('handleAnswerChecked arrQuestion:', arr);
      setQuestionArr(arr);
    }
    if (_question_choice_type === AnswerType.MC) {
      const currentIndex =
        arrQuestion[selectedQuestionindex].setStoredAnswerDetails.indexOf(val);
      const newChecked = [
        ...arrQuestion[selectedQuestionindex].setStoredAnswerDetails,
      ];

      if (currentIndex === -1) {
        newChecked.push(val);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      let arr = [];
      arr = [...questionArr];

      arr[selectedQuestionindex].setStoredAnswerDetails = newChecked;
      setQuestionArr(arr);
    }
    //setLoading(true)
  };

  // const handleNextClicked = () => {
  //   console.log('pet ans: ', ans);
  // };

  useEffect(() => {
    if (!showQuestions) {
      setPetError('');
    }
  }, [showQuestions]);

  return (
    <>
      {showBackButton && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logo}
              onClick={onBackClicked}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={onBackClicked}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(5)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>
      )}
      {questionArr &&
        questionArr.map((_question, _index) => {
          return (
            <>
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="flex-end">
                <Grid item>
                  <Typography
                    textAlign="left"
                    className={styles.description}
                    style={{
                      // color: `${_index === 0 ? '#F46B1B' : '#00597B'}`,
                      color: `${_index === 0 ? '#fff' : '#fff'}`,
                      fontSize: `${scaleByRatio(32)}px`,
                    }}>
                    {_index === 0
                      ? _question.name
                      : _index > 0 && showQuestions
                      ? _question.name
                      : null}
                    {}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="flex-end">
                {_index === 0 ? (
                  <Grid display={'flex'} gap={1}>
                    {/* <CustomButton
                      text={'Yes'}
                      variant={ButtonVariant.contained}
                      type={ButtonType.secondary}
                      onClick={() => {
                        const { question_choices } = questionArr[_index];
                        const itemFind = question_choices.find(
                          (choice) => choice.has_dependent_question,
                        );
                        setAns([itemFind]);
                        setShowQuestions(true);
                      }}
                    /> */}
                    <SingleChoiceSelectionButton
                      text={'Yes'}
                      isSelected={enableYes}
                      onClick={() => {
                        const { question_choices } = questionArr[_index];
                        const itemFind = question_choices.find(
                          (choice) => choice.has_dependent_question,
                        );
                        setAns([itemFind]);
                        setShowQuestions(true);
                        if (
                          selectedValue?.question_choices[0]?.value === 'Yes'
                        ) {
                          setYesEnable(true);
                          setNoEnable(false);
                          setPreferEnable(false);
                        } else {
                          setYesEnable(false);
                        }
                      }}
                    />
                    {/* <CustomButton
                      text={'No'}
                      variant={ButtonVariant.contained}
                      type={ButtonType.secondary}
                      onClick={() => {
                        const { question_choices } = questionArr[_index];
                        const itemFind = question_choices.find(
                          (choice) => !choice.has_dependent_question,
                        );
                        setAns([itemFind]);
                        setShowQuestions(false);
                      }}
                    /> */}
                    <SingleChoiceSelectionButton
                      text={'No'}
                      isSelected={enableNo}
                      onClick={() => {
                        const { question_choices } = questionArr[_index];
                        const itemFind = question_choices.find(
                          (choice) => !choice.has_dependent_question,
                        );
                        setAns([itemFind]);
                        setShowQuestions(false);
                        if (
                          selectedValue?.question_choices[1]?.value === 'No'
                        ) {
                          setNoEnable(true);
                          setYesEnable(false);
                          setPreferEnable(false);
                        } else {
                          setNoEnable(false);
                        }
                      }}
                    />
                    <SingleChoiceSelectionButton
                      text={'Prefer not to say'}
                      isSelected={enablePrefer}
                      onClick={() => {
                        const { question_choices } = questionArr[_index];
                        const itemFind = question_choices.find(
                          (choice) => choice.value === 'Prefer not to say',
                        );
                        setAns([itemFind]);
                        setShowQuestions(false);
                        if (
                          selectedValue?.question_choices[2]?.value ===
                          'Prefer not to say'
                        ) {
                          setPreferEnable(true);
                          setYesEnable(false);
                          setNoEnable(false);
                        } else {
                          setPreferEnable(false);
                        }
                      }}
                    />
                  </Grid>
                ) : (
                  showQuestions && (
                    <InputField
                      // id={_question.id}
                      name={_question.id}
                      handleChange={(e) => {
                        // handleAnswerChecked(
                        //   e.target.value,
                        //   _question.id,
                        //   _question.choice_type,
                        // )
                        const { value } = e.target;
                        const newAns = [...ans];
                        const index = newAns.findIndex(
                          (item) =>
                            item.id === _question?.question_choices[0]?.id,
                        );
                        if (index !== -1) {
                          newAns[index].value = value;
                        } else {
                          newAns.push({
                            id: _question?.question_choices[0]?.id,
                            value: value,
                            visible: true,
                          });
                        }
                        setAns(newAns);
                      }}
                      id={_question.id}
                      listItemClass={listItemClass}
                      textClass={textClass}
                    />
                  )
                )}
              </Grid>
            </>
          );
        })}
      {petError && petError?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(18)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {petError}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item>
          <CustomButton
            text={'Next'}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={() => handleNextClicked()}
            customStyle={`yes`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilePetData;
