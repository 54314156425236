import styles from './index.module.scss'
import { Grid } from '@mui/material'
import {scaleByRatio} from '../../utils/helper'

const CentralGridLayout = (props ) => {

    const { top = '40%', children } = props;

    return ( 
        <Grid container xs={10} sm={10} md={6} lg={6} className={styles.container} 
        style={{            
            gap:`${scaleByRatio(24)}px`, 
            maxWidth:`${scaleByRatio(600)}px`,
            maxHeight: `100vh`,
            width: '100%',
            overflowY: 'auto',
            position: 'absolute',
            top: top,
            transform: 'translateY(-48%)',
            }}>
            {props.children}
        </Grid>
     );
}
 
export default CentralGridLayout;