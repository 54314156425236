import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, Typography } from '@mui/material';
import styles from './index.module.scss';
import Loader from '../../molecule/Loader';
import CustomAlert from '../../atoms/CustomAlert';
import CustomAlertDelete from '../../atoms/CustomAlertDelete';
import { scaleByRatio } from '../../utils/helper';
import close_icon from '../../assets/close_icon.svg';

import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import CustomSwitch from '../../atoms/CustomSwitch';
import SwitchActive from '../../assets/SwitchActive.svg';
import SwitchInactive from '../../assets/SwitchInactive.svg';
import {
  useGetMyProfile,
  useUpdateMyProfile,
  useDeleteMyProfile,
} from '../../services/mutations';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';

const AccountSettings = () => {
  const getMyProfile = useGetMyProfile();
  const updateMyProfile = useUpdateMyProfile();
  const deleteMyProfile = useDeleteMyProfile();
  const localStorageItem = useLocalStorage();
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [userDetails, setUserDetails] = useState(null);

  const onAlertClose = () => {
    setAlertMsg('');
  };
  const handleChange = (status, item) => {
    console.log('handleChange: ', status, item);
    let data = {};
    if (item === 'SMS') {
      data.sms_notification_enabled = status;
    }
    if (item === 'EMAIL') {
      data.email_notification_enabled = status;
    }
    if (item === 'PN') {
      data.push_notification_enabled = status;
    }
    setLoading(true);
    updateMyProfile.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setUserDetails(response?.data?.payload);
      },
      onError: (err) => {
        console.log('updateMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };

  const onDeleteClicked = () => {
    console.log('Delete clicked');
    setShowDeleteDialog(true);
  };
  const onYesClickDelete = () => {
    console.log('onYesClickDelete');
    setShowDeleteDialog(false);
    setLoading(true);
    deleteMyProfile.mutate(
      {},
      {
        onSettled: (response) => {
          setLoading(false);
          localStorageItem.removeLocalStorageItem(localStorageKeys.accessToken);
          localStorageItem.removeLocalStorageItem(localStorageKeys.isLoggedIn);
          localStorageItem.removeLocalStorageItem(
            localStorageKeys.mobileNumber,
          );
          localStorageItem.removeLocalStorageItem(localStorageKeys.profileId);
          localStorageItem.removeLocalStorageItem(localStorageKeys.userType);
          nav(routeUrls.join);
        },
      },
    );
  };
  const onNoClickDelete = () => {
    console.log('onNoClickDelete');
    setShowDeleteDialog(false);
  };
  const fetchMyProfileDetails = () => {
    setLoading(true);
    getMyProfile.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getMyProfile onSuccess: ', response);
          setUserDetails(response?.data?.payload);
          setLoading(false);
        },
        onError: (err) => {
          console.log('getMyProfile onError: ', err);
          setLoading(false);
        },
      },
    );
  };
  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  useEffect(() => {
    fetchMyProfileDetails();
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      {showDeleteDialog && (
        <CustomAlertDelete
          isOpen={showDeleteDialog}
          message={'Are you sure \n you want to delete?'}
          heading={'Delete Confirm?'}
          onYesClick={onYesClickDelete}
          onNoClick={onNoClickDelete}
        />
      )}
      {!loading && (
        <Box
          component="main"
          bgcolor="#000"
          sx={{
            flexGrow: 1,
            p: scaleByRatio(3),
            marginTop: `${
              isWideScreen ? scaleByRatio(120) : scaleByRatio(80)
            }px`,
          }}>
          <Grid
            container
            xs={12}
            sx={{
              marginTop: '8px',
              color: '#444444',
              display: 'flex',
              justifyContent: 'center', // Center content within the parent grid
              position: 'relative', // Allows for absolute positioning
            }}>
            <Grid item>
              <Typography className={styles.shareApp}>
                Account Settings
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                position: 'absolute', // Position close icon absolutely
                right: 0, // Align it to the extreme right with some margin
                top: 0,
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              onClick={handleClose}
              className={styles.closeIcon}>
              <Typography>
                <img src={close_icon} alt="Close" />
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            m={0}
            style={{
              marginTop: '18px',
            }}>
            <hr
              style={{
                border: 'none',
                height: '0.5px',
                // marginBottom: '5px',
                backgroundColor: '#cccccc',
              }}
            />
          </Grid>

          {/* <Grid
            container
            direction="row"
            alignItems="flex-end"
            sx={{ minHeight: '50px', paddingBottom: '10px' }}>
            <Grid item xs={12} justifyContent="center">
              <Typography textAlign="center" className={styles.header}>
                Account Settings
              </Typography>
            </Grid>
          </Grid>
          <Grid
            display={'flex'}
            justifyContent={'flex-end'}
            color={'#fff'}
            marginTop={'-35px'}
            marginRight={'40px'}
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
            onClick={handleClose}>
            <Typography color={'#fff'}>X</Typography>
          </Grid> */}

          <Grid
            container
            direction="row"
            alignItems="flex-end"
            // className={styles.container}
            style={{
              gap: `${scaleByRatio(8)}px`,
            }}>
            <Grid
              container
              // backgroundColor='#fff'
              direction="row"
              alignItems={'center'}
              // columnSpacing={2.5}
              className={styles.root}
              style={{
                // paddingLeft: `${scaleByRatio(20)}px`,
                display: `flex`,
                // width: '100%',
              }}>
              <Grid
                item
                xs={12}
                alignItems={'center'}
                justifyContent="center"
                style={{
                  paddingTop: `${scaleByRatio(8)}px`,
                  display: `flex`,
                  marginBottom: `${scaleByRatio(13)}px`,
                  paddingLeft: '0px',
                  fontSize:'12px'
                }}>
                <Typography
                  justifyContent={'center'}
                  className={styles.shareApp}>
                  Notification Settings
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                m={0}
                style={{
                  marginTop: '0px',
                }}>
                <hr
                  style={{
                    border: 'none',
                    height: '0.5px',
                    // marginBottom: '5px',
                    backgroundColor: '#cccccc',
                  }}
                />
              </Grid>

              <Grid
              xs={12}
              style={{
                paddingLeft:'10px',
                paddingRight:'10px',
                paddingTop:'5px',
                paddingBottom:'5px',
                     }}
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
              >
                <Grid
                  item
                  alignItems={'center'}
                  justifyContent="left"
                  style={{
                    display: `flex`,
                  }}>
                  <Typography
                    justifyContent={'left'}
                    className={styles.description}
                    style={{
                      marginBottom: `${scaleByRatio(5)}px`,
                      fontSize: `${scaleByRatio(12)}px`,
                    }}>
                    SMS
                  </Typography>
                </Grid>
                <Grid
                  item
                  alignItems={'center'}
                  justifyContent="left"
                  style={{
                    display: `flex`,
                  }}
                  className={styles.toggleSwitch}>
                  <Typography
                    justifyContent={'left'}
                    className={styles.items}
                    style={{
                      fontSize: `${scaleByRatio(10)}px`,
                      // marginBottom: `${scaleByRatio(12)}px`,
                    }}>
                    <CustomSwitch
                      isOnOff={true}
                      txtSwitchOn={'On'}
                      txtSwitchOff={'Off'}
                      checked={userDetails?.sms_notification_enabled}
                      handleChange={(status) => handleChange(status, 'SMS')}
                      iconThumb={SwitchActive}
                      iconThumbUncheck={SwitchInactive}
                      btnWidth="0.77"
                      btnTranslateX="47"
                    />
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                m={0}
                style={{
                  marginTop: '0px',
                }}>
                <hr
                  style={{
                    border: 'none',
                    height: '0.5px',
                    // marginBottom: '5px',
                    backgroundColor: '#cccccc',
                  }}
                />
              </Grid>

              {/* <div className={styles.smsWrapper}> */}
              <Grid
              xs={12}
              style={{
                paddingLeft:'10px',
                paddingRight:'10px',
                paddingTop:'5px',
                paddingBottom:'5px',
                     }}
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
              >
                <Grid
                  item
                  alignItems={'center'}
                  justifyContent="left"
                  style={{
                    display: `flex`,
                  }}>
                  <Typography
                    justifyContent={'left'}
                    className={styles.description}
                    style={{
                      fontSize: `${scaleByRatio(12)}px`,
                      marginBottom: `${scaleByRatio(5)}px`,
                    }}>
                    Email
                  </Typography>
                </Grid>
                <Grid
                  item
                  alignItems={'center'}
                  justifyContent="left"
                  style={{
                    display: `flex`,
                  }}>
                  <Typography
                    justifyContent={'left'}
                    className={styles.items}
                    style={{
                      fontSize: `${scaleByRatio(10)}px`,
                      // marginBottom: `${scaleByRatio(12)}px`,
                    }}>
                    <CustomSwitch
                      isOnOff={true}
                      txtSwitchOn={'On'}
                      txtSwitchOff={'Off'}
                      checked={userDetails?.email_notification_enabled}
                      handleChange={(status) => handleChange(status, 'EMAIL')}
                      iconThumb={SwitchActive}
                      iconThumbUncheck={SwitchInactive}
                      btnWidth="0.77"
                      btnTranslateX="47"
                    />
                  </Typography>
                </Grid>
                </Grid>
              {/* </div> */}
              
              <Grid
                item
                xs={12}
                m={0}
                style={{
                  marginTop: '0px',
                }}>
                <hr
                  style={{
                    border: 'none',
                    height: '0.5px',
                    // marginBottom: '5px',
                    backgroundColor: '#cccccc',
                  }}
                />
              </Grid>

              <Grid
              xs={12}
              style={{
                paddingLeft:'10px',
                paddingRight:'10px',
                paddingTop:'5px',
                paddingBottom:'5px',
                     }}
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
              >
                <Grid
                  item
                  alignItems={'center'}
                  justifyContent="left"
                  style={{
                    display: `flex`,
                  }}>
                  <Typography
                    justifyContent={'left'}
                    className={styles.description}
                    style={{
                      fontSize: `${scaleByRatio(12)}px`,
                      marginBottom: `${scaleByRatio(5)}px`,
                    }}>
                    Push Notification
                  </Typography>
                </Grid>
                <Grid
                  item
                  alignItems={'center'}
                  justifyContent="left"
                  style={{
                    display: `flex`,
                  }}>
                  <Typography
                    justifyContent={'left'}
                    className={styles.items}
                    style={{
                      fontSize: `${scaleByRatio(10)}px`,
                      // marginBottom: `${scaleByRatio(12)}px`,
                    }}>
                    <CustomSwitch
                      isOnOff={true}
                      txtSwitchOn={'On'}
                      txtSwitchOff={'Off'}
                      checked={userDetails?.push_notification_enabled}
                      handleChange={(status) => handleChange(status, 'PN')}
                      iconThumb={SwitchActive}
                      iconThumbUncheck={SwitchInactive}
                      btnWidth="0.77"
                      btnTranslateX="47"
                    />
                  </Typography>
                </Grid>
              </Grid>
              
              <Grid
                item
                xs={12}
                m={0}
                style={{
                  marginTop: '0px',
                }}>
                <hr
                  style={{
                    border: 'none',
                    height: '0.5px',
                    // marginBottom: '5px',
                    backgroundColor: '#cccccc',
                  }}
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid
          container
          xs={12}
          mt={3}
          display={'flex'}
          justifyContent={'center'}>
          <Button
            variant="contained"
            onClick={onDeleteClicked}
            sx={{
              backgroundColor: '#F8A233',
              color: '#FFFFFF',
              fontFamily: 'Gill Sans Nova',
              fontWeight: '900',
              textTransform:'none',
              fontSize: '14px',
              padding: '10px 27px',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#F8A233',
              },
              '&:active': {
                backgroundColor: '#F8A233',
              },
              '&:focusVisible': {
                backgroundColor: '#F8A233',
              },
            }}>
            Delete Account
          </Button>
        </Grid>

          {/* <Grid
            container
            direction="row"
            alignItems={'center'}
            columnSpacing={2.5}
            className={styles.root}
            style={{
              minHeight: `${scaleByRatio(10)}px`,
            }}></Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            className={styles.container}
            style={{
              gap: `${scaleByRatio(8)}px`,
            }}>
            <Grid
              container
              direction="row"
              alignItems={'center'}
              columnSpacing={2.5}
              className={styles.root}
              style={{
                paddingLeft: `${scaleByRatio(15)}px`,
                display: `flex`,
              }}>
              <Grid
                item
                xs={12}
                alignItems={'center'}
                justifyContent="left"
                style={{
                  paddingTop: `${scaleByRatio(10)}px`,
                  display: `flex`,
                  marginBottom: `${scaleByRatio(10)}px`,
                  paddingLeft: '0px',
                }}>
                <Typography
                  justifyContent={'left'}
                  className={styles.sectionHeader}>
                  Delete Account
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                alignItems={'center'}
                justifyContent="center"
                style={{
                  paddingTop: `${scaleByRatio(10)}px`,
                  display: `flex`,
                  marginBottom: `${scaleByRatio(10)}px`,
                }}
                className={styles.delBtn}>
                <CustomButton
                  text={'Delete Account'}
                  variant={ButtonVariant.contained}
                  type={null}
                  onClick={onDeleteClicked}
                />
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid container direction="row" alignItems={"center"} columnSpacing={2.5} className={styles.container}
            style={{
                padding: `0px ${scaleByRatio(16)}px`,
                gap: `${scaleByRatio(8)}px`
            }}>
                <Grid container direction="row" alignItems={"center"} columnSpacing={2.5} className={styles.root}
                style={{
                    paddingLeft:`${scaleByRatio(15)}px`, 
                    display:`flex`
                }}>
                    <Grid item xs={12} alignItems={"center"}  justifyContent="left" 
                    style={{
                        paddingTop:`${scaleByRatio(10)}px`,
                        display:`flex`,
                        marginBottom:`${scaleByRatio(10)}px`
                    }}>
                        <Typography justifyContent={"left"} className={styles.sectionHeader}
                        style={{
                            fontSize:`${scaleByRatio(18)}px`,
                        }}
                        >
                            Delete Account
                        </Typography>
                    </Grid>
                    <Grid item xs={12} alignItems={"center"}  justifyContent="center" 
                    style={{
                        paddingTop:`${scaleByRatio(10)}px`,
                        display:`flex`,
                        marginBottom:`${scaleByRatio(10)}px`
                    }}>
                        <CustomButton 
                            text={'Delete Account'}
                            variant={ButtonVariant.outlined}
                            type={null}
                            onClick={onDelteClicked}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
        </Box>
      )}
    </>
  );
};

export default AccountSettings;
