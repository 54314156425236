import { createBrowserRouter } from 'react-router-dom';
import { routeUrls } from '../constants';
import LandingPage from '../../Pages/LandingPage';
import Join from '../../Pages/Join';
import Start from '../../Pages/Start';
import LoginPage from '../../Pages/LoginPage';
import MyProfileUpdate from '../../Pages/MyProfileUpdate';
import MyProfileQuestions from '../../Pages/MyProfileQuestions';
import DashboardPage from '../../Pages/DashboardPage';
import UploadPhotoPage from '../../Pages/UploadPhotoPage';
import TermsAndConditionPage from '../../Pages/TermsAndConditionPage';
import PrivacyPolicyPage from '../../Pages/PrivacyPolicyPage';
import CookiePolicyPage from '../../Pages/CookiePolicyPage';
import Home from '../../Pages/Home';
import InviteFriend from '../../Pages/InviteFriend';
import ProfileWorkData from '../../organism/ProfileWorkData';
import ProfilePetData from '../../organism/ProfilePetData';
import ProfileUpdatedData from '../../organism/ProfileUpdateData';
import DaterQuestions from '../../organism/DaterProfileQuestions';
import DaterProfileSCQuestions from '../../organism/DaterProfileSCQuestions';
import ActivitiesPause from '../../organism/ActivitiesPause';
import Logout from '../../organism/LogOut';
// import ReportUser from '../../organism/ReportUser';
// import Support from '../../organism/Support';
// import ShareApp from '../../organism/ShareApp';
import ElbowsListItem from '../../molecule/ElbowsListItem';
import Elbows from '../../organism/Elbows';
import ShareApp from '../../organism/ShareApp';
import Support from '../../organism/Support';
import MyProfile from '../../organism/MyProfile';
import InstallPWA from '../../Pages/InstallPWA';
import DaterProfileView from '../../organism/DaterProfileView';

const routes = [
  {
    path: routeUrls.landingPage,
    element: <LandingPage />,
  },
  {
    path: routeUrls.downloadPWA,
    element: <InstallPWA />,
  },
  {
    path: routeUrls.termsandconditions,
    element: <TermsAndConditionPage />,
  },
  {
    path: routeUrls.privacy,
    element: <PrivacyPolicyPage />,
  },
  {
    path: routeUrls.cookiePolicy,
    element: <CookiePolicyPage />,
  },
  {
    path: routeUrls.workData,
    element: <ProfileWorkData />,
  },
  {
    path: routeUrls.petData,
    element: <ProfilePetData />,
  },
  {
    path: routeUrls.join,
    element: <Join />,
  },
  {
    path: routeUrls.start,
    element: <Start />,
  },
  {
    path: routeUrls.login,
    element: <LoginPage />,
  },
  {
    path: routeUrls.myProfile,
    element: <MyProfileUpdate />,
  },
  {
    path: routeUrls.profile,
    element: <MyProfile />,
  },
  {
    path: routeUrls.myProfileQuestion,
    element: <MyProfileQuestions />,
  },
  {
    path: routeUrls.dashboard,
    element: <DashboardPage />,
  },
  {
    path: routeUrls.uploadPhotos,
    element: <UploadPhotoPage />,
  },
  {
    path: routeUrls.home,
    element: <Home />,
  },
  {
    path: routeUrls.updateData,
    element: <ProfileUpdatedData />,
  },
  {
    path: routeUrls.daterUpdatedQuestion,
    element: <DaterQuestions />,
  },
  {
    path: routeUrls.daterUpdatedSCQuestion,
    element: <DaterProfileSCQuestions />,
  },
  {
    path: routeUrls.inviteFriend,
    element: <InviteFriend />,
  },
  {
    path: routeUrls.activitiesPause,
    element: <ActivitiesPause />,
  },
  {
    path: routeUrls.logout,
    element: <Logout />,
  },
    {
      path: routeUrls.daterProfile,
      element: <DaterProfileView />,
    },

  {
    path: routeUrls.Support,
    element: <Support />,
  },
  {
    path: routeUrls.ShareApp,
    element: <ShareApp />,
  },
  {
    path: routeUrls.Elbows,
    element: <Elbows />,
  },
  {
    path: routeUrls.signinLoader,
    element: <DashboardPage />,
  },
];

const router = createBrowserRouter(routes);

export default router;
