import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import {
  useGetMyDaters,
  useGetActiveDater,
  usePostActiveDate,
} from '../../services/mutations';
import Loader from '../../molecule/Loader';
import DaterListItem from '../../molecule/DaterListItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import { scaleByRatio } from '../../utils/helper';
import close_icon from '../../assets/close_icon.svg';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';


const MyDaters = ({ handleCurrentDater }) => {
  const [datersList, setDatersList] = useState([]);
  const [activeUser, setActiveuser] = useState(null);
  const [loading, setLoading] = useState(false);
  const getMyDaters = useGetMyDaters();
  const getActiveDater = useGetActiveDater();
  const postActiveDate = usePostActiveDate(); 
  const nav = useNavigate();


  const fetchMyDaters = () => {
    setLoading(true);
    let data = {};
    getMyDaters.mutate(data, {
      onSuccess: (response) => {
        let arrDaters = response?.data?.payload?.results;
        setDatersList(arrDaters);
        console.log('getMyDaters success: ', arrDaters);
        getActiveDater.mutate(data, {
          onSuccess: (response) => {
            console.log('getActiveDater success: ', response);
            setActiveuser(response?.data?.payload?.user);
            setLoading(false);
          },
          onError: (error) => {
            setLoading(false);
            console.log('getActiveDater error: ', error);
          },
        });
      },
      onError: (error) => {
        setLoading(false);
        console.log('getMyDaters error: ', error);
      },
    });
  };

  const onChangeView = (status, friendID) => {
    console.log('onChangeView friendID:', friendID);
    console.log('onChangeView status:', status);
    if (status) {
      let data = {
        friend_id: friendID,
      };
      setLoading(true);
      postActiveDate.mutate(data, {
        onSuccess: (response) => {
          setLoading(false);
          fetchMyDaters();
        },
        onError: (error) => {
          console.log('Error: ', error);
          setLoading(false);
          fetchMyDaters();
        },
      });
      handleCurrentDater();
    }
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };

  useEffect(() => {
    console.log('My Daters Called');
    fetchMyDaters();
  }, []);
  return (
    <>
      <Loader open={loading} />
      <Box
        component="main"
        sx={{ flexGrow: 1, pb: 9, pr: 3, pl: 3, marginTop: '110px' }}>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{ minHeight: '50px', paddingBottom: '10px' }}>

          <Grid item xs={12} 
          
          direction={'column'}
          justifyContent="center">

            <Grid
            justifyContent={'space-around'}
            display={'flex'}
            direction={'row'}
            className={styles.daterHeader}
            >
              <Grid item xs={11}>
            <Typography textAlign="center" className={styles.header} sx={{marginBottom: '20px'}}>
              View My Daters
            </Typography>
            </Grid>
            {/* <hr style={{ maxWidth: '98%' }} /> */}
            

            <Grid
        display={'flex'}
        justifyContent={'flex-end'}
        color={'#fff'}
        marginTop={'0px'}
        marginRight={'0px'}
        // marginBottom={'-10px'}
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
        onClick={handleClose}
        className={styles.closeIcon}>
        <Typography>
          <img src={close_icon} />
        </Typography>
        </Grid>

      </Grid>

      <hr style={{ marginLeft: '-5px', marginRight: '-5px' , border: 'none', height: '0.5px' , marginBottom:'5px', backgroundColor: '#cccccc'}} />

          </Grid>

          <Grid item xs={12} justifyContent="center">
            <Typography textAlign="left" className={styles.subheader} sx={{paddingRight: '10px', paddingLeft: '5px'}}>
              CHOOSE WHICH DATER’S ACTIVITY YOU WANT TO VIEW (YOU CAN SUPPORT UP
              TO THREE DATERS BUT ONLY VIEW ONE AT A TIME).{' '}
            </Typography>
            <hr style={{ marginLeft: '-5px', marginRight: '-5px' , border: 'none', height: '0.5px' , marginBottom:'5px', backgroundColor: '#cccccc'}} />
          </Grid>
        </Grid>

        <Stack
          direction="column"
          spacing={scaleByRatio(2)}
          className={styles.stackClass}
          flexWrap="wrap">
          {!loading &&
            datersList.length > 0 &&
            datersList.map((dater) => {
              return (
                <DaterListItem
                  daterDetails={dater}
                  callBackViewChange={onChangeView}
                  activeUser={activeUser}
                />
              );
            })}
        </Stack>
      </Box>
    </>
  );
};

export default MyDaters;
