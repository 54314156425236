import React, { useState, useEffect } from 'react';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import AppLogo from '../../assets/AppLogoLarge.svg';
import { Grid } from '@mui/material';

const DashboardPage = () => {
  
  const [maxWidth, setMaxWidth] = useState(0);

  const originalWidth = 349.03;
  const originalHeight = 113.62;
  
  useEffect(() => {
    const handleResize = () => {
      // Calculate 90% of the screen width
      const max = window.innerWidth * 0.9;
      setMaxWidth(max);
    };

    // Initial calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate 1.3 times the original dimensions
  let newWidth = originalWidth * 1.3;
  let newHeight = originalHeight * 1.3;

  // Ensure it doesn't exceed maxWidth
  if (newWidth > maxWidth) {
    const scaleRatio = maxWidth / newWidth;
    newWidth *= scaleRatio;
    newHeight *= scaleRatio;
  }

  return (
    <Grid container justifyContent="center" style={{ backgroundColor: 'black', minHeight: '100vh' }}>
      <Grid item style={{ marginTop: '40vh' }}>
        <img src={AppLogo} alt="Applogo" width={newWidth} height={newHeight} />
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
