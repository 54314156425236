import React, { useState } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemCheckBox from '../../molecule/ListItemCheckbox';
import { scaleByRatio } from '../../utils/helper';
import { AnswerType } from '../../utils/constants';
import DownArrow from '../../assets/DownArrow.svg';
import UpArrow from '../../assets/UpArrow.svg';

let listItemClass = {
  display: 'flex',
  height: `${scaleByRatio(48)}px`,
  padding: `${scaleByRatio(8)}px`,
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: `${scaleByRatio(73)}px`,
  borderBottom: '1px solid #F6F7F7',
};

let textClass = {
  // color: '#1C1D1D',
  color: '#FFFFFF',
  fontFamily: 'Gill Sans Nova',
  fontSize: `${scaleByRatio(18)}px`,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: `${scaleByRatio(32)}px` /* 200% */,
};

const DatingPreferenceMultipleChoice = ({
  question,
  id,
  identifier,
  question_choices,
  question_color,
  selectedAnswer = [],
  handleOnChangeCallBack,
  question_type,
}) => {
  const [checked, setChecked] = useState(selectedAnswer);
  const [showData, setShowData] = useState(false);
  const handleAnswerChecked = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    console.log('question_choices', question_choices);
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log(newChecked);
    setChecked(newChecked);
    handleOnChangeCallBack(
      newChecked,
      id,
      identifier,
      question_type,
      AnswerType.MC,
    );
  };

  const handleShow = () => {
    setShowData(!showData);
  };
  return (
    <>
    <Grid marginBottom={'10px'}>
      <Grid container direction="row">
        <Grid
          item
          display={'flex'}
          justifyContent={'flex-start'}
          alignContent={'flex-start'}>
          <Typography
            textAlign="left"
            className={styles.question}
            style={{
              // color: `${question_color}`,
              color: '#F4AA4D',
              lineHeight: `${scaleByRatio(24)}px`,
              letterSpacing: '0.02em'
            }}>
            {question}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        padding={'20px'}
        paddingBottom={'15px'}
        item
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'flex-end'}
        onClick={() => setShowData(!showData)}
        style={{ cursor: 'pointer' }}
        mt={-5}>
        {showData ? (
          <img
            src={UpArrow}
            alt="dropIcon"
            width={'16px'}
            height={'14px'}
            // style={{ marginRight: '15px' }}
          />
        ) : (
          <img
            src={DownArrow}
            alt="upIcon"
            width={'16px'}
            height={'14px'}

            // style={{ marginRight: '15px' }}
          />
        )}
      </Grid>

      {showData && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <List dense sx={{ width: '100%', color: '#fff' }}>
            {question_choices.map((answer) => {
              return (
                <ListItemCheckBox
                  value={answer.id}
                  onChange={handleAnswerChecked}
                  isChecked={checked.indexOf(answer.id) !== -1}
                  id={answer.id}
                  displayText={answer.value}
                  listItemClass={listItemClass}
                  textClass={textClass}
                />
              );
            })}
          </List>
        </Grid>
      )}
      </Grid>
    </>
  );
};

export default DatingPreferenceMultipleChoice;