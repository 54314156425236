import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import styles from './index.module.scss';
import LeftMenuMyFriend from '../../assets/LeftMenuMyFriend.svg';
import LeftMenuDatingPreference from '../../assets/LeftMenuDatingPreference.svg';
import LeftMenuNotifications from '../../assets/LeftMenuNotifications.svg';
import LeftMenuMyProfile from '../../assets/LeftMenuMyProfile.svg';
import LeftMenuAccountSettings from '../../assets/LeftMenuAccountSettings.svg';
import LeftMenuShareApp from '../../assets/LeftMenuShareApp.svg';
import LeftMenuSupport from '../../assets/LeftMenuSupport.svg';
import LeftMenuTAndC from '../../assets/LeftMenuTAndC.svg';
import LeftMenuPrivacyPolicy from '../../assets/LeftMenuPrivacyPolicy.svg';
import LeftMenuLogout from '../../assets/LeftMenuLogout.svg';
import BlueDot from '../../assets/BlueDot.svg';
import { APP_DRAWER_WIDTH, HomeMenuConstant } from '../../utils/constants';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { scaleByRatio } from '../../utils/helper';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { red } from '@mui/material/colors';
import './index.module.scss';

const drawerWidth = scaleByRatio(APP_DRAWER_WIDTH);
const minMenuHeight = scaleByRatio(48);
const menuItemStyle = {
  // color: '#05232E',
  color: '#FFFFFF',
  fontFamily: 'Myriad Pro',
  // fontSize: `${scaleByRatio(18)}px`,
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
};
const menuItemStyleSelected = {
  color: '#F46B1B',
  fontFamily: 'Myriad Pro',
  // fontSize: `${scaleByRatio(18)}px`,
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
};
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  marginTop: `${scaleByRatio(80)}px`,
  // background: '#EFEFEF',
  // background: '#494F4F',
  background: '#28252587',
  display: 'flex', // Use flex container
  flexDirection: 'column',
  height: '100%', // Set a fixed height
  overflowY: 'auto',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  marginTop: `${scaleByRatio(80)}px`,
  // background: '#EFEFEF',
  // background: '#494F4F',
  background: '#28252587',
  height: '100%', // Set a fixed height
  overflowY: 'auto',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: '10px',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const HomeLeftSideMenu = ({
  handleMenuClicked,
  selectedMenu,
  isNewNotificationPresent,
}) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const localStorageItem = useLocalStorage();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLeftMenuClicked = (menu) => {
    handleMenuClicked(menu);
  };

  return (
    <>{
      !isMobile &&
      <Drawer
        variant="permanent"
        open={open}
      // sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        <div className={styles.childDrawer}>
          <DrawerHeader>
            {!open ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start">
                <MenuIcon style={{ fill: '#fff' }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerClose}>
                <CloseOutlinedIcon style={{ fill: '#fff' }} />
              </IconButton>
            )}
          </DrawerHeader>
          <Divider />
          <List className="side-bar-listing" sx={{ overflowY: 'auto' }}>
            {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.dater && (
                <ListItem key={'ends'} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: minMenuHeight,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() =>
                      handleLeftMenuClicked(HomeMenuConstant.MyFriends)
                    }>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                      <img src={LeftMenuMyFriend} alt="My Friends" />
                    </ListItemIcon>
                    <ListItemText
                      primary={'My Friends'}
                      className={styles.menuItemText}
                      sx={{
                        opacity: open ? 1 : 0,
                        '& .MuiListItemText-primary':
                          selectedMenu === HomeMenuConstant.MyFriends
                            ? menuItemStyleSelected
                            : menuItemStyle,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.friend && (
                <ListItem
                  key={'My Friends'}
                  disablePadding
                  sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: minMenuHeight,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => handleLeftMenuClicked(HomeMenuConstant.MyDaters)}
                    className="myProfileBtn">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                      <img src={LeftMenuMyFriend} alt="My Daters" />
                    </ListItemIcon>
                    <ListItemText
                      primary={'My Daters'}
                      className={styles.menuItemText}
                      sx={{
                        opacity: open ? 1 : 0,
                        '& .MuiListItemText-primary':
                          selectedMenu === HomeMenuConstant.MyDaters
                            ? menuItemStyleSelected
                            : menuItemStyle,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.dater && (
                <ListItem
                  key={'Dating Preferences'}
                  disablePadding
                  sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: minMenuHeight,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() =>
                      handleLeftMenuClicked(HomeMenuConstant.DatingPreferences)
                    }>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                      <img
                        src={LeftMenuDatingPreference}
                        alt="Dating Preferences"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={'Dating Preferences'}
                      className={styles.menuItemText}
                      sx={{
                        opacity: open ? 1 : 0,
                        '& .MuiListItemText-primary':
                          selectedMenu === HomeMenuConstant.DatingPreferences
                            ? menuItemStyleSelected
                            : menuItemStyle,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            <ListItem
              key={'Notifications'}
              disablePadding
              sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() =>
                  handleLeftMenuClicked(HomeMenuConstant.Notifications)
                }>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img src={LeftMenuNotifications} alt="Notifications" />
                  {/* <LeftMenuNotifications fill="#FF0000" style={{fill:'#FF0000'}} /> */}
                </ListItemIcon>
                <ListItemText
                  primary={'Notifications'}
                  className={styles.menuItemText}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.Notifications
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                />
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: '#00B9FF',
                  }}>
                  {isNewNotificationPresent && (
                    <img
                      src={BlueDot}
                      alt="BlueDot"
                      style={{
                        width: `${scaleByRatio(12)}px`,
                      }}
                    />
                  )}

                  {/* <FiberManualRecordIcon /> */}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem key={'My Profile'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleLeftMenuClicked(HomeMenuConstant.MyProfile)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img src={LeftMenuMyProfile} alt="My Profile" />
                </ListItemIcon>
                <ListItemText
                  primary={'My Profile'}
                  className={styles.menuItemText}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.MyProfile
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={'Account Settings'}
              disablePadding
              sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() =>
                  handleLeftMenuClicked(HomeMenuConstant.AccountSettings)
                }>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img src={LeftMenuAccountSettings} alt="Account Settings" />
                </ListItemIcon>
                <ListItemText
                  primary={'Account Settings'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.AccountSettings
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                  className={styles.menuItemText}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Share App'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleLeftMenuClicked(HomeMenuConstant.ShareApp)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img src={LeftMenuShareApp} alt="Share App" />
                </ListItemIcon>
                <ListItemText
                  primary={'Share App'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.ShareApp
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                  className={styles.menuItemText}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Support'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleLeftMenuClicked(HomeMenuConstant.Support)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img src={LeftMenuSupport} alt="Support" />
                </ListItemIcon>
                <ListItemText
                  primary={'Support'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.Support
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                  className={styles.menuItemText}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={'T&C'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleLeftMenuClicked(HomeMenuConstant.TnC)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img src={LeftMenuTAndC} alt="T&C" />
                </ListItemIcon>
                <ListItemText
                  primary={'T&C'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.TnC
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                  className={styles.menuItemText}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={'Privacy Policy'}
              disablePadding
              sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() =>
                  handleLeftMenuClicked(HomeMenuConstant.PrivacyPolicy)
                }>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img
                    src={LeftMenuPrivacyPolicy}
                    alt="Privacy Policy"
                    style={{
                      width: `${scaleByRatio(21)}px`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Privacy Policy'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.PrivacyPolicy
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                  className={styles.menuItemText}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Logout'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: minMenuHeight,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleLeftMenuClicked(HomeMenuConstant.Logout)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <img
                    src={LeftMenuLogout}
                    alt="Logout"
                    style={{
                      width: `${scaleByRatio(21)}px`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Logout'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiListItemText-primary':
                      selectedMenu === HomeMenuConstant.Logout
                        ? menuItemStyleSelected
                        : menuItemStyle,
                  }}
                  className={styles.menuItemText}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Drawer>

    }</>
  );
};

export default HomeLeftSideMenu;
