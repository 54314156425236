import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import InputField from '../../atoms/InputField';
import { scaleByRatio } from '../../utils/helper';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';

import { useLocation, useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import {
  useGetAllQuestions,
  useGetListOfPendingQuestions,
  useGetQuestionDetailsById,
  usePatchQuestionDetailsById,
  usePatchSingleChoiceAnswer,
  usePatchotherSingleChoiceAnswer,
  usePostSingleChoiceAnswer,
} from '../../services/mutations';

const ProfileUpdatedData = ({ }) => {
  const nav = useNavigate();
  const location = useLocation();
  console.log('clickable', location?.state?.clickable);
  let clickable = location?.state?.clickable;
  const [data, setData] = useState();
  const postSingleChoiceAnswer = usePostSingleChoiceAnswer();
  const patchSingleChoiceAnswer = usePatchotherSingleChoiceAnswer();
  const getQuestionDetailsById = useGetQuestionDetailsById();
  const localStorage = useLocalStorage();
  const myProfileID = localStorage.getLocalStorageItem(
    localStorageKeys.profileId,
  );
  const [error, setError] = useState();
  const [pendingQuestionList, setPendingQuestionList] = useState([]);
  const [question, setQuestion] = useState(null);
  const [questionDetails, setQuestionDetails] = useState({});
  const [dataQ, setDataQ] = useState();
  const getAllQuestions = useGetAllQuestions();
  const pendingQuestions = useGetListOfPendingQuestions();
  const [allQ, setAllQ] = useState([location?.state?.ansQ]);
  const [QID, setQID] = useState(null);
  console.log('dataChoice', location?.state?.choice_type);
  const postPatchSingleApiAnswer = (answer) => {
    console.log('anss', questionDetails);
    setQuestion(getAllQuestions);
    patchSingleChoiceAnswer.mutate(answer, {
      onSuccess: (response) => {
        // set the patch id for question on successful posting
        let questionIndex = pendingQuestionList.findIndex(
          (questionItem) => questionItem.id === question.id,
        );
        if (questionIndex !== -1) {
          pendingQuestionList[questionIndex].patchId =
            response?.data?.payload?.id || null;
          setPendingQuestionList(pendingQuestionList);
        }

        // setLoading(false);
        console.log('ProfileUpdatedData>>', clickable);
        nav(routeUrls.home, {
          state: {
            showEdit: clickable,
          }
        });
      },
      onError: (error) => {
        console.log('postSingleChoiceAnswer: ', error);
        if (error?.response?.status === 400) {
          let data = {
            answer: answer,
            visible: true,
            value: data,
          };
          postSingleChoiceAnswer.mutate(data, {
            onSuccess: (response) => {
              // setLoading(false);
              // nav(routeUrls.home);
              nav(routeUrls.home, {
                state: {
                  showEdit: clickable,
                }
              });
            },
          });
        } else {
          // setLoading(false);
          let answer = {
            answer: questionDetails?.question_choices[0]?.id,
            visible: true,
            value: data,
          };
          postSingleChoiceAnswer.mutate(answer, {
            onSuccess: (response) => {
              // set the patch id for question on successful posting
              let questionIndex = pendingQuestionList.findIndex(
                (questionItem) => questionItem.id === question.id,
              );
              if (questionIndex !== -1) {
                pendingQuestionList[questionIndex].patchId =
                  response?.data?.payload?.id || null;
                setPendingQuestionList(pendingQuestionList);
              }

              // setLoading(false);
              // nav(routeUrls.home);
              nav(routeUrls.home, {
                state: {
                  showEdit: clickable,
                }
              });
            },
            onError: (error) => {
              console.log('postSingleChoiceAnswer: ', error);
              if (error?.response?.status === 400) {
                let data = {
                  id: question?.patchId,
                  answer: answer,
                };
                patchSingleChoiceAnswer.mutate(data, {
                  onSuccess: (response) => {
                    // setLoading(false);
                  },
                });
              } else {
                // setLoading(false);
              }
            },
          });
        }
      },
    });
  };

  const handleSave = () => {
    console.log('onSavedataClicked: ', QID);
    // console.log('questionDetails: ', questionDetails);
    if (!data) {
      setError('Please Provide some input');
    }
    let answer = {
      id: QID,
      answer: questionDetails?.question_choices[0]?.id,
      visible: true,
      value: data,
    };
    console.log('ans...', answer);
    postPatchSingleApiAnswer(answer);
  };

  const handleData = (e) => {
    console.log('_data: ', e.target.value);
    let _data = e.target.value;
    setData(_data);
  };

  const getQuestionDetailsByIdentifier = (questionId) => {
    // setLoading(true);

    let qData = {
      id: questionId,
    };
    getQuestionDetailsById.mutate(qData, {
      onSuccess: (response) => {
        let _questionDetails = response?.data?.payload;
        setQuestionDetails(_questionDetails);
        console.log('question details: ', response?.data);
        //get answers
        return;
      },
      onError: (error) => {
        // setLoading(false);
        console.log('getQuestionDetailsById error: ', error);
      },
    });
  };

  useEffect(() => {
    if (location?.state?.questionId) {
      getQuestionDetailsByIdentifier(location?.state?.questionId);
    }
    console.log('allQ', allQ);
    allQuestionDetails();
  }, [location?.state?.questionId]);

  useEffect(() => {
    if (data) {
      setError('');
    }
  }, [data]);
  useEffect(() => {
    pendingQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          // setLoading(false);
          console.log('getAllQuestions onSuccess: ', response);
          setDataQ(response?.data?.payload);
          // fetchAllAnswers();
          console.log('getAllQuestions onSuccess: ', response);
        },
        onError: (error) => {
          // setLoading(false);
          console.log('getAllQuestions onError :', error);
          // setLoading(false);
        },
      },
    );
  }, []);
  console.log('getAllQuestions onSuccess Out:  ', dataQ);
  const allQuestionDetails = () => {
    allQ?.map((item) => console.log('itemData', item));
  };
  useEffect(() => {
    let data = [];
    if (location?.state?.questionId) {
      data = location?.state?.allQ?.find(
        (item) => item?.answer?.question?.id === location?.state?.questionId,
      );
    }
    console.log('dataNew', data);
    setQID(data?.id);
  }, []);
  return (
    <CentralContentPageLayout>
      <CentralGridLayout>
      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer} marginTop={-8}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                width: `90%`,
                marginBottom: '40px',
                marginTop: '-30px',
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logo}
              onClick={() => {
                console.log("questionEdit set to true in localStorage. back icon");
                nav(routeUrls.home, {
                  state: {
                    showEdit: clickable,
                  }
                })
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={() => {
                console.log("questionEdit set to true in localStorage.");
                nav(routeUrls.home, {
                  state: {
                    showEdit: clickable,
                  }
                })
              }
              }
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(5)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(22)}px`,
                marginLeft:'20px',
                marginRight:'100px', 
                //   lineHeight: `${scaleByRatio(38)}px`,
              }}>
              {location?.state?.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item xs={12} style={{ paddingRight: '5px' }}>
            <InputField
              class_name={styles.name}
              id={'data'}
              type={'text-area'}
              value={data}
              handleChange={handleData}
              placeholder={'Add Info'}
              labelId={''}
              minHeightPX="90"
              multiline={true}
              rows={5}
            />
          </Grid>
        </Grid>
        {error && error?.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-end">
            <Typography
              textAlign="left"
              className={styles.error}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                lineHeight: `${scaleByRatio(40)}px`,
              }}>
              {error}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="flex-end">
          <Grid item>
            <CustomButton
              text={'Save'}
              variant={ButtonVariant.contained}
              type={ButtonType.nextButton}
            customStyle='yes'
              // type={ButtonType.primary}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </CentralGridLayout>
    </CentralContentPageLayout >
  );
};

export default ProfileUpdatedData;
