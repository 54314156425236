import { useEffect } from 'react'
import OneSignal from 'react-onesignal'
import { ONESIGNAL_APP_ID } from '../constants'

const usePushNotification = () => {


  useEffect(() => {

    console.log("web push notification called")


    // const style = document.createElement('style');
    // style.innerHTML = `
    //   .slidedown-body {
    //     padding-bottom: 20px;
    //   }
    //   .slidedown-body-message {
    //     font-family: 'Gill Sans Nova' !important;
    //     font-weight: 600 !important;
    //     font-size: 14px !important;
    //     line-height: 20.08px !important;
    //     letter-spacing: 0.06em !important;
    //     color: #eaaa0c !important;
    //   }
    //   .slidedown-button.primary {
    //     font-size: 14px !important;
    //     font-weight: 900 !important;
    //     text-transform: none !important;
    //     font-family: "Gill Sans Nova" !important;
    //     color: #ffffff !important;
    //     background-color: #e66433 !important;
    //     border-radius: 50px !important;
    //     padding: 8px 25px !important;
    //   }
    //   .slidedown-button.secondary {
    //     font-size: 14px !important;
    //     font-weight: 900 !important;
    //     text-transform: none !important;
    //     font-family: "Gill Sans Nova" !important;
    //     color: #ffffff !important;
    //     background-color: #BCBEC0 !important;
    //     border-radius: 50px !important;
    //     padding: 8px 25px !important;
    //   }
    // `;
    // document.head.appendChild(style);

    const injectStyles = () => {
      const style = document.createElement('style');
      style.id = 'custom-onesignal-styles'; // Add an ID to ensure unique style
      style.innerHTML = `
        .slidedown-body {
          padding-bottom: 20px;
        }
        .slidedown-body-message {
          font-family: 'Gill Sans Nova' !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 20.08px !important;
          letter-spacing: 0.06em !important;
          color: #ea330c !important;
        }
        .slidedown-button.primary {
          font-size: 14px !important;
          font-weight: 900 !important;
          text-transform: none !important;
          font-family: "Gill Sans Nova" !important;
          color: #ffffff !important;
          background-color: #e66433 !important;
          border-radius: 50px !important;
          padding: 8px 25px !important;
        }
        .slidedown-button.secondary {
          font-size: 14px !important;
          font-weight: 900 !important;
          text-transform: none !important;
          font-family: "Gill Sans Nova" !important;
          color: #ffffff !important;
          background-color: #BCBEC0 !important;
          border-radius: 50px !important;
          padding: 8px 25px !important;
        }
      `;

      // Remove existing styles to avoid duplicates
      const existingStyle = document.getElementById('custom-onesignal-styles');
      if (existingStyle) {
        existingStyle.remove();
      }
      
      document.head.appendChild(style);
      console.log('Styles re-applied');
    };


    OneSignal.init({
      appId: ONESIGNAL_APP_ID || '',
      // eslint-disable-next-line camelcase
      // safari_web_id: ONESIGNAL_APP_ID || '',
      //   notifyButton: {
      //     enable: true,
      //   },
      allowLocalhostAsSecureOrigin: true,
      promptOptions: {
        actionMessage: 'Allow notification to get update on all activities.',
      },
    }).then(() => {
      OneSignal.showSlidedownPrompt()

      // Re-apply styles after showing the prompt
      injectStyles();

      OneSignal.on('subscriptionChange', function (isSubscribed) {
        if (isSubscribed) {
          OneSignal.getUserId().then(function (userId) {
            console.log('OneSignal User ID:', userId)

            // Set external user ID here
            const profileId = localStorage.getItem('profileId');
            OneSignal.setExternalUserId(profileId)
              .then(() => {
                console.log('External user ID set successfully');
              })
              .catch(error => {
                console.error('Error setting external user ID:', error);
              });

            // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
            // API call

          })
        }
      })

      //   OneSignal.setDefaultNotificationUrl(window.location.origin + routeUrls.notification)
    })
  }, [])
}

export default usePushNotification

// import { useEffect } from 'react';
// import OneSignal from 'react-onesignal';
// import { ONESIGNAL_APP_ID } from '../constants';

// const usePushNotification = () => {
//   useEffect(() => {
//     console.log("web push notification called");
    
//     // Inject custom styles for OneSignal popup
  
//     OneSignal.init({
//       appId: ONESIGNAL_APP_ID || '',
//       allowLocalhostAsSecureOrigin: true,
//       promptOptions: {
//         actionMessage: 'Allow notification to get update on all activities.',
//       },
//     }).then(() => {
//       OneSignal.showSlidedownPrompt();

//       OneSignal.on('subscriptionChange', function (isSubscribed) {
//         if (isSubscribed) {
//           OneSignal.getUserId().then(function (userId) {
//             console.log('OneSignal User ID:', userId);

//             const profileId = localStorage.getItem('profileId');
//             OneSignal.setExternalUserId(profileId)
//               .then(() => {
//                 console.log('External user ID set successfully');
//               })
//               .catch(error => {
//                 console.error('Error setting external user ID:', error);
//               });
//           });
//         }
//       });
//     });
//   }, []);
// };

// export default usePushNotification;
