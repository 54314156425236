import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import SingleChoiceSelectionButton from '../../atoms/SingleChoiceSelectionButton';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import CheckBoxWithLabel from '../../atoms/CheckBoxWithLabel';
import { scaleByRatio } from '../../utils/helper';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnswerType, routeUrls } from '../../utils/constants';
import {
  useGetAllQuestions,
  useGetQuestionDetailsById,
  usePatchMultiChoiceAnswer,
  usePatchSingleChoiceAnswer,
  usePostMultiChoiceAnswer,
  usePostSingleChoiceAnswer,
} from '../../services/mutations';
import Loader from '../../molecule/Loader';

const DaterQuestions = () => {
  const nav = useNavigate();
  const location = useLocation();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const getQuestionDetailsById = useGetQuestionDetailsById();
  const [questionDetails, setQuestionDetails] = useState({});
  const [answerChoices, setAnswerChoices] = useState([]);
  const [question, setQuestion] = useState(null);
  const patchSingleChoiceAnswer = usePatchSingleChoiceAnswer();
  const postSingleChoiceAnswer = usePostSingleChoiceAnswer();
  const patchMultiChoiceAnswer = usePatchMultiChoiceAnswer();
  const postMultiChoiceAnswer = usePostMultiChoiceAnswer();
  const [pendingQuestionList, setPendingQuestionList] = useState([]);
  const getAllQuestions = useGetAllQuestions();
  const [answers, setAnswers] = useState([]);
  const [selected, setSelected] = useState([]);

  console.log('qs', questionDetails);
  console.log('qc', answerChoices);

  console.log(
    'data..',
    location?.state?.questionId,
    location?.state?.choice_type,
  );

  const postPatchMultipleChoiceAnswer = (answer) => {
    //call api to store answers
    setQuestion('QS', getAllQuestions);
    console.log('answ', answer);
    postMultiChoiceAnswer.mutate(answer, {
      onSuccess: (response) => {
        let questionIndex = pendingQuestionList.findIndex(
          (questionItem) => questionItem.id === question.id,
        );
        if (questionIndex !== -1) {
          pendingQuestionList[questionIndex].patchId =
            response?.data?.payload?.id || null;
          setPendingQuestionList(pendingQuestionList);
        }
        nav(routeUrls.home, {
          state: {
            showEdit: true,
          }
        });

        // setLoading(false);
        // setActiveStep(activeStep + 1);
      },
      onError: (err) => {
        console.log('er', answer);

        if (err?.response?.status === 400) {
          //answer.id = question?.patchId;
          patchMultiChoiceAnswer.mutate(answer, {
            onSuccess: (response) => {
              // setLoading(false);
              // setActiveStep(activeStep + 1);
            },
            onError: (error) => {
              // setLoading(false);
              // setError('Something went wrong, please try again');
            },
          });
        } else {
          // setLoading(false);
          // setError('Something went wrong, please try again');
        }
      },
    });
  };

  const getQuestionDetailsByIdentifier = (questionId) => {
    let qData = {
      id: questionId,
    };
    getQuestionDetailsById.mutate(qData, {
      onSuccess: (response) => {
        let _questionDetails = response?.data?.payload;
        setQuestionDetails(_questionDetails);
        setAnswerChoices(_questionDetails?.question_choices);
        console.log('question details: ', response?.data?.payload);
        return;
      },
      onError: (error) => {
        console.log('getQuestionDetailsById error: ', error);
      },
    });
  };

  useEffect(() => {
    if (location?.state?.questionId) {
      getQuestionDetailsByIdentifier(location?.state?.questionId);
    }
  }, [location?.state?.questionId]);

  // const handleClick = (e) => {
  //   if (e) {
  //     setSelected([...selected, e]);
  //     setAnswers([...answers, e]);
  //   }
  //   console.log('e..', answers);
  // };
  const handleClick = (id) => {
    // Check if the option is already selected
    const isAlreadySelected = selected.includes(id);
  
    if (isAlreadySelected) {
      // If the option is already selected, remove it from the selected array
      setSelected(selected.filter(item => item !== id));
      // Remove the option from the answers array
      setAnswers(answers.filter(item => item !== id));
    } else {
      // If the option is not selected, add it to the selected array
      setSelected([...selected, id]);
      // Add the option to the answers array
      setAnswers([...answers, id]);
    }
  };

  const handleSave = () => {
    console.log('saveD', answers);

    if (answers && answers.length > 0) {
      let multiChoiceAnswerData = [];
      answers.forEach((element) => {
        let answerObj = {
          value: '',
          visible: true,
          answer: element,
        };
        //find value

        let selectedAnswerObj = questionDetails?.question_choices.find(
          (choice) => {
            return choice.id === element;
          },
        );
        answerObj.value = selectedAnswerObj.value;
        multiChoiceAnswerData.push(answerObj);
      });

      let data = {
        id: questionDetails?.id,
        multiChoiceAnswerData: multiChoiceAnswerData,
      };
      console.log('saveD', answers);

      console.log('sendD', data);
      postPatchMultipleChoiceAnswer(data);
    } else {
      setError('Please provide some inputs');
      console.log('err');
    }
  };

  useEffect(() => {
    if (answers) {
      setError('');
    }
  }, [answers]);
  useEffect(() => {
    if (answerChoices) {
      setLoading(false);
    }
  }, [answerChoices]);

  return (
    <CentralContentPageLayout>
      <CentralGridLayout>
        <Loader open={loading} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                marginTop:'40px',
                marginBottom:'20px',
                height: `${scaleByRatio(90)}px`,
                // width: `${scaleByRatio(56)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>

        <Grid
          container
          marginLeft={'10px'}
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logo}
              onClick={() => nav(routeUrls.home, {
                state: {
                  showEdit: true,
                }
              })}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={() => nav(routeUrls.home, {
                state: {
                  showEdit: true,
                }
              })}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(5)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>

        <Grid
         marginLeft={'20px'}
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{ fontSize: `${scaleByRatio(22)}px` }}>
              {location?.state?.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid 
        container 
        // width="200px"
        //  ={}
        // display={'flex'} 
        direction="row">
          {answerChoices &&
            answerChoices.map((choice) => {
              return (
                <Grid
                  item
                  marginTop={'10px'}
                  // marginBottom={'10px'}
                  // m={0.5}
                  // xs={'auto'}
                  style={{ paddingRight: '15px' }}>
                  <SingleChoiceSelectionButton
                    text={choice.value}
                    id={choice.id}
                    // isSelected={selected.find((data) => data == choice.id)}
                    isSelected={selected.includes(choice.id)}
                    onClick={() => handleClick(choice.id)}
                  />
                </Grid>
              );
            })}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"></Grid>
        {/* {showVisibility && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
            <CheckBoxWithLabel
              isChecked={isVisible}
              labelText={checkBoxLabel}
              handleClick={onVisibleClick}
            />
          </Grid>
        )} */}

        {error && error?.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-end">
            <Typography
              textAlign="left"
              className={styles.error}
              style={{
                fontSize: `${scaleByRatio(18)}px`,
                lineHeight: `${scaleByRatio(40)}px`,
              }}>
              {error}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="flex-end"
          
          >
          <Grid item
          marginBottom={'20px'}
          >
            <CustomButton
              text={'Save'}
              variant={ButtonVariant.contained}
              type={ButtonType.primary}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </CentralGridLayout>
    </CentralContentPageLayout>
  );
};

export default DaterQuestions;
