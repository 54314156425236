import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import './styles.scss';
import IconClose from '../../assets/IconClose.svg';

export default function CustomAlert({ isOpen = false, message = '', onClose }) {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    console.log('CustomAlert loaded');
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: 'white',
          boxShadow: 'none',
          transform: 'translateY(-40px)'
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent', // This disables the blur by making the backdrop transparent
        },
      }}
    >

<button
        onClick={handleClose}
        className="close-icon-button"
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          background: 'none',
          border: 'none',
          padding: '0',
          cursor: 'pointer',
          zIndex: 1,
          width: '16px', // Width of the SVG icon
          height: '16px', // Height of the SVG icon
        }}
      >
        {/* SVG as background */}
        <img
        src={IconClose}
        alt="img np"
        />
      </button>

      <DialogContent className="dialog-content-container">
        <DialogContentText
          id="alert-dialog-description"
          className="alert-dialog-description-cls"
          sx={{ 
            marginTop:'40px',
            marginBottom:'20px',
            marginLeft:'31px',
            marginRight:'31px',
            whiteSpace:'pre-wrap',
            textAlign:'center',
            textTransform:'uppercase',
            color: '#44C8F5',
            fontFamily: 'Gill Sans Nova',
            fontSize: '14px',
            lineHeight: '20.08px',
            letterSpacing: '0.05em',
            fontWeight: 500,
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button 
          onClick={handleClose} 
          className="dialog-button" 
          sx={{
            color: '#808285',
            fontFamily: 'Gill Sans Nova',
            fontSize: '12px',
            fontWeight: 500,
            padding: '8px 16px',
            
          }}
          autoFocus
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
