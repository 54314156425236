import moment from "moment"

export const scaleByRatio = (unit)=>{
    // if(window.devicePixelRatio > 1){
    //     let scalePercentage = 1/window.devicePixelRatio;
    //     // if(scalePercentage === 0.5){
    //     //     scalePercentage=0.40;
    //     // }
    //    // scalePercentage = scalePercentage - (0.2*scalePercentage)
    //     let calculatedUnit = (scalePercentage*unit);
    //     return calculatedUnit;
    // }else{
    //     return unit;
    // }
    return unit
}

export const getTime = (date, format="h:mm A") =>{
    return moment(date/10000).format(format);
}

export const getFormattedDate = (date, format="DD/MM/YYYY h:mm A")=>{
   // return moment(date).local(true).format(format)
   console.log('getFormattedDate: ',moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format(format))
   return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format(format);
}