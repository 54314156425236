import React, {useEffect, useState} from 'react'
import { Button } from '@mui/material'
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper'

const SingleChoiceSelectionButtonNotification = ({
    isSelected = false,
    text,
    onClick
}) => {
    
    return ( 
        <Button
        variant={'contained'}
        onClick={()=>onClick(!isSelected)}
        className={(isSelected)?styles.buttonSelected:styles.button}
        style={{
            padding: `${scaleByRatio(8)}px ${scaleByRatio(40)}px`,
            gap: `${scaleByRatio(10)}px`,
            fontSize:`${scaleByRatio(14)}px`,
            
        }}
        >
            {text}
        </Button>
     );
}
 
export default SingleChoiceSelectionButtonNotification;