import React, { useState, useEffect, useRef, useContext } from 'react';
import Box from '@mui/material/Box';

import { Grid, Typography, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import styles from './index.module.scss';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { usePubNub } from 'pubnub-react';
import dayjs from 'dayjs';
import { scaleByRatio, getTime } from '../../utils/helper';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import ChatAttachment from '../../assets/ChatAttachment.svg';
import ChatSend from '../../assets/ChatBoxSend.svg';
import { FormControl } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import IconMore from '../../assets/IconMore.svg';
import DirectMessageMenu from '../../molecule/DirectMessageMenu';
import EndIntrowDialog from '../../molecule/EndIntrowDialog';
import MakeIntrowDialog from '../../molecule/MakeIntrowDialog';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {
  usePostBlockUser,
  useGetBlockUserStatus,
  usePatchEndIntrow,
  usePostMakeIntrow,
  usePostDaterRequestIntrow,
} from '../../services/mutations';
import CustomAlert from '../../atoms/CustomAlert';
import Loader from '../../molecule/Loader';
import ImageGallery from '../../organism/ImageGallery';
import ImageCropper from '../../molecule/ImageCropper/ImageCropper';
import AppLogo from '../../assets/AppLogo.svg';
import DaterRequestIntrow from '../DaterRequestIntrow';
import ConvosProspectiveDaterProfile from '../../organism/ConvosProspectiveDaterProfile';
import DaterProfileView from '../../organism/DaterProfileView';
import { ChatClose, ReachOutContext } from '../../utils/Context/reportCounter';
const DirectChat = ({
  myProfile,
  otherProfile,
  activeDaterFriend,
  channel,
  onBackClicked,

  convo,
}) => {
  const localStorage = useLocalStorage();
  const pubnub = usePubNub();
  const inputImage = useRef(null);
  const postBlockUser = usePostBlockUser();
  const getBlockUserStatus = useGetBlockUserStatus();
  const patchEndIntrow = usePatchEndIntrow();
  const postMakeIntrow = usePostMakeIntrow();
  const daterRequest = usePostDaterRequestIntrow();
  console.log(
    'DirectChat::.....&&',
    // convo?.requested_introw,
    // activeDaterFriend,
    // convo?.make_introw,
    // convo?.end_introw,
    // convo,
    // myProfile,
    otherProfile,
    // activeDaterFriend,
  );

  // console.log(
  //   'DirectChat::.....&& myprofile,otherprofile,chllel',
  //   myProfile,
  //   otherProfile,
  //   activeDaterFriend,
  //   channel,
  // );

  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);
  const [introw, setIntrow] = useState(convo?.make_introw);
  const [endIntrow, setEndIntrow] = useState(convo?.end_introw);
  const [showGallery, setShowGallery] = useState(false);
  const [arrGalleryImages, setArrGalleryImages] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [channels, setChannels] = useState([channel]);
  const [messages, addMessage] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [activeDater, setActiveDater] = useState(null);
  const [myProfileID] = useState(
    localStorage.getLocalStorageItem(localStorageKeys.profileId),
  );
  console.log('channelid', channels[0]);
  const [menu_id] = useState('direct-message-menu');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openEndIntrowDialog, setOpenEndIntrowDialog] = useState(false);
  const [makeIntrow, setMakeIntrow] = useState(false);
  const [makeIntrowStatus, setMakeIntrowStatus] = useState(false);
  const [endIntrowStatus, setEndIntrowStatus] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showDaterProfile, setShowDaterProfile] = useState(false);
  const [showOtherProfile, setShowOtherProfile] = useState(false);
  const [requestIntrow, setRequestIntrow] = useState(false);
  const [menu_position] = useState('left');
  const [arrMenuItem, setArrMenuItem] = useState([]); //[{name:'Block', id:'1'}]
  const [endReachOut, setEndReachOut] = useState(false);
  const [reachOutHandle, setReachOutHandle] = useContext(ReachOutContext);
  const [chatClose, setChatClose] = useContext(ChatClose);
  const [checkCounter, setCheckCounter] = useState(0);
  // console.log('reachOutHandle',reachOutHandle)
  const [arrEndIntrowMessage] = useState([
    {
      id: '1',
      text: 'It’s been nice to learn a little bit more about you, but I’m just not sure this is going to be a match. I wish you well on your journey.',
    },
    {
      id: '2',
      text: "It's been good to chat with you, but I think there is a stronger connection with some other people. But I wish you well. The right person is out there for you.",
    },
    {
      id: '3',
      text: `${
        localStorage.getLocalStorageItem(localStorageKeys.userType) === 'dater'
          ? `It's been nice to chat with you and learn about for friend, and really appreciate your time, but I think maybe it's not a match. I wish you and your friend all the best on this journey.`
          : `It's been nice to chat with you and learn about you, and really appreciate your time, but I think maybe it's not a match. I wish all the best on this journey.`
      }`,
    },
    {
      id: '99',
      text: 'Custom Message',
    },
  ]);
  const [endReachOutMessage] = useState([
    {
      id: '1',
      text: "It's been nice to chat with you and learn about for friend, and really appreciate your time, but I think maybe it's not a match. I wish you and your friend all the best on this journey.",
    },
    {
      id: '99',
      text: 'Custom Message',
    },
  ]);

  const [showImageCropper, setShowImageCropper] = useState(false);
  const [fileUpload, setFileUpload] = useState({ imageUrl: null, flag: false });

  const handleMenuButtonClick = (event) => {
    console.log('handleMenuButtonClick::', event.currentTarget);
    setAnchorEl(event.currentTarget);
    setOpenMenu(Boolean(event.currentTarget));
  };
  const callbackMenuFunction = (menu_id) => {
    setAnchorEl(null);
    setOpenMenu(false);
    console.log('callbackMenuFunction: ', menu_id);
    if (menu_id === '3') {
      //block user
      blockOtherUser();
    }
    if (menu_id === '1') {
      setOpenEndIntrowDialog(true);
    }
    if (menu_id === '0') {
      setMakeIntrow(true);
      // makeAnIntrowAlert();
      // callMakeIntrow();
    }
  };

  const onYesCallBackEndIntrow = (id, messageText) => {
    console.log('onYesCallBackEndIntrow : ', id, messageText);
    console.log('channel: ', channels[0]);
    let _message = messageText;
    if (id !== arrEndIntrowMessage[arrEndIntrowMessage.length - 1].id) {
      let msgIndex = arrEndIntrowMessage.findIndex((msg) => msg.id === id);
      if (msgIndex > -1) {
        _message = arrEndIntrowMessage[msgIndex].text;
      }
    }
    let message = {
      text: _message,
      user: {
        name: myProfile?.user?.first_name,
        profilePic: myProfile?.user?.profile_pic,
      },
    };
    if (message) {
      pubnub.publish({ channel: channels[0], message }).then(() => {
        setCurrentMessage('');
        callEndIntrow();
      });
    }
    setEndIntrowStatus(true);
    setMakeIntrowStatus(true);
    setOpenEndIntrowDialog(false);
  };

  const callEndIntrow = () => {
    let data = {
      convo_id: channels[0],
    };
    setLoading(true);

    patchEndIntrow.mutate(data, {
      onSuccess: (response) => {
        console.log('callEndIntrow success: ', response);
        setLoading(false);
        // onBackClicked()
        setAlertMsg('Introw Ended!');
        setEndReachOut(true);
        setIntrow(true);
        setEndIntrow(true);
        setEndIntrowStatus(true);
      },
      onError: (error) => {
        setLoading(false);
        setAlertMsg(error?.response?.data?.error || 'Something went wrong');
      },
    });
  };

  const handleShowDaterProfile = (dater_check) => {
    console.log('dater_check', dater_check, showProfile, showDaterProfile);
    if (dater_check) {
      setShowProfile(false);
      setShowOtherProfile(false);
      setShowDaterProfile(true);
    }
  };

  const handleShowOthersProfile = () => {
    setShowDaterProfile(false);
    setShowProfile(false);
    setShowOtherProfile(true);
  };

  const handleShowProfile = () => {
    setShowDaterProfile(false);
    setShowOtherProfile(false);
    setShowProfile(true);
  };

  const introwPopupSubmit = () => {
    makeAnIntrowAlert('');
    callMakeIntrow();
  };

  const makeAnIntrowAlert = () => {
    setMakeIntrow('Are you sure you want to make an introw?');
  };

  const callMakeIntrow = async () => {
    setLoading(true);
    let data = {
      convo_id: channels[0],
    };
    postMakeIntrow.mutate(data, {
      onSuccess: (response) => {
        setIntrow(true);
        setLoading(false);
        // setCurrentMessage('Congratulations! You have successfully made an introw');
        // let msg='Congratulations! You have successfully made an introw';
        sendMessage(true);
        setAlertMsg('Introw Created');
        setMakeIntrow(false);
        setMakeIntrowStatus(true);
      },
      onError: (error) => {
        console.log('callMakeIntrow error: ', error);
        setLoading(false);
        // setMakeIntrowStatus(true);
        setMakeIntrow(false);
        setAlertMsg(error?.response?.data?.error || 'Something went wrong');
      },
    });
  };

  const callRequestIntrow = () => {
    setLoading(true);
    let data = {
      id: channels[0],
    };
    daterRequest.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setRequestIntrow(false);
        setAlertMsg('Request Created !');
      },
      onError: (error) => {
        console.log('callMakeIntrow error: ', error);
        setLoading(false);
        setRequestIntrow(false);
        setMakeIntrow(false);
        setAlertMsg(error?.response?.data?.error || 'Something went wrong');
      },
    });
  };

  const onNoCallBackEndIntrow = (id) => {
    console.log('onNoCallBackEndIntrow : ', id);
    setOpenEndIntrowDialog(false);
  };

  const fetchBlockUserStatus = () => {
    setLoading(true);
    let data = {
      user_id: otherProfile?.user?.id,
    };
    getBlockUserStatus.mutate(data, {
      onSuccess: (response) => {
        let _isBlocked =
          response?.data?.payload?.blocked_by_him ||
          response?.data?.payload?.blocked_by_me;
        setIsBlocked(_isBlocked);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };

  const blockOtherUser = () => {
    setLoading(true);
    let data = {
      user_id: otherProfile?.user?.id,
    };
    postBlockUser.mutate(data, {
      onSuccess: (response) => {
        setIsBlocked(true);
        setLoading(false);
        setAlertMsg(`${otherProfile?.user?.name} is blocked!!`);
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };
  const onAlertClose = () => {
    setAlertMsg('');
  };
  const handleCurrentMessageChange = (evt) => {
    setCurrentMessage(evt.target.value);
  };
  const sendMessage = (introw_flag) => {
    if (currentMessage.trim().length > 0) {
      let message = {
        text: currentMessage,
        user: {
          name: myProfile?.user?.first_name,
          profilePic: myProfile?.user?.profile_pic,
        },
      };
      if (message) {
        pubnub
          .publish({ channel: channels[0], message })
          .then(() => setCurrentMessage(''));
      }
    } else if (introw_flag) {
      let message = {
        text: `I think you should connect with my friend and so I'm making an introw! All the best with the next steps!`,
        user: {
          name: myProfile?.user?.first_name,
          profilePic: myProfile?.user?.profile_pic,
        },
      };
      if (message) {
        pubnub
          .publish({ channel: channels[0], message })
          .then(() => setCurrentMessage(''));
      }
    }
  };
  const sendFile = async (_file) => {
    console.log('sendFile::', _file);

    let message = {
      channel: channels[0],
      message: {
        user: {
          name: myProfile?.user?.first_name,
          profilePic: myProfile?.user?.profile_pic,
        },
        type: 'image',
      },
      file: _file,
    };
    console.log('send file message: ', message);
    let result = await pubnub.sendFile(message);
    console.log('send file result: ', result);
  };
  const handleDirectMessage = (event) => {
    const ev_message = event.message;
    console.log('handleDirectMessage event: ', event);
    let message = {
      ownerProfileID: event.publisher,
      profile_pic: ev_message?.user?.profilePic,
      message: ev_message?.text,
      time: getTime(parseInt(event.timetoken)),
      file: null,
    };
    if (message) {
      addMessage((messages) => [...messages, message]);
    }
  };
  const handleFiles = (event) => {
    console.log('pubnub handleFiles: ', event.file);
    console.log('pubnub handleFiles publisher: ', event.publisher);
    console.log('pubnub handleFiles event: ', event);
    let message = {
      profile_pic: event?.message?.user?.profilePic,
      message: '',
      time: getTime(event.timetoken),
      ownerProfileID: event?.publisher,
      file: {
        id: event?.file?.id,
        name: event?.file?.name,
        url: event?.file?.url,
      },
    };
    addMessage((messages) => [...messages, message]);
  };
  const handleInputImage = (e) => {
    console.log('handleInputImage::', e.target.files);
    if (e.target.files.length > 0) {
      setShowImageCropper(true);
      setFileUpload({
        imageUrl: URL.createObjectURL(e.target.files[0]),
        flag: true,
      });

      setCurrentFile(e.target.files[0]);
      //sendFile(e.target.files[0])
    }
  };

  const callBackImageCropped = (imageFile) => {
    setShowImageCropper(false);
    setCurrentFile(imageFile);
    console.log('callBackImageCropped::', imageFile);
    sendFile(imageFile);
    setFileUpload({
      imageUrl: URL.createObjectURL(imageFile),
      flag: true,
    });
  };
  const callBackImageNotCropped = () => {
    setShowImageCropper(false);
    sendFile(currentFile);
    console.log('callBackImageNotCropped');
  };
  const handleCloseModalImageCropper = () => {
    setShowImageCropper(false);
    setFileUpload({ imageUrl: null, flag: false });
    console.log('handleCloseModalImageCropper');
  };
  const prepareHistoricalMessage = (channelMessageArray) => {
    console.log('prepareHistoricalMessage: ', channelMessageArray);
    let arr = [];
    if (channelMessageArray && channelMessageArray.length > 0) {
      channelMessageArray?.forEach((messageElem) => {
        if (messageElem?.message?.message?.type !== 'image') {
          let message = {
            ownerProfileID: messageElem?.uuid,
            profile_pic: messageElem?.message?.user?.profilePic,
            message: messageElem?.message?.text,
            time: getTime(parseInt(messageElem.timetoken)),
            file: null,
          };
          arr.push(message);
        } else {
          console.log('File message: ', messageElem);
          let channel = messageElem?.channel;
          let file_id = messageElem?.message?.file?.id;
          let file_name = messageElem?.message?.file?.name;
          const file_url = pubnub.getFileUrl({
            channel: channel,
            id: file_id,
            name: file_name,
          });
          console.log('file url: ', file_url);
          let fileMessage = {
            profile_pic: messageElem?.message?.message?.user?.profilePic,
            message: '',
            time: getTime(parseInt(messageElem.timetoken)),
            ownerProfileID: messageElem?.uuid,
            file: {
              id: file_id,
              name: file_name,
              url: file_url,
            },
          };
          arr.push(fileMessage);
        }
      });
      addMessage(arr);
    }
  };

  const handleRequestIntrow = (messages) => {
    // if (messages[1] !== undefined || messages[0] !== undefined)
    const found = messages.find(
      (message) => message.ownerProfileID != myProfileID,
    );

    console.log(
      'messages.includes(myProfileID)::',

      found,
      // messages.includes(ownerProfileID == myProfileID),
      convo?.id,
      messages.includes(otherProfile?.user?.id),
    );
    if (found != undefined) setRequestIntrow(true);
  };

  const onBackClickedData = () => {
    onBackClicked();
  };

  const showImagePreview = (image) => {
    let arr = [];
    console.log(image);
    if (image) {
      let data = {
        label: '',
        image: image,
      };
      arr.push(data);
      setArrGalleryImages(arr);
    }
  };

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log('arrGalleryImages: ', arrGalleryImages);
    if (arrGalleryImages.length > 0) {
      setShowGallery(true);
    }
  }, [arrGalleryImages]);
  useEffect(() => {
    console.log('useeffect subscription');
    if (channels.length > 0) {
      const listenerParams = { message: handleDirectMessage };
      const fileListnerParams = { file: handleFiles };
      pubnub.addListener(listenerParams);
      pubnub.addListener(fileListnerParams);
      pubnub.subscribe({ channels });

      let currentToken = (
        Math.floor(new Date('2023-10-01').getTime() / 1000) * 10000000
      ).toString();
      pubnub.fetchMessages(
        {
          channels: channels,
          end: currentToken, //'16970822938689523',
          count: 100,
        },
        function (status, response) {
          console.log('>>>>>>>>>>>> Historical message start');
          console.log(status, response);
          if (status?.statusCode === 200) {
            prepareHistoricalMessage(response.channels[channels[0]] || []);
          }

          console.log('>>>>>>>>>>>> Historical message end');
        },
      );
    }
    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener({ message: handleDirectMessage });
      pubnub.removeListener({ file: handleFiles });
    };
  }, [pubnub, channels]);
  useEffect(() => {
    console.log('useeffect onload');
    console.log('Channel::', channel);
    console.log('introw', introw);
    setReachOutHandle(false);
    setChatClose(false);
    setCheckCounter(1);
    setIntrow(convo?.make_introw);
    setEndIntrow(convo?.end_introw);
    fetchBlockUserStatus();
    if (convo?.end_introw) {
      setArrMenuItem([
        { name: 'Report', id: '2' },
        { name: 'Block', id: '3' },
        { name: 'Cancel', id: '4' },
      ]);
    } else if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.dater
    ) {
      if (convo?.make_introw || introw || endReachOut) {
        setArrMenuItem([
          // { name: 'Make An Introw', id: '0' },
          // { name: 'End this Reach Out', id: '1' },
          { name: 'Report', id: '2' },
          { name: 'Block', id: '3' },
          { name: 'Cancel', id: '4' },
        ]);
      } else {
        setArrMenuItem([
          {
            name: `${
              convo?.session_type == 'PIC'
                ? 'End this Reach Out'
                : 'End this Introw'
            }`,
            id: '1',
          },
          { name: 'Report', id: '2' },
          { name: 'Block', id: '3' },
          { name: 'Cancel', id: '4' },
        ]);
      }
    } else {
      if (!convo?.make_introw) {
        setArrMenuItem([
          { name: 'Make An Introw', id: '0' },
          { name: 'End this Reach Out', id: '1' },
          { name: 'Report', id: '2' },
          { name: 'Block', id: '3' },
          { name: 'Cancel', id: '4' },
        ]);
      } else {
        setArrMenuItem([
          //{name:'End this Reach Out', id:'1'},
          { name: 'Report', id: '2' },
          { name: 'Block', id: '3' },
          { name: 'Cancel', id: '4' },
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (reachOutHandle && checkCounter == 1) {
      onBackClickedData();
    }
  }, [reachOutHandle, checkCounter]);

  useEffect(() => {
    if (chatClose && checkCounter == 1) {
      onBackClickedData();
    }
  }, [chatClose, checkCounter]);

  const handleMakeIntrow = () => {
    setMakeIntrow(true);
  };
  console.log('messages', messages[1], messages.length);

  const onProspectiveDaterProfileBackClicked = () => {
    setShowProfile(false);
    setShowDaterProfile(false);
    setShowOtherProfile(false);
  };

  const handleDeclineIntrow = () => {
    setOpenEndIntrowDialog(true);
  };
  const handleBack = () => {
    setShowDaterProfile(false);
  };
  useEffect(() => {
    if (endIntrow || introw) {
      setArrMenuItem([
        { name: 'Report', id: '2' },
        { name: 'Block', id: '3' },
        { name: 'Cancel', id: '4' },
      ]);
    }
  }, [endIntrow, introw]);

  // useEffect(() => {
  //   daterRequest.mutate({convo_id:channels[0]}, {
  // }, []);

  return (
    <>
      {showProfile ? (
        <ConvosProspectiveDaterProfile
          convo={convo}
          setShowProfile={setShowProfile}
          onBackClicked={onProspectiveDaterProfileBackClicked}
        />
      ) : showDaterProfile || showOtherProfile ? (
        <DaterProfileView
          otherProfile={showOtherProfile ? otherProfile : undefined}
          activeDaterOfFriend={
            showOtherProfile ? convo?.prospective_dater?.id : undefined
          }
          onBackClicked={onProspectiveDaterProfileBackClicked}
          chatFlag={true}
        />
      ) : (
        <>
          {alertMsg.length > 0 && (
            <CustomAlert
              isOpen={alertMsg.length > 0 ? true : false}
              message={alertMsg}
              onClose={onAlertClose}
            />
          )}
          <Loader open={loading} />
          {!loading && (
            <>
              {convo?.session_type == 'DIC' &&
                localStorage.getLocalStorageItem(localStorageKeys.userType) ===
                  localStorageUserType.dater && (
                  <>
                    <Grid>
                      <Typography
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          // color: '#fff',
                        }}>
                        {!endReachOut && !convo?.end_introw && !isBlocked && (
                          <>
                            {' '}
                            <Button
                              className={styles.btnCreateAccount}
                              style={{
                                marginTop: '-7px',
                                marginBottom: '5px',
                                padding: `${scaleByRatio(10)}px ${scaleByRatio(
                                  30,
                                )}px`,
                                fontSize: `${scaleByRatio(16)}px`,
                                lineHeight: `${scaleByRatio(18)}px`,
                                color: '#E66433',
                                fontSize: `${scaleByRatio(14)}px`,
                              }}
                              onClick={handleDeclineIntrow}>
                              {/* request */}
                              End This Introw
                            </Button>
                            {/* <img src={AppLogo} /> */}
                          </>
                        )}
                      </Typography>
                    </Grid>

                   {!endReachOut && !convo?.end_introw && !isBlocked && <hr
                      style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        border: 'none',
                        height: '0.5px',
                        marginBottom: '5px',
                        backgroundColor: '#cccccc',
                      }}
                    />}
                  </>
                )}

              {convo?.session_type == 'PIC' &&
                localStorage.getLocalStorageItem(localStorageKeys.userType) ===
                  localStorageUserType.dater && (
                  // (convo?.end_introw == true || convo?.make_introw == true ? (
                  //   <></>
                  // ) : convo?.end_introw == false || convo?.make_introw == true ? (
                  //   <Grid>
                  //     <Typography
                  //       style={{
                  //         display: 'flex',
                  //         justifyContent: 'center',
                  //         // color: '#fff',
                  //       }}>
                  //       {!convo?.end_introw ||
                  //         (!convo?.requested_introw && (
                  //           <>
                  //             {' '}
                  //             <span
                  //               style={{
                  //                 color: '#76d9ff',
                  //                 fontSize: `${scaleByRatio(22)}px`,
                  //               }}
                  //               onClick={handleDeclineIntrow}>
                  //               {/* request */}
                  //               End this
                  //             </span>
                  //             <img src={AppLogo} />
                  //           </>
                  //         ))}
                  //     </Typography>
                  //   </Grid>
                  // ) : convo?.end_introw == false || convo?.make_introw == false ? (
                  //   <Grid>
                  //     <Typography
                  //       style={{
                  //         display: 'flex',
                  //         justifyContent: 'center',
                  //         // color: '#fff',
                  //       }}>
                  //       {/* {convo?.requested_introw == false && (
                  //         <span
                  //           style={{
                  //             color: '#76d9ff',
                  //             fontSize: `${scaleByRatio(22)}px`,
                  //           }}
                  //           onClick={() => {
                  //             handleRequestIntrow(messages);
                  //           }}>
                  //           request
                  //         </span>
                  //       )} */}

                  //       {convo?.requested_introw == true && (
                  //         <span
                  //           style={{
                  //             color: '#76d9ff',
                  //             fontSize: `${scaleByRatio(22)}px`,
                  //           }}
                  //           onClick={handleDeclineIntrow}>
                  //           {/* request */}
                  //           End this
                  //         </span>
                  //       )}
                  //       <img src={AppLogo} />
                  //       {/* {localStorage.getLocalStorageItem(
                  //         localStorageKeys.userType,
                  //       ) === localStorageUserType.friend && (
                  //         <span
                  //           style={{
                  //             color: '#fff',
                  //             fontSize: `${scaleByRatio(20)}px`,
                  //           }}>
                  //           :{' '}
                  //           <span
                  //             onClick={handleMakeIntrow}
                  //             style={{ cursor: 'pointer' }}>
                  //             make
                  //           </span>
                  //           /{' '}
                  //           <span
                  //             onClick={handleDeclineIntrow}
                  //             style={{ cursor: 'pointer' }}>
                  //             decline
                  //           </span>
                  //         </span>
                  //       )} */}
                  //     </Typography>
                  //   </Grid>
                  // ) : convo?.end_introw == false || convo?.make_introw == true ? (
                  //   <Grid>
                  //     <Typography
                  //       style={{
                  //         display: 'flex',
                  //         justifyContent: 'center',
                  //         // color: '#fff',
                  //       }}>
                  //       {!convo?.end_introw && (
                  //         <>
                  //           {' '}
                  //           <span
                  //             style={{
                  //               color: '#76d9ff',
                  //               fontSize: `${scaleByRatio(22)}px`,
                  //             }}
                  //             onClick={handleDeclineIntrow}>
                  //             {/* request */}
                  //             End this
                  //           </span>
                  //           <img src={AppLogo} />
                  //         </>
                  //       )}
                  //     </Typography>
                  //   </Grid>
                  // ) : (
                  //   ''
                  // ))

                  <Typography
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      // color: '#fff',
                    }}>
                    {/* {!endReachOut && !convo?.end_introw && (
                  <>
                    <span
                      style={{
                        color: '#76d9ff',
                        fontSize: `${scaleByRatio(22)}px`,
                      }}
                      onClick={handleDeclineIntrow}>
                      End this
                    </span>
                  </>
                )} */}
                    {/* <img src={AppLogo} /> */}
                  </Typography>
                )}

              {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
                localStorageUserType.friend &&
                (!convo?.make_introw || !convo?.end_introw) && (
                  <Grid>
                    <Typography
                      style={{
                        display: 'flex',
                        // justifyContent: 'center',
                        justifyContent: 'space-between',
                        // // color: '#fff',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}>
                      {/* <img src={AppLogo} /> */}

                      {/* <span
                        style={{
                          color: '#fff',
                          fontSize: `${scaleByRatio(20)}px`,
                        }}> */}
                      {/* {!!makeIntrowStatus && ( */}
                      <Button
                        className={styles.friendButton}
                        onClick={
                          !introw &&
                          !endIntrow &&
                          !isBlocked &&
                          // || (introw && !endIntrow) || (introw && endIntrow) || (introw && !endIntrow) || (!introw && !endIntrow) || (!introw &&
                          // || !convo?.make_introw
                          // !makeIntrowStatus  &&
                          handleMakeIntrow
                        }
                        //#e6a48c
                        style={{
                          cursor: `${
                            !introw && !endIntrow && !isBlocked ? 'pointer' : ''
                          }`,
                          backgroundColor: `${
                            !introw && !endIntrow && !isBlocked
                              ? '#2BB673'
                              : '#7CC1A0'
                          }`,
                        }}>
                        Make
                      </Button>

                      <span className={styles.centerText}>Introw</span>

                      {/* )}
                     {!endIntrowStatus && ( */}
                      <Button
                        className={styles.daterButton}
                        onClick={
                          !convo?.end_introw &&
                          !convo?.make_introw &&
                          !endIntrowStatus &&
                          !introw &&
                          !isBlocked &&
                          handleDeclineIntrow
                        }
                        style={{
                          cursor: 'pointer',
                          backgroundColor: `${
                            !convo?.end_introw &&
                            !convo?.make_introw &&
                            !endIntrowStatus &&
                            !introw &&
                            !isBlocked
                              ? '#E66433'
                              : '#e6a48c'
                          }`,
                        }}>
                        Decline
                      </Button>
                      {/* )} */}
                      {/* </span> */}

                      {/* <Grid>
                        <Button
                          className={styles.daterButton}
                          onClick={() => {}}>
                          Dater
                        </Button>
                        <Button
                          className={styles.friendButton}
                          onClick={() => {}}>
                          Friend
                        </Button>
                      </Grid> */}
                    </Typography>
                    <hr
                      style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        border: 'none',
                        height: '0.5px',
                        marginBottom: '5px',
                        backgroundColor: '#cccccc',
                      }}
                    />
                  </Grid>

                  // : (
                  //   <Grid>
                  //     <Typography
                  //       style={{
                  //         display: 'flex',
                  //         justifyContent: 'center',
                  //         // color: '#fff',
                  //       }}>
                  //       <img src={AppLogo} />

                  //       <span
                  //         style={{
                  //           color: '#fff',
                  //           fontSize: `${scaleByRatio(20)}px`,
                  //         }}>
                  //         :{' '}
                  //         <span
                  //           onClick={!convo?.make_introw && handleMakeIntrow}
                  //           style={{ cursor: 'pointer' }}>
                  //           make
                  //         </span>
                  //         /{' '}
                  //         <span
                  //           onClick={handleDeclineIntrow}
                  //           style={{ cursor: 'pointer' }}>
                  //           decline
                  //         </span>
                  //       </span>
                  //     </Typography>
                  //   </Grid>
                  // )
                )}

              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  // pb: 3,
                  // pt: scaleByRatio(3),
                  background: '#000',
                }}>
               { <Grid container item direction="row" justifyContent="left" 
                alignItems="center" xs={12}
                marginLeft={'20px'}
                >
                <img
                  src={ArrowLeft}
                  alt={'Back icon'}
                  className={styles.logoBack}
                  onClick={onBackClicked}
                  width={scaleByRatio(12)}
                  height={scaleByRatio(12)}
                />
              {/* </Typography> */}
              <Typography
                textAlign="left"
                className={styles.back}
                onClick={onBackClicked}
                style={{
                  fontSize: `${scaleByRatio(12)}px`,
                  paddingLeft: `${scaleByRatio(10)}px`,
                  paddingBottom: `${scaleByRatio(5)}px`,
                  paddingTop: `${scaleByRatio(3)}px`,
                }}>
                Back
              </Typography>
                </Grid>}
                <Grid
                  container
                  direction="row"
                  sx={{
                    background: '#000',
                    minHeight: `${scaleByRatio(50)}px`,
                    // paddingBottom: `${scaleByRatio(10)}px`,
                    paddingTop: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                  className={styles.firstSec}>
                  <div
                    className={styles.firstpart}
                    style={{
                      // display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}>
                    <Grid
                      item
                      justifyContent="center"
                      alignItems="center"
                      className={styles.logoContainer}
                      style={{
                        display: `flex`,
                      }}>
                      <Typography textAlign="left">
                        <img
                          src={
                            otherProfile?.user?.profile_pic || Profile_Avatar
                          }
                          onClick={handleShowOthersProfile}
                          alt={''}
                          style={{
                            width: `${scaleByRatio(72)}px`,
                            height: `${scaleByRatio(72)}px`,
                            borderRadius: `50%`,
                            objectFit: 'cover',
                            // border: `2px solid ${
                            //   convo?.elbow?.interest_type === 'Y'
                            //     ? '#02E242'
                            //     : '#F90'
                            // }`,
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      justifyContent="center"
                      alignItems={'center'}
                      style={{
                        display: `flex`,
                        marginBottom: `${scaleByRatio(10)}px`,
                      }}>
                      <Typography
                        textAlign="left"
                        className={styles.name}
                        style={{
                          fontSize: `${scaleByRatio(12)}px`,
                        }}>
                        {otherProfile?.user?.name.substring(
                          0,
                          otherProfile?.user?.name.indexOf(' '),
                        )}{' '}
                        {/* {otherProfile?.user?.age ? ',' : ''} {otherProfile?.user?.age} */}
                      </Typography>
                    </Grid>
                  </div>

                  <Grid
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems="center"
                    style={{ color: '#fff' }}>
                    <Typography
                      style={{
                        fontFamily: 'Gill Sans Nova',
                        alignItems: 'center',
                        color:
                          localStorage.getLocalStorageItem(
                            localStorageKeys.userType,
                          ) === localStorageUserType.friend
                            ? '#808184'
                            : '#7F8082',
                        fontSize:
                          localStorage.getLocalStorageItem(
                            localStorageKeys.userType,
                          ) === localStorageUserType.friend
                            ? '14px'
                            : '15px',
                        paddingBottom: '20px',
                        lineHeight: '16px',
                        letterSpacing: '0.12em',
                        textTransform: 'uppercase',
                      }}>
                      {localStorage.getLocalStorageItem(
                        localStorageKeys.userType,
                      ) === localStorageUserType.dater &&
                      otherProfile?.user?.id !== convo?.prospective_dater?.id
                        ? 'Friend of'
                        : ''}

                      {/* {localStorage.getLocalStorageItem(
                      localStorageKeys.userType,
                    ) === localStorageUserType.friend
                      ? // &&
                        // otherProfile?.user?.id !== convo?.prospective_dater?.id
                        'Interested in'
                      : ''} */}

                      {localStorage.getLocalStorageItem(
                        localStorageKeys.userType,
                      ) === localStorageUserType.friend
                        ? // &&
                          // otherProfile?.user?.id !== convo?.prospective_dater?.id
                          'Interested in'
                        : ''}
                    </Typography>

                    {/* <Typography
                    style={{
                      alignItems:'center',

                      color: '#fff',
                      paddingRight: '25px',
                      fontSize: '20px',
                    }}>
                    {/* {localStorage.getLocalStorageItem(
                      localStorageKeys.userType,
                    ) === localStorageUserType.dater &&
                    otherProfile?.user?.id !== convo?.prospective_dater?.id
                      ? 'Friend of'
                      : ''} */}
                  </Grid>

                  <div className="secPart">
                    <Grid
                      item
                      direction="row"
                      display="flex"
                      justifyContent="right"
                      alignItems={'center'}
                      style={{
                        // display: `flex`,
                        // paddingRight: `${scaleByRatio(10)}px`,
                        marginRight: '-40px',
                      }}>
                      <Grid
                        direction="column"
                        justifyContent="center"
                        marginRight="5px"
                        marginTop="-10px"
                        alignItems="flex-start" // Aligns items to the top
                      >
                        <Typography display={'flex'} justifyContent={'right'}>
                          {localStorage.getLocalStorageItem(
                            localStorageKeys.userType,
                          ) === localStorageUserType.dater &&
                            otherProfile?.user?.id !==
                              convo?.prospective_dater?.id && (
                              <img
                                src={`${convo?.prospective_dater?.profile_pic}`}
                                alt={''}
                                onClick={
                                  localStorage.getLocalStorageItem(
                                    localStorageKeys.userType,
                                  ) === localStorageUserType.dater &&
                                  handleShowProfile
                                }
                                style={{
                                  width: `${scaleByRatio(72)}px`,
                                  height: `${scaleByRatio(72)}px`,
                                  objectFit: 'cover',
                                  borderRadius: `50%`,
                      
                                  marginBottom: '4px',
                                  // border: `2px solid #02E242`

                                  // border: `2px solid ${
                                  //   convo?.elbow?.interest_type === 'Y' ? '#02E242' : '#F90'
                                  // }`,
                                }}
                              />
                            )}

                          {localStorage.getLocalStorageItem(
                            localStorageKeys.userType,
                          ) === localStorageUserType.friend && (
                            // &&
                            //   otherProfile?.user?.id !==
                            //     convo?.prospective_dater?.id
                            <img
                              src={`${activeDaterFriend?.profile_pic}`}
                              onClick={
                                localStorage.getLocalStorageItem(
                                  localStorageKeys.userType,
                                ) === localStorageUserType.friend
                                  ? () => handleShowDaterProfile(true)
                                  : console.log('Hello')
                              }
                              alt={''}
                              style={{
                                width: `${scaleByRatio(70)}px`,
                                height: `${scaleByRatio(70)}px`,
                                borderRadius: `50%`,
                                objectFit: 'cover',
                             
                              }}
                            />
                          )}
                        </Typography>

                        <Typography
                          className={styles.name}
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            justifyContent: 'center',
                            // paddingRight: `${isBlocked?'10px':'55px'}`,
                          }}>
                          {localStorage.getLocalStorageItem(
                            localStorageKeys.userType,
                          ) === localStorageUserType.dater &&
                          otherProfile?.user?.id !==
                            convo?.prospective_dater?.id
                            ? `${
                                convo?.prospective_dater?.user?.first_name.includes(
                                  ' ',
                                )
                                  ? convo?.prospective_dater?.user?.first_name.substring(
                                      0,
                                      convo?.prospective_dater?.user?.first_name.indexOf(
                                        ' ',
                                      ),
                                    )
                                  : convo?.prospective_dater?.user?.first_name
                              }`
                            : ''}

                          {localStorage.getLocalStorageItem(
                            localStorageKeys.userType,
                          ) === localStorageUserType.friend
                            ? // &&
                              // otherProfile?.user?.id !== convo?.prospective_dater?.id
                              // myProfile?.user?.name.substring(
                              //   0,
                              //   myProfile?.user?.name.indexOf(' '),
                              // )
                              activeDaterFriend?.user?.first_name
                            : ''}
                        </Typography>
                      </Grid>

                      {!isBlocked && (
                        <IconButton
                          color="inherit"
                          id="directChatMoreButton"
                          edge="start"
                          sx={{
                            mr: 2,
                            color: '#E67C39',
                            marginBottom: '20px',
                          }}
                          aria-controls={openMenu ? menu_id : undefined}
                          aria-haspopup="true"
                          aria-expanded={openMenu ? 'true' : undefined}
                          onClick={handleMenuButtonClick}>
                          <MoreVertOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      )}
                      {openMenu && (
                        <DirectMessageMenu
                          menu_id={menu_id}
                          menuAnchorEL={anchorEl}
                          position={menu_position}
                          arrMenuItem={arrMenuItem}
                          callbackFunction={callbackMenuFunction}
                        />
                      )}
                      {openEndIntrowDialog && (
                        <EndIntrowDialog
                          isOpen={openEndIntrowDialog}
                          messageArr={arrEndIntrowMessage}
                          customMessageID={
                            arrEndIntrowMessage[arrEndIntrowMessage.length - 1]
                              .id
                          }
                          heading={''}
                          onYesClick={onYesCallBackEndIntrow}
                          onNoClick={onNoCallBackEndIntrow}
                          convo={convo}
                        />
                      )}
                      {makeIntrow && (
                        <MakeIntrowDialog
                          isOpen={makeIntrow}
                          onConfirmClick={callMakeIntrow}
                          onCancelClick={() => setMakeIntrow(false)}
                        />
                      )}
                      {requestIntrow && (
                        <DaterRequestIntrow
                          isOpen={requestIntrow}
                          onConfirmDaterRequest={callRequestIntrow}
                          onCancelClick={() => setRequestIntrow(false)}
                        />
                      )}
                      {/* <img src={IconMore} 
                        alt={''}
                        style={{
                            width:`${scaleByRatio(16)}px`,
                            heigt:`${scaleByRatio(16)}px`,
                        }}
                        aria-controls={openMenu ? menu_id : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleMenuButtonClick}
                    /> */}
                    </Grid>
                  </div>
                </Grid>
                <hr
                  style={{
                    marginLeft: '20px',
                    marginRight: '20px',
                    border: 'none',
                    height: '0.5px',
                    marginBottom: '5px',
                    backgroundColor: '#cccccc',
                  }}
                />

                <Grid
                  container
                  xs={12}
                  direction="row"
                  alignContent={'start'}
                  sx={{
                    width: isWideScreen ? 'calc(100vw - 309px)' : '100vw', // Adjust width based on screen width
                  }}
                  style={{
                    minHeight:
                      messages.length > 0
                        ? 'calc(100vh - 370px)'
                        : 'calc(100vh - 370px)',
                    maxHeight: 'calc(100vh - 390px)',
                    overflowY: 'auto',
                    padding: `5px 10px 90px 10px`,
                    flexShrink: 1,
                    display: `flex`,
                    // paddingBottom: '70px',
                  }}>
                  {messages &&
                    messages.length > 0 &&
                    messages.map((chat) => {
                      return (
                        <Grid container xs={12}>
                          {/* {chat.ownerProfileID !== myProfileID && (
                        <Grid
                          item
                          xs={1}
                          justifyContent="right"
                          alignItems={'flex-end'}
                          style={{
                            display: `flex`,
                            height: `${scaleByRatio(70)}px`,
                            padding: `${scaleByRatio(5)}px`,
                            
                          }}>
                          <img
                            src={chat.profile_pic || Profile_Avatar}
                            alt={''}
                            style={{
                              width: `${scaleByRatio(32)}px`,
                              height: `${scaleByRatio(32)}px`,
                              borderRadius: '4px',
                              border: `2px solid #E5E5E5`,
                            }}
                          />
                        </Grid>
                      )} */}
                          {chat.ownerProfileID === myProfileID && (
                            <Grid
                              item
                              xs={1}
                              justifyContent="left"
                              alignItems={'flex-end'}></Grid>
                          )}
                          <Grid
                            maxWidth={'100%'}
                            item
                            xs={12}
                            justifyContent={
                              chat.ownerProfileID !== myProfileID
                                ? 'left'
                                : 'right'
                            }
                            alignContent={'start'}
                            style={{
                              display: 'flex',
                            }}>
                            <Typography
                              fullWidth
                              textAlign={
                                chat.ownerProfileID !== myProfileID
                                  ? 'left'
                                  : 'right'
                              }
                              style={{ marginTop: '5px' }}>
                              <Typography
                                textAlign={
                                  chat.ownerProfileID !== myProfileID
                                    ? 'left'
                                    : 'right'
                                }
                                className={styles.message}
                                style={{
                                  padding: `${scaleByRatio(14)}px`,
                                  paddingRight: `${scaleByRatio(20)}px`,
                                  paddingLeft: `${scaleByRatio(20)}px`,
                                  paddingBottom: `${scaleByRatio(14)}px`,
                                  // minWidth: isWideScreen ? 'calc(100vw - 410px)' : '70vw', // Adjust width based on screen width
                                  maxWidth: isWideScreen
                                    ? 'calc(100vw - 410px)'
                                    : '70vw', // Adjust width based on screen width
                                  // minWidth: '70vw',
                                  // maxWidth: '70vw',
                                  marginBottom: '10px',
                                  gap: `${scaleByRatio(10)}px`,
                                  // fontSize: `${scaleByRatio(16)}px`,
                                  backgroundColor: `${
                                    chat.ownerProfileID !== myProfileID
                                      ? '#CCCCCC'
                                      : '#EB4121'
                                    // '#CED8EC'
                                  }`,
                                  borderRadius: `22px 22px ${
                                    chat.ownerProfileID === myProfileID
                                      ? '22px 22px'
                                      : '22px 22px'
                                  }`,
                                  // flexShrink: 1,
                                  // display:'flex',
                                  // minWidth: `${scaleByRatio(300)}px`,
                                  textAlign: `${
                                    chat.ownerProfileID !== myProfileID
                                      ? 'left'
                                      : 'left'
                                  }`,
                                  color: `${
                                    chat.ownerProfileID == myProfileID
                                      ? '#cccccc'
                                      : '#000'
                                  }`,
                                }}>
                                {chat.file && (
                                  <>
                                    <img
                                      src={chat?.file?.url}
                                      alt={''}
                                      width={scaleByRatio(100)}
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        showImagePreview(chat?.file?.url)
                                      }
                                    />
                                  </>
                                )}
                                {!chat.file && <>{chat.message}</>}
                              </Typography>
                              <Typography
                                textAlign={
                                  chat.ownerProfileID !== myProfileID
                                    ? 'left'
                                    : 'right'
                                }
                                className={styles.time}
                                style={{
                                  fontSize: `${scaleByRatio(12)}px`,
                                  color: '#fff',
                                  marginTop: '4px',
                                  textAlign: `${
                                    chat.ownerProfileID !== myProfileID
                                      ? 'left'
                                      : 'right'
                                  }`,
                                }}>
                                {chat.time}
                              </Typography>
                            </Typography>
                          </Grid>
                          {/* {chat.ownerProfileID === myProfileID && (
                        <Grid
                          item
                          xs={1}
                          justifyContent="left"
                          alignItems={'flex-end'}
                          style={{
                            display: `flex`,
                            height: `${
                              chat.file ? scaleByRatio(120) : scaleByRatio(70)
                            }px`,
                            padding: `${scaleByRatio(2)}px`,
                          }}>
                          <img
                            src={chat.profile_pic || Profile_Avatar}
                            alt={chat.name}
                            style={{
                              width: `${scaleByRatio(32)}px`,
                              height: `${scaleByRatio(32)}px`,
                              borderRadius: '4px',
                              border: `2px solid #E5E5E5`,
                            }}
                          />
                        </Grid>
                      )}
                      {chat.ownerProfileID !== myProfileID && (
                        <Grid
                          item
                          xs={1}
                          justifyContent="left"
                          alignItems={'flex-end'}></Grid>
                      )} */}
                        </Grid>
                      );
                    })}
                </Grid>
                {
                  // (!convo?.make_introw && convo?.end_introw) && (convo?.make_introw && !convo?.end_introw) || (!convo?.make_introw && convo?.end_introw) && (convo?.session_type=='PIC' && convo?.make_introw && !convo?.end_introw)
                  (convo?.session_type == 'PIC' &&
                    !endIntrow &&
                    !introw &&
                    !isBlocked) ||
                  (convo?.session_type == 'DIC' && !endIntrow && !isBlocked) ? (
                    // || !(endIntrow || isBlocked ) ||
                    //   (!convo?.end_introw && !endIntrowStatus)
                    // || (convo?.session_type=='PIC' && !convo?.make_introw) || (convo?.session_type=='PIC' && !convo?.end_introw)
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={
                        {
                          // bottom: '0',
                          // marginTop: '-20vh',
                          //  padding: `${scaleByRatio(5)}px`,
                          // paddingLeft: `${scaleByRatio(20)}px`,
                          // marginLeft: '-1vh',
                          // paddingBottom: '25px',
                        }
                      }
                      // className={styles.msgContainer}
                    >
                      <Grid
                        sx={{
                          width: isWideScreen ? 'calc(100vw - 309px)' : '100vw', // Adjust width based on screen width
                        }}
                        position={'fixed'}
                        backgroundColor="#000"
                        paddingBottom={'5px'}
                        zIndex="90"
                        bottom={'68px'}
                        container
                        xs={12}
                        direction="row"
                        className={styles.msgContainer}>
                        <Grid
                          item
                          xs={1}
                          justifyContent="right"
                          alignItems={'center'}
                          style={{
                            display: `flex`,
                            marginLeft: '10px',
                            paddingLeft: `${scaleByRatio(3)}px`,
                          }}>
                          <img
                            src={ChatAttachment}
                            alt={'send attachment'}
                            style={{
                              width: `${scaleByRatio(40)}px`,
                              height: `${scaleByRatio(40)}px`,
                              paddingRight: `${scaleByRatio(5)}px`,
                              cursor: `pointer`,
                            }}
                            onClick={() => {
                              inputImage.current.click();
                            }}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={inputImage}
                            onChange={handleInputImage}
                            onClick={(evt) => {
                              evt.target.value = null;
                            }}
                          />
                          {showImageCropper && (
                            <ImageCropper
                              ImageURL={fileUpload?.imageUrl}
                              callBackImageCropped={callBackImageCropped}
                              callBackImageNotCropped={callBackImageNotCropped}
                              handleCloseModalCallBack={
                                handleCloseModalImageCropper
                              }
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          alignItems={'center'}
                          style={{
                            display: `flex`,
                            paddingRight: `${scaleByRatio(5)}px`,
                          }}>
                          <Typography
                            textAlign={'center'}
                            className={styles.writeamessage}
                            style={{
                              fontSize: `${scaleByRatio(16)}px`,
                            }}>
                            Write a message
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          justifyContent="right"
                          alignItems={'center'}
                          style={{
                            display: `flex`,
                            padding: `${scaleByRatio(5)}px`,
                            paddingLeft: `${scaleByRatio(25)}px`,
                            paddingRight: `${scaleByRatio(10)}px`,
                          }}>
                          <FormControl variant="filled" fullWidth>
                            <InputBase
                              id={'CurrentMessage'}
                              value={currentMessage}
                              onChange={(evt) =>
                                handleCurrentMessageChange(evt)
                              }
                              type={'text'}
                              className={`${styles.inputField}`}
                              //style={{minHeight:`${minHeightPX}px`}}
                              multiline={false}
                              rows={1}
                              style={{
                                fontFamily: 'Gill Sans Nova',
                                borderRadius: '70px',
                                padding: `${scaleByRatio(3)}px`,
                                paddingLeft: `${scaleByRatio(20)}px`,
                                height: `${scaleByRatio(36)}px`,
                                fontSize: `${scaleByRatio(16)}px`,
                                // width: `${scaleByRatio(200)}px`,
                              }}
                              onKeyPress={(e) => {
                                if (e.key !== 'Enter') return;
                                sendMessage();
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          justifyContent="left"
                          alignItems={'center'}
                          style={{
                            display: `flex`,
                            // padding: `${scaleByRatio(3)}px`,
                            marginRight: `${scaleByRatio(-5)}px`,
                          }}>
                          <img
                            src={ChatSend}
                            alt={'send attachment'}
                            style={{
                              width: `${scaleByRatio(40)}px`,
                              height: `${scaleByRatio(40)}px`,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              sendMessage();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )
                }
              </Box>
            </>
          )}
          {showGallery && (
            <ImageGallery
              images={arrGalleryImages}
              showGallery={showGallery}
              onCloseCallBack={setShowGallery}
            />
          )}
        </>
      )}
    </>
  );
};

export default DirectChat;
