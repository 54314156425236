import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import styles from './index.module.scss';
import { scaleByRatio, getFormattedDate } from '../../utils/helper';
import NotificationListItem from '../NotificationListItem';
import Loader from '../../molecule/Loader';
import {
  usePatchNotification,
  useGetNotificationList,
} from '../../services/mutations';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import close_icon from '../../assets/close_icon.svg';


const NotificationList = ({ notifications, callbackOnClick }) => {
  const patchNotification = usePatchNotification();
  const getNotificationList = useGetNotificationList();
  const [listNotifications, setListNotifications] = useState(notifications);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const handleNotificationClick = (notification) => {
    console.log('handleNotificationClick:', notification);
    updateNotificationStatus(notification);
  };

  const fetchNotificationList = () => {
    getNotificationList.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('fetchNotificationList::', response);

          setListNotifications(response?.data?.payload?.results);
        },
        onError: (error) => {
          console.log(error);
        },
      },
    );
  };

  const updateNotificationStatus = (notification) => {
    let data = {
      notification_id: notification?.id,
      read: true,
    };
    setLoading(true);
    patchNotification.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        callbackOnClick();
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };

  useState(() => {
    console.log('Notification List: ', notifications);
    setListNotifications(notifications);
  }, [notifications]);

  useState(() => {
    fetchNotificationList();
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Loader open={loading} />

       <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 1,
        pt: scaleByRatio(3),
        marginTop: `${isWideScreen ? scaleByRatio(120) : scaleByRatio(100)}px`, // Adjust marginTop based on screen width
      }}
      style={{ padding: '15px' }} // Inline style for padding
    >
      <Grid
      display={'flex'}
      direction={'row'}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{ 
          // minHeight: '50px', 
          paddingBottom: '10px' }}>
          <Grid item xs={12} justifyContent="center">
            <Typography
              textAlign="center"
              className={styles.shareApp}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
                paddingLeft: '50px',
              }}>
              Notifications
            </Typography>
            <Grid item 
        marginTop={'1px'}
        xs={12}>
          <hr
            style={{
              marginLeft: '5px',
              // marginRight: '10px',
              border: 'none',
              height: '0.5px',
              marginTop: '20px',
              // marginBottom: '5px',
              width: '108%',
              backgroundColor: '#cccccc',
            }}
          />
        </Grid>
          </Grid>
        </Grid>
        <Grid
            item
            sx={{
              // position: 'absolute', // Position close icon absolutely
              marginRight: '10px', // Align it to the extreme right with some margin
              top: 0,
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
            onClick={handleClose}
            className={styles.closeIcon}>
            <Typography>
              <img src={close_icon} alt="Close" />
            </Typography>
          </Grid>

          </Grid>
        
        {!loading &&
          listNotifications.length > 0 &&
          listNotifications.map((notification) => {
            return (
              <NotificationListItem
                notificationItem={notification}
                onClickCallBack={handleNotificationClick}
              />
            );
          })}
      </Box>
    </>
  );
};

export default NotificationList;
