import Header from '../../organism/Header'
import Footer from '../../organism/Footer'
import { Grid } from '@mui/material'
import styles from './index.module.scss'

const CentralContentPageLayout = (props) => {
    return ( 
        <div>
            <Header />
            <Grid container 
            direction="row"
            justifyContent="center"
            alignItems="unset"
            className={`${styles.main} joinPageWrapper`}
            >
                {props.children}
            </Grid>
            <Footer />
        </div>
     );
}
 
export default CentralContentPageLayout;