import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import InputField from '../../atoms/InputField';
import IconClose from '../../assets/IconClose.svg';

const EndIntrowDialog = ({
  isOpen = false,
  messageArr = [],
  customMessageID,
  heading = '',
  onYesClick,
  onNoClick,
  listItemClass = '',
  convo,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [selectedValue, setSelectedValue] = useState(null);
  const [customTextMessage, setCustomTextMessage] = useState('');

  const handleCancel = () => {
    setOpen(false);
    onNoClick(selectedValue);
  };
  const handleAgree = () => {
    setOpen(false);
    onYesClick(selectedValue, customTextMessage);
  };
  const onChange = (id) => {
    if (id !== customMessageID) {
      setCustomTextMessage('');
    }
    setSelectedValue(id);
  };
  const handleCustomMessageChange = (evt) => {
    setCustomTextMessage(evt.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <button
        onClick={handleCancel}
        className="close-icon-button"
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          background: 'none',
          border: 'none',
          padding: '0',
          cursor: 'pointer',
          zIndex: 1,
          width: '16px',
          height: '16px',
          marginBottom:'5px',
        }}>
        <img src={IconClose} alt="img np" />
      </button>
      <DialogTitle
        id="alert-dialog-title"
        className={styles.heading}
        style={{
          fontSize: `${scaleByRatio(22)}px`,
          marginTop: `${scaleByRatio(5)}px`,
          lineHeight: `${scaleByRatio(38)}px`,
        }}>
        End this{' '}
        <span
          style={{
            color: '#ECA43C',
          }}>
          {convo?.session_type == 'PIC' ? 'Reach out' : 'Introw'}
        </span>
        
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{ background: '#FFFFFF' }}>
          <Grid
            item
            xs={12}
            justifyContent="left"
            style={{
              display: `flex`,
            }}>
            <Typography
              fullwidth
              textAlign={'left'}
              className={styles.subHeading}
              style={{
                fontSize: `${scaleByRatio(10)}px`,
                margin: `${scaleByRatio(5)}px`,

              }}>
              {` You can select and send a message from the list below, while ${
                convo?.session_type == 'PIC'
                  ? 'ending this reach out.'
                  : 'ending this introw.'
              }`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{ background: '#FFFFFF' }}>
          {messageArr &&
            messageArr.length > 0 &&
            messageArr.map((message) => {
              if (convo?.session_type == 'DIC' && message.id != 3) {
                return (
                  <ListItem
                    key={message.id}
                    sx={{
                      '&.MuiListItem-root': {
                        display: `flex`,
                        alignItems: 'flex-start',
                      },
                    }}>
                    <Radio
                      edge="start"
                      onChange={() => onChange(message.id)}
                      checked={selectedValue === message.id}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: `${scaleByRatio(20)}px`,
                        },
                        color: '#05232E',
                        '&.MuiRadio-root': {
                          paddingTop: `${scaleByRatio(6)}px`,
                        },
                        '&.Mui-checked': {
                          color: '#05232E',
                        },
                      }}
                      name="RadioButtons"
                    />

                    <ListItemText
                      id={message.id}
                      primary={message.text}
                      sx={{
                        '.MuiListItemText-primary': {
                          fontSize: `${scaleByRatio(16)}px`,
                          lineHeight: `${scaleByRatio(20)}px`,
                          fontWeight: `400`,
                          fontFamily: 'Gill Sans Nova',
                        },
                      }}
                    />
                  </ListItem>
                );
              } else if (
                (convo?.session_type == 'PIC' && message.id == 3) ||
                message.id == 99
              ) {
                return (
                  <ListItem
                    key={message.id}
                    sx={{
                      '&.MuiListItem-root': {
                        display: `flex`,
                        alignItems: 'center',
                      },
                    }}>
                    <Radio
                      edge="start"
                      onChange={() => onChange(message.id)}
                      checked={selectedValue === message.id}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: `${scaleByRatio(20)}px`,
                        },
                        '&.MuiRadio-root': {
                          paddingTop: `${scaleByRatio(6)}px`,
                          color: '#D4D4D4',
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          color: '#E66433',
                        },
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                            stroke: '#D4D4D4',
                            strokeWidth: 5,
                          },
                      }}
                      name="RadioButtons"
                    />

                    <ListItemText
                      id={message.id}
                      primary={message.text}
                      sx={{
                        '.MuiListItemText-primary': {
                          paddingLeft:'10px',
                          fontSize: `${scaleByRatio(10)}px`,
                          lineHeight: `${scaleByRatio(12)}px`,
                          fontWeight: `400`,
                          fontFamily: 'Gill Sans Nova',
                          color: '#BDBFC1',
                          textTransform: 'uppercase',
                          letterSpacing: '0.1em'
                        },
                      }}
                    />
                  </ListItem>
                );
              }
            })}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent={'left'}
          alignItems="flex-end"
          sx={{ background: '#FFFFFF', 
          paddingLeft: `${scaleByRatio(48)}px` ,
          paddingRight: '10px',
          marginBottom:'25px',
          //paddingTop: `${scaleByRatio(48)}px` ,
          }}>
          <InputField
            name="customMessage"
            id={'customMessage'}
            value={customTextMessage}
            placeholder={'MESSAGE'}
            handleChange={(evt) => handleCustomMessageChange(evt)}
            type={'text'}
            class_name={styles.customMessage}
            multiline={true}
            rows={5}
            disabled={!selectedValue || selectedValue !== customMessageID}
            sx={{
              width: 'calc(100% - 48px)', // Adjust width according to padding
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          marginBottom:'5px',
          justifyContent: 'center',
          display: 'flex',
          gap: '10px',
          padding: '10px',
        }}>
        <Button
          onClick={handleCancel}
          autoFocus
          className={`${styles.button} ${styles.secondary}`}
          style={{
            fontSize: `${scaleByRatio(14)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
            flex: '0 0 auto', // Prevent stretching
            minWidth: '120px', // Set a minimum width
            paddingLeft:'15px',
            paddingRight: '15px',
            paddingLeft:'42px',
            paddingRight: '42px'
          }}>
          Cancel
        </Button>
        <Button
          onClick={handleAgree}
          className={`${styles.button} ${styles.primary}`}
          style={{
            fontSize: `${scaleByRatio(14)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
            flex: '0 0 auto', // Prevent stretching
            minWidth: '120px', // Set a minimum width
            paddingLeft:'15px',
            paddingRight: '15px'
          }}>
          Yes, End This
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EndIntrowDialog;
