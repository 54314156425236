// import React, { useState, useEffect } from 'react';
// import styles from './index.module.scss';
// import Loader from '../../molecule/Loader';
// import Box from '@mui/material/Box';
// import { Grid } from '@mui/material';
// import Typography from '@mui/material/Typography';
// import {
//   localStorageKeys,
//   localStorageUserType,
//   useLocalStorage,
// } from '../../utils/localStorageItems';
// import { getTime, scaleByRatio } from '../../utils/helper';
// import CustomAlert from '../../atoms/CustomAlert';
// import {
//   useGetConvosList,
//   useGetElbowList,
//   usePatchAcceptDeclineConvo,
//   usePatchDeclineIntrow,
//   usePatchElbowStatus,
//   usePostMakeIntrow,
// } from '../../services/mutations';
// import ElbowsListItem from '../../molecule/ElbowsListItem';
// import ElbowProspectiveDaterProfile from '../ElbowProspectiveDaterProfile';
// import { usePubNub } from 'pubnub-react';
// import ConvosListItem from '../../molecule/ConvosListItem';
// import { default as ElbowsLogo } from '../../assets/Elbows.svg';

// const Elbows = () => {
//   const getElbowList = useGetElbowList();
//   const patchElbowStatus = usePatchElbowStatus();
//   const [elbowList, setElbowList] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [alertMsg, setAlertMsg] = useState('');
//   // const [showProspectiveDaterProfile, setShowProspectiveDaterProfile] = useState(false)
//   const [selectedProspeciveDaterProfile, setSelectedProspeciveDaterProfile] =
//     useState(null);
//   const [prospectiveDaterElbow, setProspectiveDaterElbow] = useState(null);

//   const localStorage = useLocalStorage();
//   const pubnub = usePubNub();
//   const getConvosList = useGetConvosList();
//   const postMakeIntrow = usePostMakeIntrow();
//   const patchDeclineIntrow = usePatchDeclineIntrow();
//   const patchAcceptDeclineConvo = usePatchAcceptDeclineConvo();

//   const [convoList, setConvoList] = useState([]);
//   //   const [loading, setLoading] = useState([]);
//   const [prospectiveConvo, setProspectiveConvo] = useState(null);
//   //   const [alertMsg, setAlertMsg] = useState('');
//   const [channels, setChannels] = useState([]);
//   const [historicalMessages, setHistoricalMessages] = useState([]);

//   const [directChatChannel, setDirectChatChannel] = useState(null);
//   const [showDirectMessage, setShowDirectMessage] = useState(false);
//   const [directChatMyProfile, setDirectChatMyProfile] = useState(null);
//   const [directChatOtherProfile, setDirectChatOtherProfile] = useState(null);

//   const fetchConvosList = () => {
//     setLoading(true);
//     getConvosList.mutate(
//       {},
//       {
//         onSuccess: (response) => {
//           setLoading(false);
//           console.log('fetchConvosList onSuccess: ', response);
//           setConvoList(response?.data?.payload?.results);
//         },
//         onError: (error) => {
//           console.log('fetchConvosList onError: ', error);
//           setLoading(false);
//         },
//       },
//     );
//   };
//   //   const handleProfileClicked = (convo) => {
//   //     setProspectiveConvo(convo);
//   //   };
//   //   const onProspectiveDaterProfileBackClicked = () => {
//   //     setProspectiveConvo(null);
//   //     populateConvosList();
//   //   };

//   //   const handleButtonClickedCallback = (status, convo_id) => {
//   //     console.log('handleButtonClickedCallback:: ', status, convo_id);
//   //     if (status === 'A') {
//   //       makeIntrow(convo_id);
//   //     } else {
//   //       declineIntrow(convo_id);
//   //     }
//   //   };

//   const makeIntrow = (convo_id) => {
//     setLoading(true);
//     let data = {
//       convo_id: convo_id,
//     };
//     postMakeIntrow.mutate(data, {
//       onSuccess: (response) => {
//         setLoading(false);
//         setAlertMsg('Introw Created');
//       },
//       onError: (error) => {
//         setLoading(false);
//         onProspectiveDaterProfileBackClicked();
//       },
//     });
//   };

//   const declineIntrow = (convo_id) => {
//     setLoading(true);
//     let data = {
//       convo_id: convo_id,
//     };

//     patchDeclineIntrow.mutate(data, {
//       onSuccess: (response) => {
//         setLoading(false);
//         setAlertMsg('Introw Declined');
//       },
//       onError: (error) => {
//         setLoading(false);
//         onProspectiveDaterProfileBackClicked();
//       },
//     });
//   };

//   //   const onAlertClose = () => {
//   //     setAlertMsg('');
//   //     onProspectiveDaterProfileBackClicked();
//   //     populateConvosList();
//   //   };

//   const populateConvosList = () => {
//     console.log('useEffect Friend');
//     fetchConvosList();
//   };

//   const handleAcceptRejectCallback = (status, session_member_id) => {
//     console.log('handleAcceptRejectCallback :', status, session_member_id);
//     updateConvoStatus(status, session_member_id);
//   };

//   const updateConvoStatus = (status, session_member_id) => {
//     setLoading(true);
//     let data = {
//       status: status,
//       session_member_id: session_member_id,
//     };
//     patchAcceptDeclineConvo.mutate(data, {
//       onSuccess: (response) => {
//         setLoading(false);
//         populateConvosList();
//       },
//       onError: (error) => {
//         setLoading(false);
//       },
//     });
//   };

//   const onDirectChatBackClicked = () => {
//     setShowDirectMessage(false);
//     populateConvosList();
//   };
//   const prepareHistoricalMessage = (channelMessageArray) => {
//     console.log('prepareHistoricalMessage: ', channelMessageArray);
//     let key;
//     let messageArr = [];
//     if (channelMessageArray) {
//       for (key in channelMessageArray) {
//         console.log('Key: ', channelMessageArray[key][0]);
//         messageArr.push(channelMessageArray[key][0]);
//       }
//     }
//     console.log('messageArr:', messageArr);
//     let arr = [];
//     if (messageArr && messageArr.length > 0) {
//       messageArr?.forEach((messageElem) => {
//         if (messageElem?.message?.message?.type !== 'image') {
//           let message = {
//             channel: messageElem?.channel,
//             ownerProfileID: messageElem?.uuid,
//             profile_pic: messageElem?.message?.user?.profilePic,
//             message: messageElem?.message?.text,
//             time: getTime(parseInt(messageElem.timetoken), 'DD/MM/YY HH:mm'),
//             file: null,
//           };
//           arr.push(message);
//         } else {
//           console.log('File message: ', messageElem);
//           let channel = messageElem?.channel;
//           let file_id = messageElem?.message?.file?.id;
//           let file_name = messageElem?.message?.file?.name;
//           const file_url = pubnub.getFileUrl({
//             channel: channel,
//             id: file_id,
//             name: file_name,
//           });
//           console.log('file url: ', file_url);
//           let fileMessage = {
//             channel: channel,
//             profile_pic: messageElem?.message?.message?.user?.profilePic,
//             message: '',
//             time: getTime(parseInt(messageElem.timetoken), 'DD/MM/YY HH:mm'),
//             ownerProfileID: messageElem?.uuid,
//             file: {
//               id: file_id,
//               name: file_name,
//               url: file_url,
//             },
//           };
//           arr.push(fileMessage);
//         }
//       });
//       console.log('prepareHistoricalMessage final:', arr);
//       setHistoricalMessages(arr);
//     }
//   };

//   useEffect(() => {
//     if (convoList && convoList.length > 0) {
//       //subscribe to pubnub to get historical message
//       // create channels
//       let arrChannels = [];
//       convoList.forEach((convo) => {
//         arrChannels.push(convo.id);
//       });
//       setChannels(arrChannels);
//     }
//   }, [convoList]);
//   // useEffect(() => {
//   //   console.log('useeffect subscription:', channels);
//   //   if (channels.length > 0) {
//   //     // const listenerParams = { message: handleDirectMessage }
//   //     // const fileListnerParams = { file: handleFiles}
//   //     // pubnub.addListener(listenerParams);
//   //     // pubnub.addListener(fileListnerParams);
//   //     pubnub.subscribe({ channels });

//   //     let currentToken = (
//   //       Math.floor(new Date('2023.10.01').getTime() / 1000) * 10000000
//   //     ).toString();
//   //     setLoading(true);
//   //     pubnub.fetchMessages(
//   //       {
//   //         channels: channels,
//   //         end: currentToken, //'16970822938689523',
//   //         count: 1,
//   //       },
//   //       function (status, response) {
//   //         console.log('>>>>>>>>>>>> Historical message start');
//   //         console.log(status, response);
//   //         if (status?.statusCode === 200) {
//   //           prepareHistoricalMessage(response.channels || null);
//   //         }

//   //         console.log('>>>>>>>>>>>> Historical message end');
//   //         setLoading(false);
//   //       },
//   //     );
//   //   }
//   //   return () => {
//   //     pubnub.unsubscribe({ channels });
//   //     // pubnub.removeListener({ message: handleDirectMessage });
//   //     // pubnub.removeListener({ file: handleFiles });
//   //   };
//   // }, [pubnub, channels]);

//   useEffect(() => {
//     populateConvosList();
//   }, []);

//   const handleButtonClickedCallback = (status, elbow_id) => {
//     console.log('handleYesClickedCallback :', status, elbow_id);
//     updateElbowStatus(status, elbow_id);
//   };

//   const fetchMyElbowList = () => {
//     setLoading(true);
//     getElbowList.mutate(
//       {},
//       {
//         onSuccess: (response) => {
//           console.log('fetchMyElbowList onSuccess:', response);
//           setElbowList(response?.data?.payload?.results || []);
//           setLoading(false);
//         },
//         onError: (error) => {
//           console.log('fetchMyElbowList onError:', error);
//           setLoading(false);
//         },
//       },
//     );
//   };

//   const updateElbowStatus = (status, elbow_id) => {
//     let data = {
//       request_status: status,
//       elbow_id: elbow_id,
//     };
//     setLoading(true);
//     patchElbowStatus.mutate(data, {
//       onSuccess: (response) => {
//         setLoading(false);
//         let msg = '';
//         if (status === 'A') {
//           msg = 'Reach out accepted !';
//           fetchMyElbowList();
//         } else if (status === 'D') {
//           msg = 'Reach out Declied !';
//           fetchMyElbowList();
//         } else if (status === 'E') {
//           // setAlertMsg('Elbow Removed !');
//           msg = 'Reach out Removed !';
//           fetchMyElbowList();
//         }
//         setAlertMsg(msg);
//       },
//       onError: (error) => {
//         setLoading(false);
//       },
//     });
//   };

//   const onAlertClose = () => {
//     fetchMyElbowList();
//   };

//   const handleProfileClicked = (elbow_id) => {
//     //find elbow from elbowlist
//     if (elbowList.length > 0) {
//       let elbowIndex = elbowList.findIndex((elbow) => elbow.id === elbow_id);
//       if (elbowIndex > -1) {
//         setProspectiveDaterElbow(elbowList[elbowIndex]);
//       }
//     }
//   };

//   const onProspectiveDaterProfileBackClicked = () => {
//     setProspectiveDaterElbow(null);
//   };

//   useEffect(() => {
//     fetchMyElbowList();
//   }, []);
//   return (
//     <>
//       <Loader open={loading} />
//       {alertMsg.length > 0 && (
//         <CustomAlert
//           isOpen={alertMsg.length > 0 ? true : false}
//           message={alertMsg}
//           onClose={onAlertClose}
//         />
//       )}
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           // pb: 3,
//           pt: scaleByRatio(3),
//           marginTop: `${scaleByRatio(80)}px`,
//           // background: '#FFFFFF',
//           background: '#000',
//           paddingBottom: '45px',
//         }}>
//         {!loading &&
//           !prospectiveDaterElbow &&
//           localStorage.getLocalStorageItem(localStorageKeys.userType) ===
//             localStorageUserType.dater && ( //elbowList.length > 0 &&
//             <>
//               <Grid
//                 container
//                 direction="row"
//                 alignItems="flex-end"
//                 sx={{
//                   // background: '#FFFFFF',
//                   background: '#000',
//                   minHeight: `${scaleByRatio(50)}px`,
//                   paddingBottom: `${scaleByRatio(10)}px`,
//                 }}>
//                 <Grid item xs={12} justifyContent="center">
//                   <Typography textAlign="center" className={styles.heading}>
//                     {/* {'Elbows'} */}
//                     {'Reach Outs'}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid
//                 container
//                 direction="row"
//                 alignItems="flex-end"
//                 sx={{
//                   // background: '#FFFFFF',
//                   background: '#000',
//                   minHeight: `${scaleByRatio(24)}px`,
//                   paddingBottom: `${scaleByRatio(10)}px`,
//                 }}>
//                 <Grid item xs={12} justifyContent="left">
//                   <Typography
//                     textAlign="left"
//                     className={styles.subHeading}
//                     style={{
//                       lineHeight: `${scaleByRatio(24)}px`,
//                       paddingLeft: `${scaleByRatio(10)}px`,
//                     }}>
//                     {
//                       'Give permission or decline permission for a friend to reach out to someone for you.'
//                     }
//                   </Typography>
//                 </Grid>
//               </Grid>
//               {elbowList.map((elbow) => {
//                 return (
//                   <ElbowsListItem
//                     datingProfileDetails={
//                       elbow.request_from || elbow.request_to
//                     }
//                     friendDetails={
//                       elbow.request_from_friend || elbow.request_to_friend
//                     }
//                     message={elbow.message}
//                     interest_type={elbow.interest_type}
//                     id={elbow.id}
//                     request_type={elbow.request_type}
//                     request_status={elbow.request_status}
//                     request_text={elbow.request_text}
//                     isFromFriend={elbow.request_from_friend}
//                     handleButtonClickedCallback={handleButtonClickedCallback}
//                     handleProfileClicked={handleProfileClicked}
//                   />
//                 );
//               })}
//             </>
//           )}
//         {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
//           localStorageUserType.friend && (
//           <Grid
//             container
//             direction="row"
//             alignItems="flex-end"
//             marginTop={2}
//             sx={{
//               // background: '#FFFFFF',
//               background: '#000',
//               minHeight: `${scaleByRatio(24)}px`,
//               paddingBottom: `${scaleByRatio(10)}px`,
//             }}>
//             <Grid
//               container
//               direction="row"
//               alignItems="flex-end"
//               sx={{
//                 // background: '#FFFFFF',
//                 background: '#000',
//                 minHeight: `${scaleByRatio(50)}px`,
//                 paddingBottom: `${scaleByRatio(10)}px`,
//               }}>
//               <Grid item xs={12} justifyContent="center">
//                 <Typography textAlign="center" className={styles.heading}>
//                   {/* {'Elbows'} */}
//                   {'Reach Outs'}
//                 </Typography>
//               </Grid>
//             </Grid>
//             <Grid item xs={12} justifyContent="left">
//               <Typography
//                 textAlign="left"
//                 className={styles.subHeading}
//                 style={{
//                   lineHeight: `${scaleByRatio(24)}px`,
//                   paddingLeft: `${scaleByRatio(10)}px`,
//                 }}>
//                 {'All pending and Decline Elbows for Friend'}
//               </Typography>
//             </Grid>
//           </Grid>
//         )}
//         {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
//           localStorageUserType.friend &&
//           convoList.map((convo) => {
//             if (
//               convo?.elbow?.request_status === 'P' ||
//               convo?.elbow?.request_status === 'D'
//             ) {
//               return (
//                 <ConvosListItem
//                   convo={convo}
//                   handleProfileClicked={handleProfileClicked}
//                   // setDirectChatChannel={setDirectChatChannel}
//                   // setShowDirectMessage={setShowDirectMessage}
//                   // setDirectChatMyProfile={setDirectChatMyProfile}
//                   setDirectChatOtherProfile={setDirectChatOtherProfile}
//                   lastMessage={
//                     historicalMessages.length > 0
//                       ? historicalMessages.find(
//                           (history) => history.channel === convo?.id,
//                         ) || null
//                       : null
//                   }
//                 />
//               );
//             }
//           })}
//         {prospectiveDaterElbow && (
//           <ElbowProspectiveDaterProfile
//             ProfileID={
//               prospectiveDaterElbow?.request_from
//                 ? prospectiveDaterElbow?.request_from?.id
//                 : prospectiveDaterElbow?.request_to?.id
//             }
//             ProfileName={
//               prospectiveDaterElbow?.request_from
//                 ? prospectiveDaterElbow?.request_from?.name
//                 : prospectiveDaterElbow?.request_to?.name
//             }
//             onBackClicked={onProspectiveDaterProfileBackClicked}
//             request_status={prospectiveDaterElbow.request_status}
//             handleButtonClickedCallback={handleButtonClickedCallback}
//             prospectiveDaterElbow={prospectiveDaterElbow}
//           />
//         )}
//       </Box>
//       {/* <Grid key={ElbowsLogo} disablePadding mt={32}>
//         <Typography textAlign="center" className={styles.menuItem}>
//           <img src={ElbowsLogo} alt="Explore" className={styles.TopMenuICon} />{' '}
//         </Typography>
//       </Grid> */}
//     </>
//   );
// };

// export default Elbows;

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import styles from './index.module.scss';
import {
  useGetConvosList,
  usePostMakeIntrow,
  usePatchDeclineIntrow,
  usePatchAcceptDeclineConvo,
  useGetActiveDater,
} from '../../services/mutations';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import Loader from '../../molecule/Loader';
import ConvosListItem from '../../molecule/ConvosListItem';
import ConvosProspectiveDaterProfile from '../ConvosProspectiveDaterProfile';
import CustomAlert from '../../atoms/CustomAlert';
import { scaleByRatio, getTime } from '../../utils/helper';
import ConvosListItemDater from '../../molecule/ConvosListItemDater';
import DirectChat from '../../molecule/DirectChat';
import { usePubNub } from 'pubnub-react';
import AppLogo from '../../assets/AppLogo.svg';

const Elbows = ({ daterFriendId, setDaterFriendId }) => {
  const localStorage = useLocalStorage();
  const myProfileID = localStorage.getLocalStorageItem(
    localStorageKeys.profileId,
  );
  console.log('daterFriendId##########', daterFriendId);
  const pubnub = usePubNub();
  const getConvosList = useGetConvosList();
  const postMakeIntrow = usePostMakeIntrow();
  const patchDeclineIntrow = usePatchDeclineIntrow();
  const patchAcceptDeclineConvo = usePatchAcceptDeclineConvo();
  const getActiveDater = useGetActiveDater();
  const [convoList, setConvoList] = useState([]);
  const [loading, setLoading] = useState([]);
  const [prospectiveConvo, setProspectiveConvo] = useState(null);
  const [alertMsg, setAlertMsg] = useState('');
  const [channels, setChannels] = useState([]);
  const [historicalMessages, setHistoricalMessages] = useState([]);
  const [directChatChannel, setDirectChatChannel] = useState(null);
  const [showDirectMessage, setShowDirectMessage] = useState(false);
  const [directChatMyProfile, setDirectChatMyProfile] = useState(null);
  const [directChatOtherProfile, setDirectChatOtherProfile] = useState(null);
  const [directChatConvo, setDirectChatConvo] = useState(null);
  const [activeDaterFriend, setActiveDaterFriend] = useState(null);
  const [activeDater, setActiveDater] = useState(null);
  const [reachOutDater, setReachOutDater] = useState(null);
  const handleuserType = () => {
    if (localStorage.getLocalStorageItem('userType') === 'dater') {
      return `Friends of daters you've reached out to`;
    } else if (localStorage.getLocalStorageItem('userType') === 'friend') {
      return 'Daters interested in your friend';
    }
  };

  const fetchActiveDater = () => {
    let data = {};
    setLoading(true);
    getActiveDater.mutate(data, {
      onSuccess: (response) => {
        console.log('getActiveDater success: ', response);
        setActiveDaterFriend(response?.data?.payload?.friend);
        setActiveDater(response?.data?.payload?.user);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        console.log('getActiveDater error: ', error);
      },
    });
  };

  const fetchConvosList = () => {
    setLoading(true);
    let data = {};
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      data = {
        friend_id: activeDaterFriend.id,
      };
    }
    getConvosList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('fetchConvosList onSuccess: ', response);
        setConvoList(response?.data?.payload?.results);
      },
      onError: (error) => {
        console.log('fetchConvosList onError: ', error);
        setLoading(false);
      },
    });
  };
  const handleProfileClicked = (convo) => {
    setProspectiveConvo(reachOutDater || convo);
  };
  const onProspectiveDaterProfileBackClicked = () => {
    setProspectiveConvo(null);
    populateConvosList();
  };

  const handleButtonClickedCallback = (status, convo_id) => {
    console.log('handleButtonClickedCallback:: ', status, convo_id);
    if (status === 'A') {
      makeIntrow(convo_id);
    } else {
      declineIntrow(convo_id);
    }
  };

  const makeIntrow = (convo_id) => {
    setLoading(true);
    let data = {
      convo_id: convo_id,
    };
    postMakeIntrow.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setAlertMsg('Introw Created');
      },
      onError: (error) => {
        setLoading(false);
        onProspectiveDaterProfileBackClicked();
      },
    });
  };

  const declineIntrow = (convo_id) => {
    setLoading(true);
    let data = {
      convo_id: convo_id,
    };

    patchDeclineIntrow.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setAlertMsg('Introw Declined');
      },
      onError: (error) => {
        setLoading(false);
        onProspectiveDaterProfileBackClicked();
      },
    });
  };

  const onAlertClose = () => {
    setAlertMsg('');
    onProspectiveDaterProfileBackClicked();
    populateConvosList();
  };

  const populateConvosList = () => {
    console.log('useEffect Friend');
    fetchConvosList();
  };

  const handleAcceptRejectCallback = (status, session_member_id) => {
    console.log('handleAcceptRejectCallback :', status, session_member_id);
    updateConvoStatus(status, session_member_id);
  };

  const updateConvoStatus = (status, session_member_id) => {
    setLoading(true);
    let data = {
      status: status,
      session_member_id: session_member_id,
    };
    patchAcceptDeclineConvo.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        populateConvosList();
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };

  const onDirectChatBackClicked = () => {
    setShowDirectMessage(false);
    populateConvosList();
  };
  const prepareHistoricalMessage = (channelMessageArray) => {
    console.log('prepareHistoricalMessage: ', channelMessageArray);
    let key;
    let messageArr = [];
    if (channelMessageArray) {
      for (key in channelMessageArray) {
        console.log('Key: ', channelMessageArray[key][0]);
        messageArr.push(channelMessageArray[key][0]);
      }
    }
    console.log('messageArr:', messageArr);
    let arr = [];
    if (messageArr && messageArr.length > 0) {
      messageArr?.forEach((messageElem) => {
        if (messageElem?.message?.message?.type !== 'image') {
          let message = {
            channel: messageElem?.channel,
            ownerProfileID: messageElem?.uuid,
            profile_pic: messageElem?.message?.user?.profilePic,
            message: messageElem?.message?.text,
            time: getTime(parseInt(messageElem.timetoken), 'DD/MM/YY HH:mm'),
            file: null,
          };
          arr.push(message);
        } else {
          console.log('File message: ', messageElem);
          let channel = messageElem?.channel;
          let file_id = messageElem?.message?.file?.id;
          let file_name = messageElem?.message?.file?.name;
          const file_url = pubnub.getFileUrl({
            channel: channel,
            id: file_id,
            name: file_name,
          });
          console.log('file url: ', file_url);
          let fileMessage = {
            channel: channel,
            profile_pic: messageElem?.message?.message?.user?.profilePic,
            message: '',
            time: getTime(parseInt(messageElem.timetoken), 'DD/MM/YY HH:mm'),
            ownerProfileID: messageElem?.uuid,
            file: {
              id: file_id,
              name: file_name,
              url: file_url,
            },
          };
          arr.push(fileMessage);
        }
      });
      console.log('prepareHistoricalMessage final:', arr);
      setHistoricalMessages(arr);
    }
  };

  useEffect(() => {
    if (convoList && convoList.length > 0) {
      //subscribe to pubnub to get historical message
      // create channels
      let arrChannels = [];
      convoList.forEach((convo) => {
        arrChannels.push(convo.id);
      });
      setChannels(arrChannels);
    }
  }, [convoList]);
  useEffect(() => {
    console.log('useeffect subscription:', channels);
    if (channels.length > 0) {
      // const listenerParams = { message: handleDirectMessage }
      // const fileListnerParams = { file: handleFiles}
      // pubnub.addListener(listenerParams);
      // pubnub.addListener(fileListnerParams);
      pubnub.subscribe({ channels });

      let currentToken = (
        Math.floor(new Date('2023.10.01').getTime() / 1000) * 10000000
      ).toString();
      setLoading(true);
      pubnub.fetchMessages(
        {
          channels: channels,
          end: currentToken, //'16970822938689523',
          count: 1,
        },
        function (status, response) {
          console.log('>>>>>>>>>>>> Historical message start');
          console.log(status, response);
          if (status?.statusCode === 200) {
            prepareHistoricalMessage(response.channels || null);
          }

          console.log('>>>>>>>>>>>> Historical message end');
          setLoading(false);
        },
      );
    }
    return () => {
      pubnub.unsubscribe({ channels });
      // pubnub.removeListener({ message: handleDirectMessage })
      // pubnub.removeListener({ file: handleFiles})
    };
  }, [pubnub, channels]);
  useEffect(() => {
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend &&
      activeDaterFriend
    ) {
      populateConvosList();
    }
  }, [activeDaterFriend]);
  useEffect(() => {
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      fetchActiveDater();
    } else {
      populateConvosList();
    }
  }, []);

  useEffect(() => {
    console.log('daterFriendId: ', daterFriendId);
    console.log('convoList: ', convoList);
    const convo = daterFriendId
      ? convoList?.find((convo) => {
        return convo?.elbow?.request_to_friend?.id === daterFriendId;
      })
      : null;
    convo?.convoSessionMembers?.map((item) => {
      if (item.user.id !== myProfileID) {
        setReachOutDater(convo);
        setDirectChatChannel(convo.id);
        setShowDirectMessage(true);
        setDirectChatOtherProfile(item);
        setDirectChatConvo(convo);
      } else {
        setDirectChatMyProfile(item);
      }
      setDaterFriendId(null);
    });
  }, [convoList, daterFriendId]);
  console.log(
    'reachoutDater/daterChatConvo: ',
    reachOutDater,
    directChatChannel,
    showDirectMessage,
    directChatConvo,
    myProfileID,
    directChatMyProfile,
    directChatOtherProfile,
    activeDater,
  );

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
     <Box
      component="main"
      sx={{
        flexGrow: 1,
        marginTop: `${isWideScreen ? scaleByRatio(135) : scaleByRatio(105)}px`, // Adjust marginTop based on screen width
        paddingBottom: '70px',
      }}
    >
        {!loading && !prospectiveConvo && !showDirectMessage && (
          // convoList.length > 0 &&
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              sx={{ minHeight: '50px', paddingBottom: '10px', py: '12px', }}
            >
              <Grid item xs={12} display={'flex'} justifyContent="center">
                <Typography textAlign="center" className={styles.header}>
                  {/* Convos */}
                  {handleuserType()}
                </Typography>
                {/* {localStorage.getLocalStorageItem('userType') === 'dater' && (
                  <Typography paddingTop={'5px'}>
                    <img src={AppLogo} />
                  </Typography>
                )} */}
              </Grid>
              <Grid item xs={12} justifyContent="left">
                <Typography
                  textAlign="left"
                  className={styles.subheader}
                  style={{
                    lineHeight: `${scaleByRatio(24)}px`,
                    paddingLeft: `${scaleByRatio(15)}px`,
                  }}>
                  {/* This is where you can find all of you conversations. */}
                </Typography>
              </Grid>
            </Grid>
            <hr style={{ marginLeft: '20px', marginRight: '20px' , border: 'none', height: '0.5px' , marginBottom:'5px', backgroundColor: '#cccccc'}} />
            {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.friend &&
              convoList.map((convo) => {
                if (
                  convo?.elbow?.request_status === 'A' ||
                  convo?.elbow?.request_status === 'E' ||
                  daterFriendId
                ) {
                  return (
                    <>
                      <ConvosListItem
                        convo={convo}
                        handleProfileClicked={handleProfileClicked}
                        setDirectChatChannel={setDirectChatChannel}
                        activeDaterFriend={activeDater}
                        setShowDirectMessage={setShowDirectMessage}
                        setDirectChatMyProfile={setDirectChatMyProfile}
                        setDirectChatOtherProfile={setDirectChatOtherProfile}
                        setDirectChatConvo={setDirectChatConvo}
                        lastMessage={
                          historicalMessages.length > 0
                            ? historicalMessages.find(
                              (history) => history.channel === convo?.id,
                            ) || null
                            : null
                        }
                      />
<hr style={{ marginLeft: '20px', marginRight: '20px' , border: 'none', height: '0.5px' , marginBottom:'5px', backgroundColor: '#cccccc'}} />
                    </>
                  );
                }
              })}
            {localStorage.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.dater &&
              convoList.map((convo) => {
                // return (
                //   <ConvosListItemDater
                //     convo={convo}
                //     handleProfileClicked={handleProfileClicked}
                //     handleAcceptRejectCallback={handleAcceptRejectCallback}
                //     setDirectChatChannel={setDirectChatChannel}
                //     setShowDirectMessage={setShowDirectMessage}
                //     setDirectChatMyProfile={setDirectChatMyProfile}
                //     setDirectChatOtherProfile={setDirectChatOtherProfile}
                //     setDirectChatConvo={setDirectChatConvo}
                //     lastMessage={
                //       historicalMessages.length > 0
                //         ? historicalMessages.find(
                //             (history) => history.channel === convo?.id,
                //           ) || null
                //         : null
                //     }
                //   />
                // );
                if (convo?.session_type === 'PIC') {
                  return (
                    <>
                    <div
                    style={{
                      paddingLeft:'10px',
                      paddingRight:'10px'
                    }}
                    >
                      <ConvosListItemDater
                        convo={convo}
                        handleProfileClicked={handleProfileClicked}
                        handleAcceptRejectCallback={handleAcceptRejectCallback}
                        setDirectChatChannel={setDirectChatChannel}
                        setShowDirectMessage={setShowDirectMessage}
                        setDirectChatMyProfile={setDirectChatMyProfile}
                        setDirectChatOtherProfile={setDirectChatOtherProfile}
                        setDirectChatConvo={setDirectChatConvo}
                        lastMessage={
                          historicalMessages.length > 0
                            ? historicalMessages.find(
                              (history) => history.channel === convo?.id,
                            ) || null
                            : null
                        }
                      />
                      </div>
<hr style={{ marginLeft: '20px', marginRight: '20px' , border: 'none', height: '0.5px' , marginBottom:'5px', backgroundColor: '#cccccc'}} />
                    </>
                  );
                }
              })}
          </>
        )}
        {prospectiveConvo && !showDirectMessage && (
          <ConvosProspectiveDaterProfile
            onBackClicked={onProspectiveDaterProfileBackClicked}
            handleButtonClickedCallback={handleButtonClickedCallback}
            convo={prospectiveConvo}
          />
        )}
        {!prospectiveConvo && showDirectMessage && (
          <DirectChat
            myProfile={directChatMyProfile}
            activeDaterFriend={activeDater}
            otherProfile={directChatOtherProfile}
            channel={directChatChannel}
            onBackClicked={onDirectChatBackClicked}
            convo={reachOutDater ? reachOutDater : directChatConvo}
          />
        )}
      </Box>
    </>
  );
};

export default Elbows;
