import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import InputField from '../../atoms/InputField';

const MakeIntrowDialog = ({
  isOpen = false,
  onConfirmClick,
  onCancelClick,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleCancel = () => {
    setOpen(false);
    onCancelClick();
  };
  const handleConfirmClick = () => {
    onConfirmClick();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        className={styles.heading}
        style={{
          fontSize: `${scaleByRatio(22)}px`,
          lineHeight: `${scaleByRatio(38)}px`,
        }}>
        Make this{' '}
        <span
          style={{
            // color: '#F46B1B',
            color:'#ECA43C'
          }}>
          Introw ?
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{ background: '#FFFFFF' }}>
          <Grid
            item
            xs={12}
            justifyContent="left"
            style={{
              display: `flex`,
            }}>
            <Typography
              fullwidth
              textAlign={'left'}
              className={styles.subHeading}
              style={{
                fontSize: `${scaleByRatio(14)}px`,
              }}>
              To make an introw between these 2 daters.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          marginBottom:'5px',
          justifyContent: 'center',
          display: 'flex',
          // gap: '10px',
          padding: '10px',
        }}>
        <Button
          onClick={handleCancel}
          autoFocus
          className={`${styles.button} ${styles.secondary}`}
          style={{
            fontSize: `${scaleByRatio(14)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
            flex: '0 0 auto', // Prevent stretching
            minWidth: '120px', // Set a minimum width
            paddingLeft:'42px',
            paddingRight: '42px',
            paddingBottom: '10px',
          }}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          className={`${styles.button} ${styles.primary}`}
          style={{
            fontSize: `${scaleByRatio(14)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
            flex: '0 0 auto', // Prevent stretching
            minWidth: '120px', // Set a minimum width
            paddingLeft:'15px',
            paddingRight: '15px',
            paddingBottom: '10px',
          }}>
       Confirm Now    
     </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MakeIntrowDialog;
