import AddFriend from '../../assets/AddFriend.svg'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper';

const FriendListAddItem = ({
    callBackFunction
}) => {
    const handleAdditemClick=()=>{
        callBackFunction(true)
    }
    return ( 
        <>
             <Grid container direction="row" columnSpacing={scaleByRatio(0.5)} className={styles.root} onClick={handleAdditemClick}>
                <Grid item justifyContent="center" style={{paddingTop:`${scaleByRatio(5)}px`, cursor:"pointer"}} alignItems="center" className={styles.addFriendImg}>
                    <Typography textAlign='left'>
                        <img src={AddFriend} alt="AddFriend" width={'90px'} height={'90px'} />
                    </Typography>

                </Grid>
                <Grid item justifyContent="left" alignItems="center" style={{display: "flex", cursor:'pointer'}} onClick={handleAdditemClick}>
                    <Typography textAlign='left' className={styles.addFriend}
                    >
                            Add Friend
                    </Typography>
                </Grid>
            </Grid>
        </>
     );
}
 
export default FriendListAddItem;