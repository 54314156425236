import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import {
  useGetQuestionDetailsById,
  usePatchMultiChoiceAnswer,
  usePatchSingleChoiceAnswer,
  usePostMultiChoiceAnswer,
  usePostSingleChoiceAnswer,
} from '../../services/mutations';
import Questionare from '../Questionaire';
import QuestionHeight from '../QuestionHeight';
import Loader from '../../molecule/Loader';
import { AnswerType, Q_Identifier_Height } from '../../utils/constants';
import { routeUrls } from '../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';

const MyProfileUpdateQuestion = ({
  QuestionID,
  identifier,
  allQuestions,
  allAnwers,
  setEditMode,
}) => {
  const nav = useNavigate();
  const getQuestionDetailsById = useGetQuestionDetailsById();
  const patchSingleChoiceAnswer = usePatchSingleChoiceAnswer();
  const patchMultiChoiceAnswer = usePatchMultiChoiceAnswer();
  const postMultiChoiceAnswer = usePostMultiChoiceAnswer();
  const postSingleChoiceAnswer = usePostSingleChoiceAnswer();

  const [question, setQuestion] = useState(null);
  const [questionPatchID, setQuestionPatchID] = useState(null);
  const [storedAnswerDetails, setStoredAnswerDetails] = useState();
  const [questionDetails, setQuestionDetails] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const getQuestionByIdentifier = (identifier) => {
    allQuestions?.forEach((question) => {
      if (question?.identifier === identifier) {
        setQuestion(question);
      }
    });
  };

  const getSelectedAnswersByIdentifier = (identifier) => {
    let arrSelectedAnswer = [];
    allAnwers?.forEach((answerItem) => {
      if (answerItem?.answer?.question?.identifier === identifier) {
        arrSelectedAnswer.push(answerItem?.answer?.id);
        if (answerItem?.answer?.question?.choice_type === AnswerType.SC) {
          console.log('Patch id: ', answerItem);
          setQuestionPatchID(answerItem?.id);
        }
      }
    });
    console.log('getSelectedAnswersByIdentifier: ', arrSelectedAnswer);
    setStoredAnswerDetails(arrSelectedAnswer);
  };

  const getSelectedHeightAnswer = (identifier) => {
    let selectedAnswer = '';
    allAnwers?.forEach((answerItem) => {
      if (answerItem?.answer?.question?.identifier === identifier) {
        selectedAnswer = answerItem?.value;
        setQuestionPatchID(answerItem?.id);
      }
    });
    console.log('getSelectedHeightAnswer: ', selectedAnswer);
    setStoredAnswerDetails(selectedAnswer);
  };

  const getQuestionDetails = (questionid) => {
    setLoading(true);
    let qData = {
      id: questionid,
    };
    getQuestionDetailsById.mutate(qData, {
      onSuccess: (response) => {
        let _questionDetails = response?.data?.payload;
        setQuestionDetails(_questionDetails);
        console.log('question details: ', response?.data?.payload);
        setLoading(false);
      },
      onError: (err) => {
        console.log('Error: ', err);
        setLoading(false);
      },
    });
  };

  const onBackClicked = () => {
    // console.log('Back clicked');
    // nav(0);
    // // window.location.href='/home';
    // localStorage.setItem('setEdit', true);
    setEditMode(false);
  };

  const patch_single_choice_answer = (answer) => {
    setLoading(true);
    let data = {
      id: questionPatchID,
      answer: answer,
    };
    patchSingleChoiceAnswer.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('patch_single_choice_answer Success:', response);
        onBackClicked();
      },
      onError: (error) => {
        setLoading(false);
        setError('Something went wrong, please try again');
      },
    });
  };

  const patch_multiple_choice_answer = (answer) => {
    setLoading(true);

    postMultiChoiceAnswer.mutate(answer, {
      onSuccess: (response) => {
        setLoading(false);
        onBackClicked();
      },
      onError: (error) => {
        setLoading(false);
        setError('Something went wrong, please try again');
      },
    });
  };

  const onSubmitClicked = (answers, answerType) => {
    console.log('onSubmitClicked answers: ', answers);
    console.log('onSubmitClicked answerType: ', answerType);
    if (answerType === AnswerType.SC) {
      setStoredAnswerDetails(answers);
      let selectedAnswerObj = questionDetails?.question_choices?.find(
        (choice) => {
          return choice?.id === answers[0];
        },
      );
      let answer = {
        value: selectedAnswerObj?.value,
        visible: true,
        answer: selectedAnswerObj?.id,
      };
      patch_single_choice_answer(answer);
    } else if (answerType === AnswerType.MC) {
      if (answers && answers.length > 0) {
        let multiChoiceAnswerData = [];
        answers?.forEach((element) => {
          let answerObj = {
            value: '',
            visible: true,
            answer: element,
          };
          //find value
          let selectedAnswerObj = questionDetails?.question_choices.find(
            (choice) => {
              return choice.id === element;
            },
          );
          answerObj.value = selectedAnswerObj.value;
          multiChoiceAnswerData.push(answerObj);
        });
        let data = {
          id: question.id,
          multiChoiceAnswerData: multiChoiceAnswerData,
        };
        setStoredAnswerDetails(answers);
        patch_multiple_choice_answer(data);
      } else {
        setError('Please select your spirituality.');
      }
    }
  };

  const onNextHeightClicked = (height) => {
    console.log('onNextHeightClicked : ', height);
    let answer = {
      answer: questionDetails?.question_choices[0].id, //questionPatchID,
      visible: true,
      value: height,
    };
    setLoading(true);

    patch_single_choice_answer(answer);
    // postSingleChoiceAnswer.mutate(answer,{
    //     onSuccess:(response)=>{
    //         setLoading(false)
    //         console.log('Success height: ', response)
    //     },
    //     onError:(error)=>{
    //         setLoading(false)
    //         console.log('Error height: ', error)
    //     }
    // })
  };

  useEffect(() => {
    console.log('identifier>>>:', identifier);
    console.log('allAnwers>>>:', allAnwers);
    console.log('allQuestions>>>:', allQuestions);
    getQuestionByIdentifier(identifier);
    if (identifier !== Q_Identifier_Height) {
      getSelectedAnswersByIdentifier(identifier);
    } else {
      getSelectedHeightAnswer(identifier);
    }
  }, []);
  useEffect(() => {
    if (question) {
      getQuestionDetails(question?.id);
    }
  }, [question]);
  return (
    <>
      <Loader open={loading} />
      {!loading && identifier !== Q_Identifier_Height && (
        <Questionare
          questionText={question?.name}
          answerType={questionDetails?.choice_type}
          onBackClicked={onBackClicked}
          error={error}
          handleOnNextClicked={onSubmitClicked}
          answerOptions={questionDetails?.question_choices}
          showBackButton={true}
          selectedAnswer={storedAnswerDetails || []}
          submiButtonLabel={'Save'}
        />
      )}
      {!loading && identifier === Q_Identifier_Height && (
        <QuestionHeight
          questionText={question?.name}
          answerType={AnswerType.TF}
          onBackClicked={onBackClicked}
          handleOnNextClicked={onNextHeightClicked}
          error={error}
          answer={storedAnswerDetails}
          showBackButton={true}
          btnSubmitText={'Save'}
        />
      )}
    </>
  );
};

export default MyProfileUpdateQuestion;
