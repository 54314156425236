import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import IphoneShare from '../../assets/IphoneShare.svg';
import DownloadCloseIcon from '../../assets/DownloadCloseIcon.svg';
import InstallDownArrow from '../../assets/InstallDownArrow.svg';
import InstallUpArrow from '../../assets/InstallUpArrow.svg';
import { Box } from '@mui/material';
import AppLogo from '../../assets/AppLogo.svg';
import Button from '@mui/material/Button';
import DownloadPlusIcon from '../../assets/DownloadPlusIcon.svg';
import MacAddIcon from '../../assets/MacAddIcon.svg';
import pwaInstall1 from '../../assets/pwaInstall1.svg';
import pwaInstall2 from '../../assets/pwaInstall2.svg';
import './styles.scss';

const InstallPWA = () => {
    const [isIPad, setIsIPad] = useState(false);
    const [isIPhone, setIsIPhone] = useState(false);
    const [IsMacBook, setIsMacBook] = useState(false);
    const [showIosDownload, setShowIosDownload] = useState(false);
    const [showIpad, setShowIpad] = useState(false);
    const [showIphone, setShowIphone] = useState(false);
    const [showMac, setShowMac] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        setIsIPad(!!userAgent.match(/iPad/i));
        setIsIPhone(!!userAgent.match(/iPhone/i));
        setIsMacBook(!!userAgent.match(/Macintosh/i));
    }, []);

    useEffect(() => {
        handleDeviceSelect();
    }, [isIPad, isIPhone, IsMacBook]);

    const handleDeviceSelect = () => {
        if (isIPad === true) {
            setShowIosDownload(true);
            setShowIpad(true);
        } else if (isIPhone === true) {
            setShowIosDownload(true);
            setShowIphone(true);
            window.scrollTo(0, 0);
        } else if (IsMacBook === true) {
            setShowIosDownload(true);
            setShowMac(true);
        }
    }

    return (
        <>
            {showIphone ? (
                <Modal
                    open={showIosDownload}
                    onClose={() => setShowIosDownload(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: 'absolute',
                            top: showIphone ? '65%' : showMac ? '20%' : '15%',
                            left: showMac || showIpad ? '70%' : '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '300px',
                            maxWidth: 400,
                            bgcolor: '#181B20',
                            boxShadow: 24,
                            p: 3,
                            borderRadius: '8px',
                        }}>
                        <img
                            src={AppLogo}
                            style={{ width: '108px', height: '32px' }}></img>
                        <IconButton
                            aria-label="close"
                            onClick={() => setShowIosDownload(false)}
                            sx={{
                                position: 'absolute',
                                top: 25,
                                right: 15,
                                color: '#22A887',
                                // borderRadius: '50%',
                                // border: '2px solid #22A887',
                                padding: '1px',
                            }}>
                            {/* <CloseIcon /> */}
                            <img src={DownloadCloseIcon} alt="icon" />
                        </IconButton>

                        <p style={{ color: '#BCBCBC' }}>
                            To install meadow on your phone, just follow these easy steps
                        </p>
                        <div className='phone-step-1'>
                            <div className='phone-step-1-head'>Step 1</div>
                            <div className='phone-step-1-desc'>Click on “ Share “  icon below</div>
                        </div>
                        {
                            showIphone &&
                            <div className='install-img-1'>
                                <img src={pwaInstall1} className='pwaInstall1 w-100' alt="imageNp" />
                            </div>
                        }
                        {
                            showMac &&
                            <div
                                className="d-flex"
                                style={{
                                    marginBottom: '-55px',
                                    marginLeft: '-5px',
                                    display: 'flex',
                                    marginRight: '20px',
                                }}>
                                <img
                                    src={InstallUpArrow}
                                    alt="img np"
                                    style={{ marginRight: '7px' }}
                                />
                                <img src={MacAddIcon} alt="img np" />
                            </div>
                        }

                        {
                            isIPhone &&
                            <>
                                <div className='iphone-lower-div'>
                                    <div className='div-1'>
                                        <div className='div-1-parent'>
                                            <div className='phone-step-1-head'>Step 2</div>
                                            <div className='div-11'>Then scroll down and click on</div>
                                        </div>
                                        <div className='div-12'>
                                            <div className='div-122'>“Add to Home Screen”</div>
                                            <img
                                                src={DownloadPlusIcon}
                                                alt="download"
                                                // style={{ marginBottom: '-5px' }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='div-3'>
                                    <img src={pwaInstall2} className='pwa-install-2' alt="" />
                                </div>
                            </>
                        }

                        {
                            showMac &&
                            <>
                                <ol style={{ color: 'white', marginLeft: '20px' }}>
                                    <li style={{ listStyle: 'none' }}>
                                        {
                                            isIPhone ? 'Then Scroll down and click on' : 'Then click on ,'
                                        }
                                    </li>
                                    {
                                        showMac &&
                                        <li style={{ listStyle: 'none' }}>
                                            <span style={{ color: '#22A887' }}>
                                                {showMac ? 'Install' : 'Add to Home Screen'}
                                            </span>
                                            {!showMac && (
                                                <span>
                                                    <img
                                                        src={DownloadPlusIcon}
                                                        alt="download"
                                                        style={{ marginBottom: '-5px' }}
                                                    />
                                                </span>
                                            )}
                                        </li>
                                    }

                                    {
                                        isIPhone &&

                                        <li style={{ listStyle: 'none' }}>
                                            <span style={{ color: '#FE0000' }}>
                                                "Add to Home Screen"
                                            </span>
                                        </li>
                                    }
                                </ol>
                                <Button
                                    onClick={() => setShowIosDownload(false)}
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        color: '#22A887',
                                        // borderRadius: '10%',
                                        border: '2px solid #22A887',
                                        backgroundColor: 'transparent',
                                        width: '300px',
                                        height: '46px',
                                    }}>
                                    Close
                                </Button>
                            </>
                        }
                    </Box>
                </Modal>
            ) : (
                <Modal
                    open={showIosDownload}
                    onClose={() => setShowIosDownload(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: 'absolute',
                            top: showIphone ? '65%' : showMac ? '20%' : '15%',
                            left: showMac || showIpad ? '70%' : '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '300px',
                            maxWidth: 400,
                            bgcolor: '#181B20',
                            boxShadow: 24,
                            p: 3,
                            borderRadius: '8px',
                        }}>
                        <img
                            src={AppLogo}
                            style={{ width: '108px', height: '32px' }}></img>
                        <IconButton
                            aria-label="close"
                            onClick={() => setShowIosDownload(false)}
                            sx={{
                                position: 'absolute',
                                top: 25,
                                right: 15,
                                color: '#22A887',
                                padding: '1px',
                            }}>
                            <img src={DownloadCloseIcon} alt="icon" />
                        </IconButton>

                        <p style={{ color: 'white' }}>
                            {`To install meadow on your device, just click on the “
                      ${showMac ? `Install` : showIpad ? 'above Share' : ''
                                } ” icon`}
                        </p>
                        <div
                            className="d-flex"
                            style={{
                                marginBottom: '-55px',
                                marginLeft: '-5px',
                                display: 'flex',
                                marginRight: '20px',
                            }}>
                            <img
                                src={
                                    showIphone ? InstallDownArrow : InstallUpArrow
                                }
                                style={{ marginRight: '7px' }}
                                alt=""
                            />
                            <img src={showMac ? MacAddIcon : IphoneShare} alt="" />
                        </div>
                        <ol style={{ color: 'white', marginLeft: '20px' }}>
                            <li style={{ listStyle: 'none' }}>
                                Then click on ,
                            </li>
                            <li style={{ listStyle: 'none' }}>
                                <span style={{ color: '#22A887' }}>
                                    {showMac ? 'Install' : 'Add to Home Screen'}
                                </span>
                                {!showMac && (
                                    <span>
                                        <img
                                            src={DownloadPlusIcon}
                                            alt="download"
                                            style={{ marginBottom: '-5px' }}
                                        />
                                    </span>
                                )}
                            </li>
                        </ol>

                        <Button
                            onClick={() => setShowIosDownload(false)}
                            variant="contained"
                            color="primary"
                            sx={{
                                mt: 2,
                                color: '#22A887',
                                border: '2px solid #22A887',
                                backgroundColor: 'transparent',
                                width: '300px',
                                height: '46px',
                            }}>
                            Close
                        </Button>
                    </Box>
                </Modal>
            )}
        </>
    )
}

export default InstallPWA;