import MyProfileUpdateLayout from '../../Layout/MyProfileUpdateLayout';
import MyProfileUpdateQuestion from '../MyProfileUpdateQuestion';
import MyProfileUpdatProfile from '../MyProfileUpdateProfile';
import { useEffect } from 'react';

const MyProfileUpdate = ({
  allQuestions,
  allAnwers,
  type,
  QuestionID,
  identifier,
  setEditMode,
  userDetails,
}) => {
  console.log('inside MyProfileUpdate', type);
  return (
    <MyProfileUpdateLayout>
      {type === 'Questions' && (
        <MyProfileUpdateQuestion
          allQuestions={allQuestions}
          allAnwers={allAnwers}
          QuestionID={QuestionID}
          identifier={identifier}
          setEditMode={setEditMode}
        />
      )}
      {type === 'Profile' && (
        <MyProfileUpdatProfile
          allQuestions={allQuestions}
          allAnwers={allAnwers}
          QuestionID={QuestionID}
          identifier={identifier}
          setEditMode={setEditMode}
          userDetails={userDetails}
        />
      )}
    </MyProfileUpdateLayout>
  );
};

export default MyProfileUpdate;
