import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { scaleByRatio } from '../../utils/helper';
import styles from "./index.module.scss"
import './index.module.scss'
import {  Grid } from '@mui/material'
import IconClose from '../../assets/IconClose2.svg';


const ImageCropper = (
    {
        ImageURL,
        callBackImageCropped,
        callBackImageNotCropped,
        handleCloseModalCallBack
    }
) => {
    const [show, setShow] = useState(false);
    const [cropper, setCropper] = useState(null);
    const handleClose = () =>{
        callBackImageNotCropped(null);
        setShow(false);
    } 
    const handleCloseModal = ()=>{
      handleCloseModalCallBack();
      setShow(false);
    }
    useEffect(() => {
      console.log('Cropper useeffect')
        if(ImageURL){
          setShow(true)
        }
        
      }, [])
    const getCropData = async () => {
      
        if (cropper) {
          const file = await fetch(cropper.getCroppedCanvas().toDataURL())
            .then((res) => res.blob())
            .then((blob) => {
              return new File([blob], "newAvatar.png", { type: "image/png" });
            });
          if (file) {
            callBackImageCropped(file);
            handleCloseModal()
          }else{
            handleClose()
          }
        }
      };

      
    return ( 
        <>
        <Modal open={show} onClose={handleCloseModal}  BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >

          
          <Box sx={{ maxWidth: {xs:300, sm:375},
           position: 'absolute',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
           flexGrow: 1, backgroundColor:'#E66433', 
           padding:`${scaleByRatio(50)}px`,
           paddingBottom: '30px'
           }}>
            <button
        onClick={handleClose}
        className="close-icon-button"
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          background: 'none',
          border: 'none',
          padding: '0',
          cursor: 'pointer',
          zIndex: 1,
          width: '16px', // Width of the SVG icon
          height: '16px', // Height of the SVG icon
        }}
      >
        {/* SVG as background */}
        <img
        src={IconClose}
        alt="img np"
        />
      </button>
          <Grid container direction="row"  style={{display:'flex'}}>
          <Grid container direction="row" justifyContent={"center"}>
            <Typography fullwidth textAlign={"center"}
                style={{
                  // fontFamily: 'Knuckle Down',
                  marginBottom:"10px",
                  marginTop:'-10px',
                  fontFamily: 'Gill Sans Nova',
                  fontWeight: "900",
                  textAlign: "center",
                  color:"#ECA43C",
                  fontSize:`${scaleByRatio(22)}px`,
                  lineHeight:`${scaleByRatio(38)}px`,
                  height:`${scaleByRatio(45)}px`,
            }}>
                Crop Image

          </Typography>
          </Grid>
          <Grid container direction="row" justifyContent={"center"}>
            <Typography fullwidth textAlign={"center"}>
              <Cropper
                  src={ImageURL}
                  className={styles.customCropBox} 
                  style={{ 
                    height: 360, 
                    width: 350, 
                    //border: "1px solid #44C8F5",
                    borderRadius: "15px",
                    // Optional: If you want to control the overflow of the container
                    overflow: "hidden",
                    // marginLeft: "25px",
                    // marginRight: "25px",
                    // border: "1px solid #ECA43C",
                    // borderRadius: "50px"
                  }}
                  //border={"10px solid #44C8F5"}
                  initialAspectRatio={6 / 5}
                  minCropBoxHeight={120}
                  minCropBoxWidth={120}
                  maxCropBoxHeight={80}
                  maxCropBoxWidth={80}
                  guides={false}
                  dragMode={"move"}
                  checkOrientation={false}
                  cropBoxResizable={false}
                  autoCropArea={1} 
                  onInitialized={(instance) => {
                      setCropper(instance);
                      
                  }}
              />
              <div style={{textAlign: "center", marginTop: "5px"}}>
              
              </div>
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6} >
            <Typography fullwidth textAlign={"right"} justifyContent={"right"} style={{display:'flex'}}>
              <Button variant="secondary" 
                style={{marginRight: "15px", marginTop:"15px"}} 
                onClick={()=>handleClose()}
                className={`${styles.button} ${styles.secondary} `}
                >CANCEL</Button>
              
            </Typography>
          </Grid>
          <Grid item xs={6} >
              <Typography fullwidth textAlign={"left"} >
                <Button 
                style={{marginRight: "15px", marginTop:"15px"}} 
                className={`${styles.button} ${styles.primary} `}
                onClick={getCropData}>
                    CROP IMAGE
                </Button>
              </Typography>
          </Grid>
          </Grid>
          </Grid>
          </Box>
        </Modal>
        </>
     );
}
 
export default ImageCropper;