import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import close_icon from '../../assets/close_icon.svg';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import styles from './index.module.scss';
import FullPageGridLayout from '../../Layout/FullPageGridLayout';
import { scaleByRatio } from '../../utils/helper';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
const rawHtml = `
<div
    lang="EN-IN"
    link="#000000"
    vlink="fuchsia"
    style="tab-interval: 36pt; font-size: 14px; word-wrap: break-word"
  >
    <div class="WordSection1">
      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
            lang="EN-US"
            style="
              font-size: 18px;
              font-family: 'Gill Sans Nova';
              color: #fff;
              letter-spacing:0.01em;
              text-transform:uppercase;
            "
            class="privacySecHead"
            >Terms of Use Agreement</span
          >
          <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <i
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >Effective on 2023-07-15
          </span></i
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >California subscribers: You may cancel your subscription, without
          penalty or obligation, at any time prior to midnight of the third
          business day following the date you subscribed. If you subscribed
          using an External Service (e.g., Apple ID, Google Play), you must
          cancel through your External Service, as set forth in more detail in
          Section 8a. If you subscribed through your Apple ID, refunds are
          handled by Apple, not Introw. You can request a refund from Apple
          through your Apple ID account on your phone or at
          https://getsupport.apple.com. All other users may request a refund by
          contacting Introw Customer Service by clicking </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/contactus"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >here</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, or by mailing or delivering a signed and dated notice that states
          that you, the buyer, are canceling this agreement, or words of similar
          effect. Please also include your name and the email address, phone
          number, or other unique identifier you used to sign up for your
          account.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >This notice shall be sent to: Introw, Attn: Cancellations, Sag Harbor
          NY 11963</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >We have included brief summaries at the beginning of each section to
          make it easier for you to read and understand this agreement. The
          summaries do not replace the text of each section, and you should
          still read each section in its entirety.</span
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >1. INTRODUCTION</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <b
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >By accessing or using Introw's Services, you agree to be bound by
              this Terms of Use Agreement (the &quot;Terms&quot; or
              &quot;Agreement&quot;), including our
            </span></i
          ></b
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><b
                ><i
                  ><span
                    style="
                      color: #BCBEC0;
                      mso-style-textfill-fill-color: #BCBEC0;
                      mso-style-textfill-fill-alpha: 100%;
                    "
                    ><span
                      style="
                        color: #BCBEC0;
                        mso-style-textfill-fill-color: #BCBEC0;
                        mso-style-textfill-fill-alpha: 100%;
                      "
                      >Privacy Policy</span
                    ></span
                  ></i
                ></b
              ></a
            ></span
          ></span
        ><b
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >,
            </span></i
          ></b
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/cookiepolicy"
              ><b
                ><i
                  ><span
                    style="
                      color: #BCBEC0;
                      mso-style-textfill-fill-color: #BCBEC0;
                      mso-style-textfill-fill-alpha: 100%;
                    "
                    ><span
                      style="
                        color: #BCBEC0;
                        mso-style-textfill-fill-color: #BCBEC0;
                        mso-style-textfill-fill-alpha: 100%;
                      "
                      >Cookie Policy</span
                    ></span
                  ></i
                ></b
              ></a
            ></span
          ></span
        ><b
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >,
            </span></i
          ></b
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/communityguidelines"
              ><b
                ><i
                  ><span
                    style="
                      color: #BCBEC0;
                      mso-style-textfill-fill-color: #BCBEC0;
                      mso-style-textfill-fill-alpha: 100%;
                    "
                    ><span
                      style="
                        color: #BCBEC0;
                        mso-style-textfill-fill-color: #BCBEC0;
                        mso-style-textfill-fill-alpha: 100%;
                      "
                      >Community Guidelines</span
                    ></span
                  ></i
                ></b
              ></a
            ></span
          ></span
        ><b
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >, and
            </span></i
          ></b
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://help.match.com/hc/en-us/articles/6991442293787-Dating-Safety-Tips"
              ><b
                ><i
                  ><span
                    style="
                      color: #BCBEC0;
                      mso-style-textfill-fill-color: #BCBEC0;
                      mso-style-textfill-fill-alpha: 100%;
                    "
                    ><span
                      style="
                        color: #BCBEC0;
                        mso-style-textfill-fill-color: #BCBEC0;
                        mso-style-textfill-fill-alpha: 100%;
                      "
                      >Safety Tips</span
                    ></span
                  ></i
                ></b
              ></a
            ></span
          ></span
        ><b
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >, so it is important that you read this Agreement and these
              policies and procedures carefully before you create an
              account.</span
            ></i
          ></b
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN
            SECTION 15 BELOW. THESE GOVERN THE MANNER IN WHICH CLAIMS WILL BE
            ADDRESSED BETWEEN YOU AND Introw. THESE PROVISIONS INCLUDE A
            MANDATORY PRE-ARBITRATION INFORMAL DISPUTE RESOLUTION PROCESS, AN
            ARBITRATION AGREEMENT, SMALL CLAIMS COURT ELECTION, CLASS ACTION
            WAIVER, ADDITIONAL PROCEDURES FOR MASS ARBITRATION FILINGS, AND JURY
            TRIAL WAIVER THAT AFFECT YOUR RIGHTS. IN ARBITRATION, THERE IS
            TYPICALLY LESS DISCOVERY AND APPELLATE REVIEW THAN IN COURT.</span
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >We may update these Terms from time to time, so check this page
              regularly for updates.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Welcome to Introw, operated by Introw inc in the case of users
          originating from within the United States and Canada, and operated by
          Introw.com Global Services Limited, in the case of users originating
          from outside of the United States and Canada. As used in this
          Agreement, the terms &quot;Introw,&quot; &quot;us,&quot;
          &quot;we,&quot; the &quot;Company&quot;, and &quot;our&quot; shall
          refer to Introw Group, LLC and/or Introw.com Global Services Limited,
          as appropriate. Together you and Introw may be referred to as the
          &quot;Parties&quot; or separately as &quot;Party.&quot;</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >By accessing or using our Services on Introw.com (the
          &quot;Website&quot;), the Introw mobile application (the
          &quot;App&quot;), or any other platforms or services Introw may offer
          (collectively, the &quot;Service&quot; or our &quot;Services&quot;),
          you agree to, and are bound by, this Agreement. This Agreement applies
          to anyone who accesses or uses our Services, regardless of
          registration or subscription status.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Your access and use of our Services is also subject to the </span
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Privacy Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/cookiepolicy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Cookie Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/communityguidelines"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Community Guidelines</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, and </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://help.match.com/hc/en-us/articles/6991442293787-Dating-Safety-Tips"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Safety Tips</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          and any terms disclosed and agreed to by you when you purchase
          additional features, products, or services from Introw
          (&quot;Additional Terms Upon Purchase&quot;), which are incorporated
          into this Agreement by reference. If you do not wish to be bound by
          this Agreement, do not <span class="GramE">access</span> or use our
          Services.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >We reserve the right to modify, amend, or change the Terms at any
          time. Notice of any material change will be posted on this page with
          an updated effective date. In certain circumstances, we may notify you
          of a change to the Terms via email or other means; however, you are
          responsible for regularly checking this page for any changes. Your
          continued access or use of our Services constitutes your ongoing
          consent to any changes, and as a result, you will be legally bound by
          the updated Terms. If you do not accept a change to the Terms, you
          must stop accessing or using our Services immediately.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >2. ACCOUNT ELIGIBILITY; YOUR RESPONSIBILITIES</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Before you create an account on Introw, make sure you are
              eligible to use our Services. This Section also details what you
              can and can</span
            ></i
          ><
        ><span dir="RTL"></span
        ><
          ><i
            ><span
              lang="AR-SA"
              dir="RTL"
              style="
                font-family: 'Arial Unicode MS', sans-serif;
                mso-ascii-font-family: 'Gill Sans Nova';
                mso-hansi-font-family: 'Gill Sans Nova';
                color: #BCBEC0;
              "
              ><span dir="RTL"></span>’</span
            ></i
          ><
        ><
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >t do when using the Services, as well as the rights you grant
              Introw.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >You are not authorized to create an account or use the Services
            unless <span class="GramE">all of</span> the following are true, and
            by using our Services, you represent and warrant that:</span
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >1.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are at least 18 years <span class="GramE">old;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >2.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are legally qualified to enter a binding contract with
          <span class="GramE">Introw;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >3.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are single or separated from your
          <span class="GramE">spouse;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >4.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are not located in a country that is subject to a U.S. Government
          embargo, or that has been designated by the U.S. Government as a
          &quot;terrorist supporting&quot; <span class="GramE">country;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >5.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are not on any list of individuals prohibited from conducting
          business with the United <span class="GramE">States;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >6.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are not prohibited by law from using our
          <span class="GramE">Services;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >7.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You have not committed, been convicted of, or pled no contest to a
          felony or indictable offense (or crime of similar severity), a sex
          crime, or any crime involving violence or a threat of violence, unless
          you have received clemency for a non-violent
          <span class="GramE">crime</span> and we have determined that you are
          not likely to pose a threat to other users of our Services;<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >8.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are not required to register as a sex offender with any state,
          federal or local sex offender <span class="GramE">registry;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >9.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You do not have more than one account on our Services; and<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >10.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You have not previously been removed from our Services or our
          affiliates</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >services by us or our
          <span class="GramE">affiliates, unless</span> you have our express
          written permission to create a new account.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If at any time you cease to meet these requirements, all
          authorization to access our Services or systems is automatically
          revoked, and you must immediately delete your account.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >You agree to:</span
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Comply with these Terms, and check this page from time to time to
          ensure you are aware of any <span class="GramE">changes;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Comply with all applicable laws, including without limitation,
          privacy laws, intellectual property laws, anti- spam laws, and
          regulatory <span class="GramE">requirements;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use the latest version of the Website and/or
          <span class="GramE">App;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Treat other users in a courteous and respectful manner, both on and
          off our <span class="GramE">Services;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Be respectful when communicating with any of our customer care
          representatives or other <span class="GramE">employees;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Review the </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://help.match.com/hc/en-us/articles/6991442293787-Dating-Safety-Tips"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Safety Tips</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >;<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Maintain a strong password and take reasonable measures to protect
          the security of your login information.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >You agree that you will not:</span
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Misrepresent your identity, age, current or previous positions,
          qualifications, or affiliations with a person or
          <span class="GramE">entity;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use the Services in a way that damages the Services or prevents their
          use by other <span class="GramE">users;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use our Services in a way to interfere with, disrupt or negatively
          affect the platform, the servers, or our Services</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span class="GramE"
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >networks;</span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use our Services for any harmful, illegal, or nefarious
          <span class="GramE">purpose;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Harass, bully, stalk, intimidate, assault, defame, harm or otherwise
          mistreat any <span class="GramE">person;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Post or share Prohibited Content (see below<span class="GramE"
            >);</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Solicit passwords for any purpose, or personal identifying
          information for commercial or unlawful purposes from other users or
          disseminate another person</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s personal information without his or her
          <span class="GramE">permission;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Solicit money or other items of value from another user, whether as a
          gift, loan, or form of <span class="GramE">compensation;</span
          ><o:p></o:p></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use another user</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s <span class="GramE">account;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use our Services in relation to fraud, a pyramid scheme, or other
          similar practice; or<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Violate the terms of the license granted to you by Introw (see
          Section 6 below).<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Disclose private or proprietary information that you do not have the
          right to <span class="GramE">disclose;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Copy, modify, transmit, distribute, or create any derivative works
          from, any Member Content or Our Content, or any copyrighted material,
          images, trademarks, trade names, service marks, or other intellectual
          property, content or proprietary information accessible through our
          Services without Introw's prior written
          <span class="GramE">consent;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Express or imply that any statements you make are endorsed by
          <span class="GramE">Introw;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use any robot, crawler, site search/retrieval application, proxy or
          other manual or automatic device, method or process to access,
          retrieve, index, &quot;data mine,&quot; or in any way reproduce or
          circumvent the navigational structure or presentation of our Services
          or its <span class="GramE">contents;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Upload viruses or other malicious code or otherwise compromise the
          security of our <span class="GramE">Services;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Forge headers or otherwise manipulate identifiers to disguise the
          origin of any information transmitted to or through our
          <span class="GramE">Services;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >&quot;Frame&quot; or &quot;mirror&quot; any part of our Services
          without Introw prior written <span class="GramE">authorization;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use meta tags or code or other devices containing any reference to
          Introw or the platform (or any trademark, trade name, service mark,
          logo or slogan of Introw) to direct any person to any other website
          for any <span class="GramE">purpose;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Modify, adapt, sublicense, translate, sell, reverse engineer,
          decipher, decompile or otherwise disassemble any portion of our
          Services, or cause others to do <span class="GramE">so;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use or develop any third-party applications that interact with our
          Services or Member Content or information without our written
          <span class="GramE">consent;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Use, access, or publish the Introw application programming interface
          without our written <span class="GramE">consent;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Probe, scan or test the vulnerability of our Services or any system
          or <span class="GramE">network;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Encourage, promote, or agree to engage in any activity that violates
          these Terms; or<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Create a new account after we suspend or terminate your
          <span class="GramE">account, unless</span> you receive our express
          permission.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >The license granted to you under these Terms and any authorization to
          access the Services is automatically revoked
          <span class="GramE">in the event that</span> you do any of the
          above.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >Prohibited Content—Introw prohibits uploading or sharing content
            that:</span
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Is likely to be deemed offensive or to harass, upset, embarrass,
          alarm or annoy any other <span class="GramE">person;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Is obscene, pornographic, violent or otherwise may offend human
          dignity, or contains <span class="GramE">nudity;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Is abusive, insulting or threatening, discriminatory or that promotes
          or encourages racism, sexism, hatred or
          <span class="GramE">bigotry;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Encourages or facilitates any illegal activity including, without
          limitation, terrorism, inciting racial hatred or the submission of
          which in itself constitutes committing a criminal
          <span class="GramE">offense;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Is defamatory, libelous, or <span class="GramE">untrue;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Relates to commercial activities (including, without limitation,
          sales, competitions, promotions, and advertising, solicitation for
          services, &quot;sugar daddy&quot; or &quot;sugar baby&quot;
          relationships, links to other websites or premium line telephone
          numbers<span class="GramE">);</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Involves the transmission of &quot;junk&quot; mail or &quot;spam<span
            class="GramE"
            >&quot;;</span
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Contains any spyware, adware, viruses, corrupt files, worm programs
          or other malicious code designed to interrupt, damage or limit the
          functionality of or disrupt any software, hardware,
          telecommunications, networks, servers or other equipment, Trojan horse
          or any other material designed to damage, interfere with, wrongly
          intercept or expropriate any data or personal information whether from
          Introw or <span class="GramE">otherwise;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Infringes upon any third party</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s rights (including, without limitation, intellectual property rights
          and privacy rights<span class="GramE">);</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Was not written by you or was automatically generated, unless
          expressly authorized by <span class="GramE">Introw;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Includes the image or likeness of another person without that
          person</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s consent (or in the case of a minor, the minor</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s parent or guardian), or is an image or likeness of a minor
          unaccompanied by the minor</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s parent or <span class="GramE">guardian;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Is inconsistent with the intended use of the Services; or<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >May harm the reputation of Introw or its affiliates.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >The uploading or sharing of content that violates these Terms
          (&quot;Prohibited Content&quot;) may result in the immediate
          suspension or termination of your account.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="DE"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: DE;
            "
            class="privacySecHead"
            >3. CONTENT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >It is important that you understand your rights and
              responsibilities <span class="GramE">with regard to</span> the
              content on our Services, including any content you provide or
              post. You are expressly prohibited from posting inappropriate
              content.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >While using our Services, you will have access to: (i) content that
          you upload or provide while using our Services (&quot;Your
          Content&quot;); (ii) content that other users upload or provide while
          using our Services (&quot;Member Content&quot;); and (iii) content
          that Introw provides on and through our Services (&quot;Our
          Content&quot;). In this agreement, &quot;content&quot; includes,
          without limitation, all text, images, video, audio, or other material
          on our Services, including information on users</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >profiles and in direct messages between users.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >3a. YOUR CONTENT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >You are responsible for Your Content. Don</span
            ></i
          ><
        ><span dir="RTL"></span
        ><
          ><i
            ><span
              lang="AR-SA"
              dir="RTL"
              style="
                font-family: 'Arial Unicode MS', sans-serif;
                mso-ascii-font-family: 'Gill Sans Nova';
                mso-hansi-font-family: 'Gill Sans Nova';
                color: #BCBEC0;
              "
              ><span dir="RTL"></span>’</span
            ></i
          ><
        ><
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >t share anything that you wouldn</span
            ></i
          ><
        ><span dir="RTL"></span
        ><
          ><i
            ><span
              lang="AR-SA"
              dir="RTL"
              style="
                font-family: 'Arial Unicode MS', sans-serif;
                mso-ascii-font-family: 'Gill Sans Nova';
                mso-hansi-font-family: 'Gill Sans Nova';
                color: #BCBEC0;
              "
              ><span dir="RTL"></span>’</span
            ></i
          ><
        ><
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >t want others to see, that would violate this Agreement, or that
              may expose you or us to legal liability.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You are solely responsible and liable for Your Content, and,
          therefore, you agree to indemnify, defend, release, and hold us
          harmless from any claims made in connection with Your Content.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You represent and warrant to us that the information you provide to
          <span class="GramE">us</span> or any other user is accurate, including
          any information submitted through Facebook or other third-party
          sources (if applicable), and that you will update your account
          information as necessary to ensure its accuracy.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >The content included on your individual profile should be relevant to
          the intended use of our Services. You may not display any personal
          contact or banking information, whether in relation to you or any
          other person (for example, names, home addresses or postcodes,
          telephone numbers, email addresses, URLs, credit/debit card or other
          banking details). If you choose to reveal any personal information
          about yourself to other users, you do so at your own risk. We
          encourage you to use caution in disclosing any personal information
          online.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Your individual profile will be visible to other people around the
          world, so be sure that you are comfortable sharing Your Content before
          you post. You acknowledge and agree that Your Content may be viewed by
          other users, and, notwithstanding these Terms, other users may share
          Your Content with third parties. By uploading Your Content, you
          represent and warrant to us that you have all necessary rights and
          licenses to do so and automatically grant us a license to use Your
          Content as provided under Section 7 below.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You understand and agree that we may monitor or review Your Content,
          and we have the right to remove, delete, edit, limit, or block or
          prevent access to any of Your Content at any time in our sole
          discretion. Furthermore, you understand agree that we have no
          obligation to display or review Your Content.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="DE"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: DE;
            "
            class="privacySecHead"
            >3b. MEMBER CONTENT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >While you will have access to Member Content, it is not yours and
              you may not copy or use Member Content for any purpose except as
              contemplated by these Terms.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Other users will also share content on our Services. Member Content
          belongs to the user who posted the content and is stored on our
          servers and displayed at the direction of that user.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You do not have any rights in relation to Member Content, and, unless
          expressly authorized by Introw, you may only use Member Content to the
          extent that your use is consistent with our Services</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >purpose of allowing users to communicate with and meet one another.
          You may not copy the Member Content or use Member Content for
          commercial purposes, to spam, to harass, or to make unlawful threats.
          We reserve the right to terminate your account if you misuse Member
          Content.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="DE"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: DE;
            "
            class="privacySecHead"
            >3c. OUR CONTENT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Introw owns all other content on our Services.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Any other text, content, graphics, user interfaces, trademarks,
          logos, sounds, artwork, images, and other intellectual property
          appearing on our Services is owned,
          <span class="GramE">controlled</span> or licensed by us and protected
          by copyright, trademark and other intellectual property law rights.
          All rights, title, and interest in and to Our Content
          <span class="GramE">remains with us at all times</span>.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >We grant you a limited license to access and use Our Content as
          provided under Section 6 below, and we reserve all other rights.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >4. INAPPROPRIATE CONTENT AND MISCONDUCT; REPORTING</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Introw does not tolerate inappropriate content or behavior on our
              Services.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >We are committed to maintaining a positive and respectful Introw
          community, and we do not tolerate any inappropriate content or
          misconduct, whether on or <span class="GramE">off of</span> the
          Services (including, but not limited to, on services operated by our
          affiliates). We encourage you to report any inappropriate Member
          Content or misconduct by other users. You can report a user directly
          through the &quot;Report a Concern&quot; link on a user</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s profile or at the bottom of every email. You may also email Introw
          Customer Service by clicking </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/contactus.aspx"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >here</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Member Content is subject to the terms and conditions of Sections
          512(c) and/or 512(d) of the Digital Millennium Copyright Act 1998. To
          submit a complaint regarding Member Content that may constitute
          intellectual property infringement, see Section 12 (Digital Millennium
          Copyright Act) below.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="PT"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: PT;
            "
            class="privacySecHead"
            >5. PRIVACY</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Privacy is important to us. We have a separate policy about it
              that you should read.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >For information about how Introw and its affiliates collect, use, and
          share your personal data, please read our </span
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Privacy Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. By using our Services, you agree that we may use your personal data
          in accordance with our </span
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Privacy Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >6. RIGHTS YOU ARE GRANTED BY Introw</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Introw grants you the right to use and enjoy our Services,
              subject to these Terms.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >For as long as you comply with these Terms, Introw grants you a
          personal, worldwide, royalty-free, non-assignable, non-exclusive,
          revocable, and non-sublicensable license to access and use our
          Services for purposes as intended by Introw and permitted by these
          Terms and applicable laws. This license and any authorization to
          access the Service are automatically revoked
          <span class="GramE">in the event that</span> you fail to comply with
          these Terms.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >7. RIGHTS YOU GRANT Introw</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >You own <span class="GramE">all of</span> the content you provide
              to Introw, but you also grant us the right to use Your Content as
              provided in this Agreement.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >By creating an account, you grant to Introw a worldwide, perpetual,
          transferable, sub-licensable, royalty-free right and license to host,
          store, use, copy, display, reproduce, adapt, edit, publish, translate,
          modify, reformat, incorporate into other works, advertise, distribute
          and otherwise make available to the general public Your Content,
          including any information you authorize us to access from Facebook or
          other third-party sources (if applicable), in whole or in part, and in
          any way and in any format or medium currently known or developed in
          the future. Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s license to Your Content shall be non-exclusive, except that
          Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s license shall be exclusive with respect to derivative works created
          through use of our Services. For example, Introw would have an
          exclusive license to screenshots of our Services that include Your
          Content.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >In addition, so that Introw can prevent the use of Your Content
          outside of our Services, you authorize Introw to act on your behalf
          with respect to infringing uses of Your Content taken from our
          Services by other users or third parties. This expressly includes the
          authority, but not the obligation, to send notices pursuant to 17
          U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices) on your behalf if
          Your Content is taken and used by third parties outside of our
          Services. Introw is not obligated to take any action
          <span class="GramE">with regard to</span> use of Your Content by other
          users or third parties. Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s license to Your Content is subject to your rights under applicable
          law (for example, laws regarding personal data protection to the
          extent the content contains personal information as defined by those
          laws).</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >In consideration for Introw allowing you to use our Services, you
          agree that we, our affiliates, and our third-party partners may place
          advertising on our Services. By submitting suggestions or feedback to
          Introw regarding our Services, you agree that Introw may use and share
          such feedback for any purpose without compensating you.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You agree that Introw may access, preserve, and disclose your account
          information, including Your Content, if required to do so by law or
          upon a good faith belief that such access, preservation, or disclosure
          is reasonably necessary to: (i) comply with legal process; (ii)
          enforce these Terms; (iii) respond to claims that any content violates
          the rights of third parties; (iv) respond to your requests for
          customer service; or (v) protect the rights, property or personal
          safety of the Company or any other person.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="
            font-size: 16pt;
            font-family: 'Gill Sans Nova';
            color: #fff;
          "
          class="privacySecHead"
          >8. PURCHASES AND AUTOMATICALLY RENEWING SUBSCRIPTIONS</span
        ><span
          lang="EN-US"
          style="
            font-size: 16pt;
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >You will have the opportunity to purchase products and services
              from Introw. If you purchase a subscription, it will automatically
              renew—and you will be charged—until you cancel.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw may offer products and services for purchase through iTunes,
          Google Play or other external services authorized by Introw (each, an
          &quot;External Service,&quot; and any purchases made thereon, an
          &quot;External Service Purchase&quot;). Introw may also offer products
          and services for purchase via credit card or other payment processors
          on the Website or inside the App (&quot;Internal Purchases&quot;). If
          you purchase a subscription, it will automatically renew until you
          cancel, in accordance with the terms disclosed to you at the time of
          purchase, as further described below. If you cancel your subscription,
          you will continue to have access to your subscription benefits until
          the end of your subscription period, at which point it will
          expire.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Because our Services may be utilized without a subscription,
          canceling your subscription does not remove your profile from our
          Services. If you wish to fully terminate your membership, you must
          terminate your membership as set forth in Section 9.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw operates a global business, and our pricing varies by
          <span class="GramE">a number of</span> factors. We frequently offer
          promotional rates - which can vary based on region, length of
          subscription, bundle size and more. We also regularly test new
          features and payment options.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="
            font-size: 16pt;
            font-family: 'Gill Sans Nova';
            color: #fff;
          "
          class="privacySecHead"
          >8a. EXTERNAL SERVICE PURCHASES AND SUBSCRIPTIONS</span
        ><span
          lang="EN-US"
          style="
            font-size: 16pt;
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >External Service Purchases, including subscriptions, may be
              processed through the External Service, in which case those
              purchases must be managed through your External Service Account.
              Subscriptions automatically renew until you cancel.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >When making a purchase on the Service, you may have the option to pay
          through an External Service, such as with your Apple ID or Google Play
          account (&quot;your External Service Account&quot;), and your External
          Service Account will be charged for the purchase in accordance with
          the terms disclosed to you at the time of purchase and the general
          terms applicable to your External Service Account. Some External
          Services may charge you sales tax, depending on where you live, which
          may change from time to time.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If your External Service Purchase includes an automatically renewing
          subscription, your External Service Account will continue to be
          periodically charged for the subscription until you cancel. After your
          initial subscription commitment period, and again after any subsequent
          subscription period, the subscription will automatically continue for
          the price and <span class="GramE">time period</span> you agreed to
          when subscribing.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span class="None"
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >To cancel a subscription</span
            ></i
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >: If you do not want your subscription to renew automatically, or if
          you want to change or terminate your subscription, you must log in to
          your External Service Account and follow instructions to manage or
          cancel your subscription, even if you have otherwise deleted your
          account with us or if you have deleted the App from your device. For
          example, if you subscribed using your Apple ID, cancellation is
          handled by Apple, not Introw. To cancel a purchase made with your
          Apple ID, go to Settings &gt; iTunes &amp; App Stores &gt; [click on
          your Apple ID] &gt; View Apple ID &gt; Subscriptions, then find your
          Introw subscription and follow the instructions to cancel. You can
          also request assistance at https://getsupport.apple.com. Similarly, if
          you subscribed on Google Play, cancellation is handled by Google. To
          cancel a purchase made through Google Play, launch the Google Play app
          on your mobile device and go to Menu &gt; My Apps &gt; Subscriptions,
          then find your Introw subscription and follow the instructions to
          cancel. You can also request assistance at https://play.google.com. If
          you cancel a subscription, you may continue to use the cancelled
          service until the end of your then-current subscription term. The
          subscription will not be renewed when your then-current term
          expires.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If you initiate a chargeback or otherwise reverse a payment made with
          your External Service Account, Introw may terminate your account
          immediately in its sole discretion, on the basis that you have
          determined that you do not want a Introw subscription.
          <span class="GramE">In the event that</span> your chargeback or other
          payment reversal is overturned, please contact </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://help.match.com/hc/en-us/requests/new"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Customer Care</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. Introw will retain all funds charged to your External Service
          Account until you cancel your subscription through your External
          Service Account. Certain users may be entitled to request a refund.
          See Section 8d below for more information.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="
            font-size: 16pt;
            font-family: 'Gill Sans Nova';
            color: #fff;
          "
          class="privacySecHead"
          >8b. INTERNAL PURCHASES AND SUBSCRIPTIONS</span
        ><span
          lang="EN-US"
          style="
            font-size: 16pt;
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Internal Purchases, including subscriptions, are processed using
              the Payment Method you provide on the Website or App.
              Subscriptions automatically renew until you cancel.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If you make an Internal Purchase, you agree to pay the prices
          displayed to you for the Services you</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >ve selected as well as any sales or similar taxes that may be imposed
          on your payments (and as may change from time to time), and you
          authorize Introw to charge the payment method you provide (your
          &quot;Payment Method&quot;). Introw may correct any billing errors or
          mistakes even if we have already requested or received payment. If you
          initiate a chargeback or otherwise reverse a payment made with your
          Payment Method, Introw may terminate your account immediately in its
          sole discretion, on the basis that you have determined that you do not
          want a Introw subscription.
          <span class="GramE">In the event that</span> your chargeback or other
          payment reversal is overturned, please contact </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://help.match.com/hc/en-us/requests/new"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Customer Care</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If your Internal Purchase includes an automatically renewing
          subscription, your Payment Method will continue to be periodically
          charged for the subscription until you cancel. After your initial
          subscription commitment period, and again after any subsequent
          subscription period, your subscription will automatically continue for
          the price and <span class="GramE">time period</span> you agreed to
          when subscribing, until you cancel.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >To cancel a subscription, log in to the Website or App and go to the
          Settings tool. If you cancel a subscription, you may continue to use
          the cancelled service until the end of your then-current subscription
          term. The subscription will not be renewed when your then-current term
          expires.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You may edit your Payment Method information by using the Settings
          tool. If a payment is not successfully processed, due to expiration,
          insufficient funds, or otherwise, you remain responsible for any
          uncollected amounts and authorize us to continue billing the Payment
          Method, as it may be updated. This may result in a change to your
          payment billing dates.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >In addition, you authorize us to obtain updated or replacement
          expiration dates and card numbers for your credit or debit card as
          provided by your credit or debit card issuer. The terms of your
          payment will be based on your Payment Method and may be determined by
          agreements between you and the financial institution, credit card
          issuer, or other provider of your chosen Payment Method. Certain users
          may be entitled to request a refund. See Section 8d below for more
          information.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >8c. VIRTUAL ITEMS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Virtual items are non-refundable and subject to certain
              conditions.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >From time to time, you may have the opportunity to purchase a
          limited, personal, non-transferable, non-sublicensable, revocable
          license to use or access special limited-use features such as
          &quot;Boost&quot; (&quot;Virtual Item(s)&quot;) from Introw. You may
          only purchase Virtual Items from us or our authorized partners through
          our Services. Virtual Items represent a limited license right governed
          by this Agreement, and, except as otherwise prohibited by applicable
          law, no title or ownership in or to Virtual Items is being transferred
          or assigned to you. This Agreement should not be construed as a sale
          of any rights in Virtual Items.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Any Virtual Item balance shown in your account does not constitute a
          real-world balance or reflect any stored value, but instead
          constitutes a measurement of the extent of your license. Virtual Items
          do not incur fees for non-use; however, the license granted to you in
          Virtual Items will terminate in accordance with the terms of this
          Agreement, on the earlier of when Introw ceases providing our
          Services, or your account is otherwise closed or terminated.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw, in its sole discretion, reserves the right to charge fees for
          the right to access or use Virtual Items and/or may distribute Virtual
          Items with or without charge. Introw may manage, regulate, control,
          modify, or eliminate Virtual Items at any time, including taking
          actions that may impact the perceived value or purchase price, if
          applicable, of any Virtual Items. Introw shall have no liability to
          you or any third party
          <span class="GramE">in the event that</span> Introw exercises any such
          rights. The transfer of Virtual Items is prohibited, and you shall not
          sell, redeem, or otherwise transfer Virtual Items to any person or
          entity. Virtual Items may only be redeemed through our Services.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH OUR
          SERVICES ARE FINAL AND NON-REFUNDABLE. YOU ACKNOWLEDGE THAT Introw IS
          NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT
          RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN
          ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR
          INVOLUNTARY.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >8d. REFUNDS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Generally, all purchases are nonrefundable. Special terms apply
              in Arizona, California, Colorado, Connecticut, Illinois, Iowa,
              Minnesota, New York, North Carolina, Ohio, Rhode Island, and
              Wisconsin.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Generally, all purchases are final and nonrefundable, and there are
          no refunds or credits for partially used periods, except if the laws
          applicable in your jurisdiction provide for refunds.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="DE"
          style="
            font-family: 'Gill Sans Nova';
            color: #BCBEC0;
            mso-ansi-language: DE;
          "
          >FOR SUBSCRIBERS RESIDING IN ARIZONA, CALIFORNIA, COLORADO,
          CONNECTICUT, ILLINOIS, IOWA, MINNESOTA, NEW YORK, NORTH CAROLINA,
          OHIO, RHODE ISLAND, AND WISCONSIN:</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span class="None"
          ><
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Your Right to Cancel—You may cancel your subscription, without
              penalty or obligation, at any time prior to midnight of the third
              business day following the date you subscribed.</span
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          <span class="GramE">In the event that</span> you die before the end of
          your subscription period, your estate shall be entitled to a refund of
          that portion of any payment you had made for your subscription which
          is allocable to the period after your death. In the event that you
          become disabled (such that you are unable to use our Services) before
          the end of your subscription period, you shall be entitled to a refund
          of that portion of any payment you had made for your subscription
          which is allocable to the period after your disability by providing
          the Company notice in the same manner as you request a refund as
          described below.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Purchases of Virtual Items are FINAL AND NON-REFUNDABLE.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If any of the above apply to you and you subscribed using your Apple
          ID, your refund requests are handled by Apple, not Introw. To request
          a refund, please contact your External Service directly; for
          <span class="GramE">example</span> using your Apple device, go to
          Settings &gt; iTunes &amp; App Stores &gt; [click on your Apple ID]
          &gt; View Apple ID &gt; Purchase History. Find the transaction and
          select &quot;Report a Problem.&quot; You can also request a refund at
          https://getsupport.apple.com. For any other purchase, please contact
          Introw Customer Service with your order number (see your confirmation
          email) by mailing or delivering a signed and dated notice which states
          that you, the buyer, are canceling this Agreement, or words of similar
          effect. Please also include the email address or telephone number
          associated with your account along with your order number. This notice
          shall be sent to: Introw, Attn: Cancellations, P.O. Box 25472, Dallas,
          Texas 75225, USA (California and Ohio users may also email us by
          clicking </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/subhelp/"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >here</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          or send a facsimile to 214-853-4309).</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >8e. INSTALLMENT PLAN CONDITIONS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >If you agree to make a purchase pursuant to the installment plan
              option, your purchase will be subject to these additional terms
              and conditions, including around eligibility, payment, and
              cancellation.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >By selecting the option to pay in four payments and clicking
          subscribe, in addition to the terms set forth above, you agree to the
          following additional terms that will govern your installment plan
          purchase:</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo5;
        "
      >
        <i
          ><span
            lang="IT"
            style="
              font-family: Helvetica;
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
              mso-ansi-language: IT;
            "
            ><span style="mso-list: Ignore"
              >1.<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          ></i
        ><span class="None"
          ><i
            ><span
              lang="IT"
              style="
                font-family: 'Gill Sans Nova';
                color: #BCBEC0;
                mso-ansi-language: IT;
              "
              >Eligibility</span
            ></i
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. To be eligible, you must be a Introw member in good standing
          residing in the United States of America. This offer may not be
          available to every customer and may not be available for all Services
          Introw offers. Introw will not use a consumer credit report to
          determine your eligibility for this Agreement.</span
        ><span
          lang="IT"
          style="
            font-family: 'Gill Sans Nova';
            color: #BCBEC0;
            mso-ansi-language: IT;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo5;
        "
      >
        <i
          ><span
            lang="EN-US"
            style="
              font-family: Helvetica;
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><span style="mso-list: Ignore"
              >2.<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          ></i
        ><span class="None"
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Payment</span
            ></i
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. You authorize Introw to charge the Payment Method selected on a
          periodic basis (as determined when you register). You will be charged
          the full price of the qualifying product you selected spread equally
          over one initial payment due at the time of purchase and three
          subsequent payments (provided, however, that if the full price is not
          evenly divisible by four, your final payment amount may be smaller).
          The three subsequent payments will be charged in the increments you
          selected as part of your subscription plan. No interest or finance
          charges apply to this installment plan purchase. Any interest, finance
          charges or fees assessed by the issuer of your Payment Method may
          still apply. You are personally responsible for any applicable state,
          federal or other taxes that may be associated with your purchase of
          Services unless noted <span class="GramE">otherwise.You</span> can
          choose to prepay your next schedule payment or the full remaining
          balance at any time by contacting Customer Care.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo5;
        "
      >
        <i
          ><span
            lang="EN-US"
            style="
              font-family: Helvetica;
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><span style="mso-list: Ignore"
              >3.<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          ></i
        ><span class="None"
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Introw</span
            ></i
          ></span
        ><span dir="RTL"></span
        ><span class="None"
          ><i
            ><span
              lang="AR-SA"
              dir="RTL"
              style="
                font-family: 'Arial Unicode MS', sans-serif;
                mso-ascii-font-family: 'Gill Sans Nova';
                mso-hansi-font-family: 'Gill Sans Nova';
                color: #BCBEC0;
              "
              ><span dir="RTL"></span>’</span
            ></i
          ></span
        ><span class="None"
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >s right to Terminate</span
            ></i
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. If Introw is not able to charge any payment to your Payment Method,
          Introw reserves the right to pursue any remedy that is available to
          it, including the right to suspend or terminate your Introw
          subscription and/or Introw account. You agree that Introw and its
          affiliates have no liability related to the exercise of these
          remedies.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo5;
        "
      >
        <i
          ><span
            lang="EN-US"
            style="
              font-family: Helvetica;
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><span style="mso-list: Ignore"
              >4.<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          ></i
        ><span class="None"
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Cancellation Policy</span
            ></i
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. Except as otherwise set forth herein, your subscription purchased
          through your installment plan will continue until terminated,
          cancelled, or not renewed by you or Introw, as further described in
          this Agreement. If not terminated, cancelled, or not renewed, your
          Membership will continue to renew with installment payments, until you
          cancel or change your payment options, via your Account Settings. If
          you cancel a subscription, you may continue to use the cancelled
          service until the end of your then-current subscription term. The
          subscription will not be renewed when your then-current term expires.
          If you cancel your subscription prior to completing all payments due,
          unless otherwise required by applicable law, the remaining balance of
          the subscription will remain due and payable pursuant to the
          installment payment schedule you agreed to.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >9. ACCOUNT TERMINATION</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >If you no longer wish to use our Services, or if we terminate
              your account for any reason, here</span
            ></i
          ><
        ><span dir="RTL"></span
        ><
          ><i
            ><span
              lang="AR-SA"
              dir="RTL"
              style="
                font-family: 'Arial Unicode MS', sans-serif;
                mso-ascii-font-family: 'Gill Sans Nova';
                mso-hansi-font-family: 'Gill Sans Nova';
                color: #BCBEC0;
              "
              ><span dir="RTL"></span>’</span
            ></i
          ><
        ><
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >s what you need to know.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You can delete your account at any time by logging into the Website
          or App, going to &quot;Settings&quot; (the gear/pencil icon in the top
          right corner), and following the instructions to cancel your
          membership.
          <span class="None"
            ><
              >However, you will need to cancel / manage any External Service
              Purchases through your External Service Account (e.g., iTunes,
              Google Play) to avoid additional billing.<
            ></span
          ></span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw reserves the right to investigate and, if appropriate, suspend
          or terminate your account without a refund if Introw believes that you
          have violated these Terms, misused our Services, or behaved in a way
          that Introw regards as inappropriate or unlawful, on or off our
          Services. We reserve the right to make use of any personal,
          technological, legal, or other means available to enforce the Terms,
          at any time without liability and without the obligation to give you
          prior notice, including, but not limited to, preventing you from
          accessing the Services.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If your account is terminated by you or by Introw for any reason,
          these Terms continue and remain enforceable between you and Introw,
          and you will not be entitled to any refund for purchases made. Your
          information will be maintained and deleted in accordance with our </span
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Privacy Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >10. NO CRIMINAL BACKGROUND OR IDENTITY VERIFICATION CHECKS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Introw does not conduct criminal background or identity
              verification checks on its users. Use your best judgment when
              interacting with others and review our
            </span></i
          ><
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://help.match.com/hc/en-us/articles/6991442293787-Dating-Safety-Tips"
              ><
                ><i
                  ><span
                    style="
                      color: #BCBEC0;
                      mso-style-textfill-fill-color: #BCBEC0;
                      mso-style-textfill-fill-alpha: 100%;
                    "
                    ><span
                      style="
                        color: #BCBEC0;
                        mso-style-textfill-fill-color: #BCBEC0;
                        mso-style-textfill-fill-alpha: 100%;
                      "
                      >Safety Tips</span
                    ></span
                  ></i
                ><
              ></a
            ></span
          ></span
        ><
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span class="None"
          ><
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >YOU UNDERSTAND THAT Introw DOES NOT CONDUCT CRIMINAL BACKGROUND
              OR IDENTITY VERIFICATION CHECKS ON ITS USERS OR OTHERWISE INQUIRE
              INTO THE BACKGROUND OF ITS USERS.</span
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          Introw MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT,
          IDENTITY, INTENTIONS, LEGITIMACY, OR VERACITY OF USERS. Introw
          RESERVES THE RIGHT TO CONDUCT—AND YOU AUTHORIZE Introw TO CONDUCT—ANY
          CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER
          REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS, AND YOU
          AGREE THAT ANY INFORMATION YOU PROVIDE MAY BE USED FOR THAT PURPOSE.
          IF THE COMPANY DECIDES TO CONDUCT ANY SCREENING THROUGH A CONSUMER
          REPORTING AGENCY, YOU HEREBY AUTHORIZE THE COMPANY TO OBTAIN AND USE A
          CONSUMER REPORT ABOUT YOU TO DETERMINE YOUR ELIGIBILITY UNDER THESE
          TERMS.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS.
          SEX OFFENDER SCREENINGS AND OTHER TOOLS DO NOT GUARANTEE YOUR SAFETY
          AND ARE NOT A SUBSTITUTE FOR FOLLOWING THE </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://help.match.com/hc/en-us/articles/6991442293787-Dating-Safety-Tips"
              ><span
                lang="DE"
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                  mso-ansi-language: DE;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >SAFETY TIPS</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          AND OTHER SENSIBLE SAFETY PRECAUTIONS. ALWAYS USE YOUR BEST JUDGMENT
          AND TAKE APPROPRIATE SAFETY PRECAUTIONS WHEN COMMUNICATING WITH OR
          MEETING NEW PEOPLE. COMMUNICATIONS RECEIVED THROUGH THE SERVICE,
          INCLUDING AUTOMATIC NOTIFICATIONS SENT BY Introw, MAY RESULT FROM
          USERS ENGAGING WITH THE SERVICE FOR IMPROPER PURPOSES, INCLUDING
          FRAUD, ABUSE, HARASSMENT, OR OTHER SUCH IMPROPER BEHAVIOR.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Though Introw strives to encourage a respectful user experience, it
          is not responsible for the conduct of any user on or off the Service.
          You agree to use caution in all interactions with other users,
          particularly if you decide to communicate off the Service or meet in
          person.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="DE"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: DE;
            "
            class="privacySecHead"
            >11. DISCLAIMER</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Services are provided &quot;as is&quot; and we do not make, and
          cannot make, any representations about the content or features of our
          Services.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw PROVIDES OUR SERVICES ON AN &quot;AS IS&quot; AND &quot;AS
          AVAILABLE&quot; BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW,
          GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY
          OR OTHERWISE WITH RESPECT TO OUR SERVICES (INCLUDING ALL CONTENT
          CONTAINED THEREIN), INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
          WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE OR NON-INFRINGEMENT. Introw DOES NOT REPRESENT OR
          WARRANT THAT (A) OUR SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR
          FREE, (B) ANY DEFECTS OR ERRORS IN OUR SERVICES WILL BE DISCOVERED OR
          CORRECTED, OR (C) THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR
          THROUGH OUR SERVICES WILL BE ACCURATE OR APPROPRIATE FOR YOUR
          PURPOSES. FURTHERMORE, Introw MAKES NO GUARANTEES AS TO THE NUMBER OF
          ACTIVE USERS AT ANY TIME; USERS</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >ABILITY OR DESIRE TO COMMUNICATE WITH OR MEET YOU, OR THE ULTIMATE
          COMPATIBILITY WITH OR CONDUCT BY USERS YOU MEET THROUGH THE
          SERVICES.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="DE"
          style="
            font-family: 'Gill Sans Nova';
            color: #BCBEC0;
            mso-ansi-language: DE;
          "
          >Introw ASSUMES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER
          USER OR THIRD PARTY POSTS, SENDS, OR RECEIVES THROUGH OUR SERVICES;
          NOR DOES Introw ASSUME ANY RESPONSIBILITY FOR THE IDENTITY,
          INTENTIONS, LEGITIMACY, OR VERACITY OF ANY USERS WITH WHOM YOU MAY
          COMMUNICATE WITH THROUGH Introw. ANY MATERIAL DOWNLOADED OR OTHERWISE
          OBTAINED THROUGH THE USE OF OUR SERVICES IS ACCESSED AT YOUR OWN
          DISCRETION AND RISK. Introw IS NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR
          COMPUTER HARDWARE, COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR TECHNOLOGY
          INCLUDING, BUT WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR
          FROM ANY VIRUS, BUGS, TAMPERING, HACKING, FRAUD, ERROR, OMISSION,
          INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
          LINE OR NETWORK FAILURE, OR ANY OTHER TECHNICAL OR OTHER DISRUPTION OR
          MALFUNCTION.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >12. DIGITAL MILLENNIUM COPYRIGHT ACT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >We take copyright infringement very seriously. We ask you to help
              us to ensure we address it promptly and effectively.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw has adopted the following policy towards copyright
          infringement in accordance with the Digital Millennium Copyright Act
          (the &quot;DMCA&quot;). If you believe any Member Content or Our
          Content infringes upon your intellectual property rights, please
          submit a notification alleging such infringement (&quot;DMCA Takedown
          Notice&quot;) including the following:</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo6;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >1.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly
          <span class="GramE">infringed;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >2.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Identification of the copyrighted work claimed to have been
          infringed, or, if multiple copyrighted works at a single online site
          are covered by a single notification, a representative list of such
          <span class="GramE">works;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >3.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Identification of the material claimed to be infringing or to be the
          subject of infringing activity and that is to be removed or access
          disabled and information reasonably sufficient to permit the service
          provider to locate the <span class="GramE">material;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >4.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Information reasonably sufficient to permit the service provider to
          contact you, such as an address, telephone number, and, if available,
          an electronic <span class="GramE">mail;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >5.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >A statement that you have a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law; and<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l1 level1 lfo2;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >6.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >A statement that, under penalty of perjury, the information in the
          notification is accurate and you are authorized to act on behalf of
          the owner of the exclusive right that is allegedly infringed.<o:p
          ></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Any DMCA Takedown Notices should be sent to copyright@introw.com, by
          phone to 214-576-3272 or via mail to the following address: Copyright
          Compliance Department c/o Introw Group Legal, 8750 N. Central
          Expressway, Dallas, Texas 75231.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw will terminate the accounts of repeat infringers.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >13. ADS AND THIRD-PARTY CONTENT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Like many subscription-based services, there are ads on our
              websites.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Our Services may contain advertisements and promotions offered by
          third parties and links to other websites or resources. Introw may
          also provide non-commercial links or references to third parties
          within its content. Introw is not responsible for the availability (or
          lack of availability) of any external websites or resources or their
          content. Furthermore, Introw is not responsible for, and does not
          endorse, any products or services that may be offered by third-party
          websites or resources. If you choose to interact with the third
          parties made available through our Services, such party</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s terms will govern their relationship with you. Introw is not
          responsible or liable for such third parties</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >terms or actions.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >14. LIMITATION OF LIABILITY</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Introw</span
            ></i
          ><
        ><span dir="RTL"></span
        ><
          ><i
            ><span
              lang="AR-SA"
              dir="RTL"
              style="
                font-family: 'Arial Unicode MS', sans-serif;
                mso-ascii-font-family: 'Gill Sans Nova';
                mso-hansi-font-family: 'Gill Sans Nova';
                color: #BCBEC0;
              "
              ><span dir="RTL"></span>’</span
            ></i
          ><
        ><
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >s liability is limited to the maximum extent allowed by
              applicable law.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          Introw, ITS AFFILIATES, EMPLOYEES, LICENSORS, OR SERVICE PROVIDERS BE
          LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
          SPECIAL, PUNITIVE, FIXED, OR ENHANCED DAMAGES, INCLUDING, WITHOUT
          LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY,
          OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES,
          RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR
          USE THE SERVICES, (II) THE CONDUCT OR CONTENT OF ANY USERS OR THIRD
          PARTIES ON OR THROUGH ANY OF OUR AFFILIATES</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >SERVICES OR IN CONNECTION WITH THE SERVICES; OR (III) ANY
          UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF
          Introw HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE
          FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICES
          EXCEED THE AMOUNT PAID, IF ANY, BY YOU TO Introw FOR THE SERVICES
          DURING THE TWENTY-FOUR (24) MONTH PERIOD IMMEDIATELY PRECEDING THE
          DATE THAT YOU FIRST FILE A LAWSUIT, ARBITRATION OR ANY OTHER LEGAL
          PROCEEDING AGAINST Introw, WHETHER STATUTORY, IN LAW OR IN EQUITY, IN
          ANY TRIBUNAL. THE DAMAGES LIMITATION SET FORTH IN THE IMMEDIATELY
          PRECEDING SENTENCE APPLIES (I) REGARDLESS OF THE GROUND UPON WHICH
          LIABILITY IS BASED (WHETHER DEFAULT, CONTRACT, TORT, STATUTE, OR
          OTHERWISE), (II) IRRESPECTIVE OF THE TYPE OF BREACH OF RIGHTS,
          PRIVILEGES, OR OBLIGATIONS, AND (III) WITH RESPECT TO ALL EVENTS, THE
          SERVICE, AND THIS AGREEMENT.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS SECTION 14
          SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL WITH
          RESPECT TO THEIR ESSENTIAL PURPOSE.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN
          THIS SECTION MAY NOT APPLY TO YOU.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >15. DISPUTE RESOLUTION SECTION</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >In the unlikely event that we have a legal dispute, here is how
              the Parties agree to proceed, except
              <span class="GramE">where</span> prohibited by applicable
              law.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Any Subsection in this Dispute Resolution Section that is prohibited
          by law shall not apply to the users residing in that jurisdiction,
          including Subsections 15b, 15c, 15d, and 15e, which shall not apply to
          users residing within the European Union, European Economic Area, the
          United Kingdom, or Switzerland.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="DE"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: DE;
            "
            class="privacySecHead"
            >15a. INFORMAL DISPUTE RESOLUTION PROCESS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If you are dissatisfied with our Services for any reason, please
          contact Introw Customer Service first so we can try to resolve your
          concerns without the need of outside assistance. If you choose to
          pursue a dispute, claim or controversy against Introw, these terms
          will apply. For purposes of this Dispute Resolution Process and
          Arbitration Procedures set forth in Section 15, &quot;Introw&quot;
          shall include our affiliates, employees, licensors, and service
          providers.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Introw values its relationship with you and appreciates the mutual
          benefit realized from informally resolving Disputes (as defined
          below). Before formally pursuing a Dispute in arbitration or small
          claims court, you agree to first send a detailed notice
          (&quot;Notice&quot;) to Introw Group Legal, P.O. Box 25458, Dallas,
          Texas 75225, USA. If Introw has a Dispute with you, Introw agrees to
          first send a Notice to you at your most recent email address on file
          with us, or if no email address is on file, other contact information
          associated with your account. Your Notice must contain all of the
          following information: (1) your full name; (2) information that
          enables Introw to identify your account, including a picture or
          screenshot of your profile, your address, mobile phone number, email
          address, and date of birth you used to register your account if any;
          and (3) a detailed description of your Dispute, including the nature
          and factual basis of your claim(s) and the relief you are seeking with
          a corresponding calculation of your alleged damages (if any). You must
          personally sign this Notice for it to be effective. Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Notice must likewise set forth a detailed description of its
          Dispute, which shall include the nature and factual basis of its
          claim(s) and the relief it is seeking, with a corresponding
          calculation of our damages (if any). You and Introw agree to then
          negotiate in good faith
          <span class="GramE">in an effort to</span> resolve the Dispute. As
          part of these good faith negotiations, if Introw requests a telephone
          conference with you to discuss your Dispute, you agree to personally
          participate, with your attorney if you</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >re represented by counsel. Likewise, if you request a telephone
          conference to discuss Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Dispute with you, Introw agrees to have one representative
          participate. This informal process should lead to a resolution of the
          Dispute. However, if the Dispute is not resolved within 60 days after
          receipt of a fully completed Notice and the Parties have not otherwise
          mutually agreed to an extension of this informal dispute resolution
          <span class="GramE">time period</span>, you or Introw may initiate an
          arbitration (subject to a Party</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s right to elect small claims court as provided below).</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Completion of this informal dispute resolution is a condition
          precedent to filing any demand for arbitration or small claims court
          action. Failure to do so is a breach of this Agreement. The statute of
          limitations and any filing fee deadlines will be tolled while you and
          Introw engage in this informal dispute resolution process. Unless
          prohibited by applicable law, the arbitration provider, National
          Arbitration and Mediation (&quot;NAM&quot;), shall not accept or
          administer any demand for arbitration and shall administratively close
          any arbitration unless the Party bringing such demand for arbitration
          can certify in writing that the terms and conditions of this informal
          dispute resolution process were fully satisfied. A court of competent
          jurisdiction shall have authority to enforce this provision and to
          enjoin any arbitration proceeding or small claims court action.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >15b. INDIVIDUAL RELIEF: CLASS ACTION AND JURY TRIAL WAIVER</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >TO THE FULLEST EXTENT ALLOWABLE BY LAW, YOU AND Introw EACH WAIVE
            THE RIGHT TO A JURY TRIAL AND THE RIGHT TO LITIGATE DISPUTES IN
            COURT IN FAVOR OF INDIVIDUAL ARBITRATION (EXCEPT FOR SMALL CLAIMS
            COURT AS PROVIDED ABOVE). YOU AND Introw EACH WAIVE THE RIGHT TO
            FILE OR PARTICIPATE IN A CLASS ACTION AGAINST THE OTHER OR OTHERWISE
            TO SEEK RELIEF ON A CLASS BASIS, INCLUDING ANY CURRENTLY PENDING
            ACTIONS AGAINST Introw. TO THE FULLEST EXTENT ALLOWABLE BY LAW,
            THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE ARBITRATED
            OR LITIGATED ON A CLASS, COLLECTIVE, REPRESENTATIVE, CONSOLIDATED,
            OR PRIVATE ATTORNEY GENERAL BASIS. THE ARBITRATOR CAN AWARD THE SAME
            RELIEF AVAILABLE IN COURT PROVIDED THAT THE ARBITRATOR MAY ONLY
            AWARD FINAL RELIEF (INCLUDING INJUNCTIVE OR DECLARATORY RELIEF) IN
            FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
            NECESSARY TO PROVIDE FINAL RELIEF WARRANTED BY THAT INDIVIDUAL
            PARTY</span
          ><
        ><span dir="RTL"></span
        ><
          ><span
            lang="AR-SA"
            dir="RTL"
            style="
              font-family: 'Arial Unicode MS', sans-serif;
              mso-ascii-font-family: 'Gill Sans Nova';
              mso-hansi-font-family: 'Gill Sans Nova';
              color: #BCBEC0;
            "
            ><span dir="RTL"></span>’</span
          ><
        ><
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >S CLAIM. THE ARBITRATOR MAY NOT AWARD FINAL RELIEF FOR, AGAINST, OR
            ON BEHALF OF ANYONE WHO IS NOT A PARTY TO THE ARBITRATION ON A
            CLASS, COLLECTIVE, REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL
            BASIS. IF A COURT DETERMINES THAT ANY OF THESE PROHIBITIONS IN THIS
            PARAGRAPH ARE UNENFORCEABLE AS TO A PARTICULAR CLAIM OR REQUEST FOR
            RELIEF (SUCH AS A REQUEST FOR PUBLIC INJUNCTIVE RELIEF), AND ALL
            APPEALS OF THAT DECISION ARE EXHAUSTED OR THE DECISION IS OTHERWISE
            FINAL, THEN YOU AND Introw AGREE THAT THAT PARTICULAR CLAIM OR
            REQUEST FOR RELIEF SHALL PROCEED IN COURT BUT SHALL BE STAYED
            PENDING INDIVIDUAL ARBITRATION OF THE REMAINING CLAIMS FOR RELIEF
            THAT YOU HAVE BROUGHT. IF THIS SPECIFIC PARAGRAPH IS FOUND TO BE
            UNENFORCEABLE, THEN THE ENTIRETY OF THIS ARBITRATION PROVISION
            (EXCEPT FOR THE JURY TRIAL WAIVER AND THE INFORMAL DISPUTE
            RESOLUTION PROCESS) SHALL BE NULL AND VOID. THIS PARAGRAPH IS AN
            ESSENTIAL PART OF THIS ARBITRATION AGREEMENT.</span
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >15c. DISPUTE RESOLUTION THROUGH ARBITRATION OR SMALL CLAIMS
            COURT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Any dispute, claim, or controversy between you and Introw (that is
          not resolved informally by Introw Customer Service or as provided
          under subsection 15a above) that arises from or relates in any way to
          this Agreement (including any alleged breach of this Agreement), the
          Services, or our relationship with you (collectively,
          &quot;Dispute&quot;), shall be exclusively resolved through BINDING
          INDIVIDUAL ARBITRATION except as specifically provided otherwise in
          this Dispute Resolution Section. &quot;Dispute&quot; as used in this
          Agreement shall have the broadest possible meaning and include claims
          that arose before the existence of this or any prior Agreement and
          claims that arise during the term of this Agreement or after the
          termination of this Agreement. Notwithstanding the foregoing, either
          you or Introw may elect to have an individual claim heard in small
          claims court. If the request to proceed in small claims court is made
          after an arbitration has been initiated but before an arbitrator has
          been appointed, such arbitration shall be administratively closed. Any
          controversy over the small claims court</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s jurisdiction shall be determined by the small claims court. All
          other issues (except as otherwise provided herein) are exclusively for
          the Arbitrator to decide, including but not limited to scope and
          enforceability of this Dispute Resolution Section, as well as any
          request to proceed in small claims court that is made after an
          arbitrator has been appointed. If you or Introw challenges the small
          claims court election in your Dispute, and a court of competent
          jurisdiction determines that the small claims court election is
          unenforceable, then such election shall be severed from this Agreement
          as to your Dispute. However, such court determination shall not be
          considered or deemed binding with respect to Introw</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s other contracting parties.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Any court proceeding to enforce this Dispute Resolution Section 15,
          including any proceeding to confirm, modify, or vacate an arbitration
          award, must be commenced in accordance with Section 17. In the event
          Dispute Resolution Section 15 is for any reason held to be
          unenforceable, any litigation against Introw (except for small claims
          court actions) may be commenced only in the federal or state courts
          located in Dallas County, Texas. You hereby irrevocably consent to
          those courts</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >exercise of personal jurisdiction over you for such purposes and
          waive any claim that such courts constitute an inconvenient
          forum.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >15d. INDIVIDUAL ARBITRATION AND MASS ARBITRATION PROTOCOLS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >This subsection 15d applies to Disputes that are submitted to NAM
          after fully completing the informal Notice and Dispute resolution
          process described in subsection 15a above and when no small claims
          court election is made by either Party. Any arbitration between you
          and Introw shall be administered by NAM in accordance with NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s operative Comprehensive Dispute Resolution Rules and Procedures
          (the &quot;NAM Rules&quot;) in effect at the time any demand for
          arbitration is filed with NAM, as modified by this Dispute Resolution
          Section 15. For a copy of the NAM Rules, please visit </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.namadr.com/resources/rules-fees-forms"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >https://www.namadr.com/resources/rules-fees-forms</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          or contact NAM at NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s National Processing Center at 990 Stewart Avenue, 1st Floor, Garden
          City, NY 11530 and email address commercial@namadr.com. If NAM is
          unable or unwilling to perform its duties under this Agreement, the
          parties shall mutually agree on an alternative administrator that will
          replace NAM and assume NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s role consistent with this Agreement. If the parties are unable to
          agree, they will petition a court of competent jurisdiction to appoint
          an administrator that will assume NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s duties under this Agreement.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >The Parties agree that the following procedures will apply to any
          Arbitrations initiated under this Dispute Resolution Section:</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >1.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Commencing an Arbitration</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – To initiate an arbitration, you or Introw shall send to NAM a demand
          for arbitration (&quot;Demand for Arbitration&quot;) that describes
          the claim(s) and request for relief in detail, consistent with the
          requirements in this Agreement and NAM Rules. If you send a Demand for
          Arbitration, you shall also send it to Introw at Introw Group Legal,
          Sag Harbor, NY 11963, USA, within 10 days of delivery of the Demand
          for Arbitration to NAM. If Introw sends a Demand for Arbitration, we
          will also send it to your mailing address on file with us within the
          same 10-day period. If your mailing address is unavailable, we will
          send it to your email address on file, or if no email address is on
          file, other contact information associated with your account. The
          arbitration provider shall not accept or administer any demand for
          arbitration and shall administratively close any such demand for
          arbitration that fails to certify in writing that the Party meets the
          requirements of Dispute Resolution Section 15 or if either Party
          elects small claims court as set forth above.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="NL"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
            mso-ansi-language: NL;
          "
          ><span style="mso-list: Ignore"
            >2.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="NL"
                style="
                  font-family: 'Gill Sans Nova';
                  color: #BCBEC0;
                  mso-ansi-language: NL;
                "
                >Fees</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – The payment of all fees shall be governed by the NAM Rules, except
          to the extent that the case is a part of a Mass Filing (as defined
          below) or the NAM fees and costs (including Arbitrator fees) paid by
          either Party are reallocated upon order of the Arbitrator following a
          determination that (a) either Party breached Section 15 of this
          Agreement, (b) such reallocation is called for under this Agreement,
          or (c) reallocation is otherwise permitted under applicable law. Upon
          a showing to Introw of your financial hardship we will consider a good
          faith request made by you to pay your portion of the applicable
          consumer portion of the filing fee. Introw is committed to ensuring
          that arbitration costs to consumers do not serve as a barrier to the
          adjudication of disputes. If Introw initiates an arbitration against
          you, we shall pay all fees.</span
        ><span
          lang="NL"
          style="
            font-family: 'Gill Sans Nova';
            color: #BCBEC0;
            mso-ansi-language: NL;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="DE"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
            mso-ansi-language: DE;
          "
          ><span style="mso-list: Ignore"
            >3.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="DE"
                style="
                  font-family: 'Gill Sans Nova';
                  color: #BCBEC0;
                  mso-ansi-language: DE;
                "
                >The Arbitrator</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – The arbitration shall be conducted by a single, neutral (the
          &quot;Claim Arbitrator&quot;), as assisted by any Process Arbitrator
          appointed under NAM Rules. (The term &quot;Arbitrator&quot; applies to
          both the Claim Arbitrator and the Process Arbitrator). If a hearing is
          elected by either Party, the Arbitrator shall be in or close to the
          location in which you reside. The Arbitrator is bound by and shall
          adhere to this Agreement. In the event NAM Rules conflict with this
          Agreement, the terms of this Agreement shall control. If the
          Arbitrator determines that strict application of any term of Section
          15 of this Agreement (except for the small claims election, which
          shall be determined by the small claims court) would result in a
          fundamentally unfair arbitration (the &quot;Unfair Term&quot;), then
          the Arbitrator shall have authority to modify the Unfair Term to the
          extent necessary to ensure a fundamentally fair arbitration that is
          consistent with the Terms of Use (the &quot;Modified Term&quot;). In
          determining the substance of a Modified Term, the Arbitrator shall
          select a term that comes closest to expressing the intention of the
          Unfair Term.</span
        ><span
          lang="DE"
          style="
            font-family: 'Gill Sans Nova';
            color: #BCBEC0;
            mso-ansi-language: DE;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="FR"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
            mso-ansi-language: FR;
          "
          ><span style="mso-list: Ignore"
            >4.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="FR"
                style="
                  font-family: 'Gill Sans Nova';
                  color: #BCBEC0;
                  mso-ansi-language: FR;
                "
                >Dispositive Motions</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – The Parties agree that the Claim Arbitrator shall have the authority
          to consider dispositive motions without an oral evidentiary hearing.
          Dispositive motions may be requested under the following
          circumstances: (a) within 30 days after the Claim Arbitrator</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s appointment, a Party may request to file a dispositive motion based
          upon the pleadings; and (b) no later than 30 days prior to the
          evidentiary hearing, a Party may request to file a dispositive motion
          for summary judgment based upon the Parties</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’ </span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >pleadings and the evidence submitted.</span
        ><span
          lang="FR"
          style="
            font-family: 'Gill Sans Nova';
            color: #BCBEC0;
            mso-ansi-language: FR;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >5.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Discovery</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          –Each Party may (a) serve up to five requests for relevant,
          non-privileged documents from the other Party; and (b) request that
          the other Party provide verified responses to no more than 5 relevant
          interrogatories (including subparts). Unless both Parties agree
          otherwise, no other forms of discovery (including depositions) may be
          utilized. Any such discovery requests must be served on the other
          Party within 21 days after the Claim Arbitrator</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s appointment. The responding Party shall provide the requesting
          Party with all responsive, non-privileged documents, responses signed
          by the Party themselves to the requested interrogatories, and/or any
          objections to the requests within 30 days after receipt of the
          requests, or, in the event of an objection to any discovery request,
          30 days after the Claim Arbitrator resolves the dispute. In the event
          <span class="GramE">either Party</span> requests that the Claim
          Arbitrator consider a dispositive motion on the pleadings, such
          written discovery response deadlines shall be extended until 30 days
          following the Claim Arbitrator</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s final decision on such dispositive motion. Any disputes about
          discovery or requests for extensions shall be submitted promptly to
          the Claim Arbitrator for resolution. In ruling on any discovery
          dispute or extension request, the Claim Arbitrator shall take into
          consideration the nature, amount, and scope of the underlying
          arbitration claim, the cost and other effort that would be involved in
          providing the requested discovery, the case schedule, and whether the
          requested discovery is necessary for the adequate preparation of a
          claim or defense.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >6.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Confidentiality</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – Upon either Party</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s request, the Arbitrator will issue an order requiring that
          confidential information of either Party disclosed during the
          arbitration (whether in documents or orally) may not be used or
          disclosed except in connection with the arbitration or a proceeding to
          enforce the arbitration award and that any permitted court filing of
          confidential information must be done under seal.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >7.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Arbitration Hearing</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – You and Introw are entitled to a fair evidentiary hearing (<span
            class="GramE"
            >i.e.</span
          >
          trial) before the Claim Arbitrator. Arbitration proceedings are
          usually simpler, less costly, and more streamlined than trials and
          other judicial proceedings. The Parties agree to waive all oral
          hearings and instead submit all disputes to the Claim Arbitrator for
          an award based on written submissions and other evidence as the
          Parties may <span class="GramE">agree, unless</span> a Party requests
          an oral hearing within 10 days after the Respondent files a response.
          If an oral evidentiary hearing is requested, both Parties must be
          personally present at the hearing, regardless of whether either Party
          has retained counsel. Both Parties must personally attend the hearing.
          <span class="GramE"
            >Either Party<span dir="RTL"></span
            ><span
              lang="AR-SA"
              dir="RTL"
              style="
                font-family: 'Arial Unicode MS', sans-serif;
                mso-ascii-font-family: 'Gill Sans Nova';
                mso-hansi-font-family: 'Gill Sans Nova';
              "
              ><span dir="RTL"></span>’</span
            >s</span
          >
          failure to personally attend the hearing, without a continuance
          ordered by the Claim Arbitrator for good cause, will result in a
          default judgment taken against that Party.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >8.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Arbitration Award</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – Regardless of the format of the arbitration, the Claim Arbitrator
          shall provide a reasoned decision, in writing within 30 days after the
          hearing or, if no hearing is held, within 30 days after any rebuttal
          or supplemental statements are due. The decision must clearly specify
          the relief, if any, awarded and contain a brief statement of the
          reasons for the award. The arbitration award is binding only between
          you and Introw and will not have any preclusive effect in another
          arbitration or proceeding that involves a different Party. The Claim
          Arbitrator may, however, choose to consider rulings from other
          arbitrations involving a different Party. The Arbitrator may award
          fees and costs as provided by the NAM Rules or to the extent such fees
          and costs could be awarded in court. This includes but is not limited
          to the ability of the Arbitrator to award fees and costs if the
          Arbitrator determines that a claim or defense is frivolous or was
          brought for an improper purpose, for the purpose of harassment, or in
          bad faith.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >9.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Offer of Settlement</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – The Respondent may, but is not obligated to, make a written
          settlement offer to the opposing Party any time before the evidentiary
          hearing or, if a dispositive motion is permitted, prior to the
          dispositive motion being granted. The amount or terms of any
          settlement offer may not be disclosed to the Claim Arbitrator until
          after the Claim Arbitrator issues an award on the claim. If the award
          is issued in the opposing Party</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="PT"
          style="
            font-family: 'Gill Sans Nova';
            color: #BCBEC0;
            mso-ansi-language: PT;
          "
          >s favor and is less than the Respondent</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s settlement <span class="GramE">offer</span> or if the award is in
          the Respondent</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s favor, the opposing Party must pay the Respondent</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s costs incurred after the offer was made, including any
          attorney</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s fees. If any applicable statute or caselaw prohibits the flipping
          of costs incurred in the arbitration, then the offer in this provision
          shall serve to cease the accumulation of any costs that claimant may
          be entitled to for the cause of action under which it is suing.<o:p
          ></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l0 level1 lfo8;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >10.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><u
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Mass Filing</span
              ></u
            ><
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
        >
          – If, at any time, 25 or more similar demands for arbitration are
          asserted against Introw or related parties by the same or coordinated
          counsel or entities (&quot;Mass Filing&quot;), consistent with the
          definition and criteria of Mass Filings set forth in the NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Mass Filing Supplemental Dispute Resolution Rules and Procedures
          (&quot;NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Mass Filing Rules&quot;, available at </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.namadr.com/resources/rules-fees-forms/"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >https://www.namadr.com/resources/rules-fees-forms/</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >&quot;), the additional protocols set forth below shall apply.<o:p
          ></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 72pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level2 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
            position: relative;
            top: 1pt;
            mso-text-raise: -1pt;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >If you or your counsel file a Demand for Arbitration that fits within
          the definition of Mass Filing referred to above, then you agree that
          your Demand for Arbitration shall be subject to the additional
          protocols set forth in this Mass Filing subsection. You also
          acknowledge that the adjudication of your Dispute might be
          <span class="GramE">delayed</span> and that any applicable statute of
          limitations shall be tolled from the time at which the first cases are
          chosen to proceed until your case is chosen for a bellwether
          proceeding.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 72pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level2 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
            position: relative;
            top: 1pt;
            mso-text-raise: -1pt;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Mass Filing Rules shall apply if your Dispute is deemed by NAM, in
          its sole discretion pursuant to its Rules and this Dispute Resolution
          Section, to be part of a Mass Filing. Such election for NAM</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Mass Filing Rules and related fee schedule must be made by either
          you or Introw in writing and submitted to NAM and all Parties.<o:p
          ></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 72pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level2 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
            position: relative;
            top: 1pt;
            mso-text-raise: -1pt;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span class="None"
          ><
            ><i
              ><span
                lang="EN-US"
                style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
                >Bellwether Proceedings</span
              ></i
            ><
          ></span
        ><
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >. Bellwether proceedings are encouraged by courts and arbitration
            administrators when there are multiple disputes involving similar
            claims against the same or related parties. Counsel for the Mass
            Filings claimants (including you) and counsel for Introw shall each
            select 15 Demands for Arbitration (30 total), and no more than 30
            arbitrations shall be filed, processed, adjudicated, or pending at
            the same time, with each of the 30 individual arbitrations presided
            over by a different Claim Arbitrator, in a first set of bellwether
            proceedings. During this time, no other Demands for arbitration that
            are part of the Mass Filings may be filed, processed, adjudicated,
            or pending. If the Parties are unable to resolve the remaining
            Demands for Arbitration after the first set of bellwether
            proceedings are arbitrated or otherwise resolved, then counsel for
            the Claimants and counsel for Introw shall each select an additional
            15 Demands for Arbitration (30) total to be filed, processed, and
            adjudicated as individual arbitrations, with each of the 30
            arbitrations presided over by a different Claim Arbitrator, in a
            second set of bellwether proceedings. During this time, no other
            Demands for Arbitration that are part of the Mass Filings may be
            filed, processed, or adjudicated. This staged process of bellwether
            proceedings, with each set including 30 Demands for Arbitration
            adjudicated on an individual basis, shall continue until each Demand
            included in the Mass Filings (including your Demand for Arbitration)
            is adjudicated or otherwise resolved. Fees associated with a Demand
            for Arbitration included in the Mass Filings, including fees owed by
            Introw and the claimants (including you), shall only be due after
            your Demand for Arbitration is chosen as part of a set of bellwether
            proceedings and therefore properly designated for filing,
            processing, and adjudication. Any applicable statute of limitations
            shall be tolled beginning when you initiate the informal dispute
            resolution process set forth in subsection 15a of the Agreement, and
            if the first Mass Filings</span
          ><
        ><span dir="RTL"></span
        ><
          ><span
            lang="AR-SA"
            dir="RTL"
            style="
              font-family: 'Arial Unicode MS', sans-serif;
              mso-ascii-font-family: 'Gill Sans Nova';
              mso-hansi-font-family: 'Gill Sans Nova';
              color: #BCBEC0;
            "
            ><span dir="RTL"></span>’
          </span><
        ><
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >Demands for Arbitration are chosen for the initial set of
            bellwether proceedings have been filed, your claims will remain
            tolled until your Demand for Arbitration is decided, withdrawn, or
            is settled. A court of competent jurisdiction located in a venue
            allowed under Section 17 of the Agreement shall have the power to
            enforce this subsection.<o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 72pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level2 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
            position: relative;
            top: 1pt;
            mso-text-raise: -1pt;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You and Introw agree that we each value the integrity and efficiency
          of the arbitration and small claims court process and wish to employ
          the process for the fair resolution of genuine and sincere disputes
          between us. You and Introw acknowledge and agree to act in good faith
          to ensure the fair resolution of genuine and sincere Disputes. The
          Parties further agree that application of these Mass Filings
          procedures have been reasonably designed to result in an efficient and
          fair adjudication of such cases.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "            
        class="privacySecHead"
            >15e. FUTURE CHANGES AND RETROACTIVE APPLICATION</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >This Dispute Resolution Section 15 applies to all Disputes between
          the Parties, including for any claims that accrued against you or
          Introw prior to the time of your consent to this Agreement and to any
          claims that accrue against you or Introw after your consent to this
          Agreement. Notwithstanding any provision in this Agreement to the
          contrary, you may elect to opt out of the retroactive application of
          this Dispute Resolution Section 15 as to claims that have accrued
          against you or against Introw prior to the time of your consent to
          this Agreement. You may opt out by sending us written notice, within
          30 days of the time you consent to this Agreement, to the following
          email address: </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="mailto:optout@match.com"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >optout@introw.com</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. Please do not direct any customer support inquiries </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="mailto:optout@match.com"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >optout@introw.com</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, as they will not be addressed; such inquiries should be directed to </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/contactus.aspx"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >customer support</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >. You must include information sufficient to identify your
          account(s), such as the email address or phone number associated with
          your account(s<span class="GramE">), and</span> should include a
          statement that you are opting out of the retroactive application of
          this Dispute Resolution Section 15. Please note: if you opt out of the
          retroactive application of this Dispute Resolution Section 15, you
          will still be subject to and bound by any Dispute Resolution Sections
          and Arbitration Procedures you previously agreed to, including any
          arbitration provisions, class action waivers, and retroactive
          application sections. Also, regardless of whether you opt out of the
          retroactive application of these changes, the Parties will resolve any
          claims that accrue against you or Introw after your consent to this
          Agreement in accordance with this Dispute Resolution Section.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="DE"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: DE;
            "
            class="privacySecHead"
            >16. GOVERNING LAW</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >Texas law and the Federal Arbitration Act will apply to any
              Dispute (except <span class="GramE">where</span> prohibited by
              law).</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >To the fullest extent allowable by law, the laws of Texas, U.S.A.,
          without regard to its conflict of laws rules, shall apply to any
          Dispute arising out of or relating to this Agreement or our Services.
          Notwithstanding the foregoing, the Dispute Resolution Process set
          forth in Section 15 shall be governed by the Federal Arbitration
          Act.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >17. VENUE/FORUM SELECTION</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >To the fullest extent allowable by law, any claims that are not
              arbitrated for any reason must be litigated in Dallas County,
              Texas (except for claims filed in small claims court).</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Except where prohibited by law and except for claims that are heard
          in a small claims court as set forth in Section 15, any claims arising
          out of or relating to this Agreement, to our Services, or to your
          relationship with Introw that for whatever reason are not required to
          be arbitrated or filed in small claims court, will be litigated
          exclusively in the federal or state courts located in Dallas County,
          Texas, U.S.A. You and Introw consent to the exercise of personal
          jurisdiction of courts in the State of Texas and waive any claim that
          such courts constitute an inconvenient forum.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >18. INDEMNITY BY YOU</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >You agree to indemnify Introw if a claim is made against Introw
              due to your actions.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You agree, to the extent permitted under applicable law, to
          indemnify, defend, and hold harmless Introw, our affiliates, and their
          and our respective officers, directors, agents, and employees from and
          against any and all complaints, demands, claims, damages, losses,
          costs, liabilities, and expenses, including attorney</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s fees, due to, arising out of, or relating in any way to your access
          to or use of our Services, your Content, your conduct toward other
          users, or your breach of this Agreement.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >19. ACCEPTANCE OF TERMS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >By using our Services, you accept the Terms of this
              Agreement.</span
            ></i
          ><
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >By using our Services, whether through a mobile device, mobile
          application, or computer, you agree to be bound by (i) these Terms,
          which we may amend from time to time, (ii) our </span
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Privacy Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/cookiepolicy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Cookie Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/communityguidelines"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Community Guidelines</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, and </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://help.match.com/hc/en-us/articles/6991442293787-Dating-Safety-Tips"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Safety Tips</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, and (iii) any Additional Terms Upon Purchase. If you do not accept
          and agree to be bound by <span class="GramE">all of</span> the terms
          of this Agreement, you are not entitled to use our Services.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >All pronouns and any variations thereof shall be deemed to refer to
          the masculine, feminine, neuter, singular or plural as the identity of
          the entities or persons referred to any require.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
            "
            class="privacySecHead"
            >20. ENTIRE AGREEMENT</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><i
            ><span
              lang="EN-US"
              style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
              >This Agreement supersedes any previous agreements or
              representations.</span></i><><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >These Terms, with the </span
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Privacy Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/cookiepolicy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Cookie Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/help/communityguidelines"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Community Guidelines</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, and </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://help.match.com/hc/en-us/articles/6991442293787-Dating-Safety-Tips"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Safety Tips</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >, and any Additional Terms Upon Purchase, contain the entire
          agreement between you and Introw regarding the use of our Services.
          The Terms supersede all previous agreements, representations, and
          arrangements between us, written or oral. If any provision of these
          Terms is held invalid, illegal, or otherwise unenforceable, the
          remainder of the Terms shall continue in full force and effect. The
          failure of the Company to exercise or enforce any right or provision
          of these Terms shall not constitute a waiver of such right or
          provision. You agree that your Introw account is non-transferable and
          <span class="GramE">all of</span> your rights to your account and its
          content terminate upon your death, unless otherwise provided by law.
          Any rights and licenses granted hereunder may not be transferred or
          assigned by you but may be assigned by us without restriction. No
          agency, partnership, joint venture, fiduciary or other special
          relationship or employment is created
          <span class="GramE">as a result of</span> these Terms, and you may not
          make any representations on behalf of or bind Introw in any
          manner.</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <
          ><span
            lang="DE"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              color: #fff;
              mso-ansi-language: DE;
            "
            class="privacySecHead"
            >21. SPECIAL STATE TERMS</span
          ><
        ><
          ><span
            lang="EN-US"
            style="
              font-size: 16pt;
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ><>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Special terms apply in Arizona, California, Colorado, Connecticut,
          Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, Rhode
          Island, and Wisconsin</span
        ><span
          lang="EN-US"
          style="
            font-family: 'Gill Sans Nova';
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <u style="text-underline: #BCBEC0"
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >For subscribers residing in New York:</span
          ></u
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >The Services do not guarantee any number of
          &quot;referrals&quot;—rather, the functionality of the Services is
          such that the subscriber can view as many profiles as he/she would
          <span class="GramE">like;</span><o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Upon notice in writing and delivered to Introw inc
          <span class="GramE">l,Sag</span> Harbor NY 11963, USA, subscribers may
          place their subscription on hold for up to one year;<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >How your information is used and how you may access your information
          is set forth in our </span
        ><span class="Hyperlink1"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.match.com/masp/en-us/privacy"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >Privacy Policy</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >;<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You may review the New York Dating Service Consumer Bill of Rights </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;" href="https://www.nysenate.gov/legislation/laws/GBS/394-C"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >here</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >; For subscribers residing in North Carolina:<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >You may review the North Carolina Buyer</span
        ><span dir="RTL"></span
        ><span
          lang="AR-SA"
          dir="RTL"
          style="
            font-family: 'Arial Unicode MS', sans-serif;
            mso-ascii-font-family: 'Gill Sans Nova';
            mso-hansi-font-family: 'Gill Sans Nova';
            color: #BCBEC0;
          "
          ><span dir="RTL"></span>’</span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >s Rights </span
        ><span class="Hyperlink0"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              color: #BCBEC0;
              mso-style-textfill-fill-color: #BCBEC0;
              mso-style-textfill-fill-alpha: 100%;
            "
            ><a style="text-decoration: underline;text-decoration-color:#00EDE2;"
              href="https://www.ncleg.gov/EnactedLegislation/Statutes/PDF/BySection/Chapter_66/GS_66-120.pdf"
              ><span
                style="
                  color: #BCBEC0;
                  mso-style-textfill-fill-color: #BCBEC0;
                  mso-style-textfill-fill-alpha: 100%;
                "
                ><span
                  style="
                    color: #BCBEC0;
                    mso-style-textfill-fill-color: #BCBEC0;
                    mso-style-textfill-fill-alpha: 100%;
                  "
                  >here</span
                ></span
              ></a
            ></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <u style="text-underline: #BCBEC0"
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >For subscribers residing in Illinois, New York, North Carolina, and
            <span class="GramE">Ohio :</span></span
          ></u
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 10pt;
          margin-left: 36pt;
          text-indent: -25pt;
          line-height: normal;
          mso-list: l3 level1 lfo4;
        "
      >
        <span
          lang="EN-US"
          style="
            font-family: Helvetica;
            mso-fareast-font-family: Helvetica;
            mso-bidi-font-family: Helvetica;
            color: #BCBEC0;
          "
          ><span style="mso-list: Ignore"
            >•<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ></span
        ><span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Our Services are widely available in the United States—if you believe
          that you have moved outside a location where we provide the Services,
          please contact us in writing delivered to Introw Group
          <span class="GramE">Legal,Sag</span> Harbor NY 11963, USA, and we will
          work with you to provide alternative services or a refund.<o:p></o:p
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <u style="text-underline: #BCBEC0"
          ><span
            lang="EN-US"
            style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
            >For subscribers residing in Arizona, California, Colorado,
            Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina,
            Ohio, Rhode Island, and Wisconsin:</span
          ></u
        ><span class="None"
          ><span
            lang="EN-US"
            style="
              font-family: 'Gill Sans Nova';
              mso-fareast-font-family: Helvetica;
              mso-bidi-font-family: Helvetica;
              color: #BCBEC0;
            "
            ><o:p></o:p></span
        ></span>
      </p>

      <p
        class="privacyTxt Default"
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 20pt;
          margin-left: 0cm;
          line-height: normal;
        "
      >
        <span
          lang="EN-US"
          style="font-family: 'Gill Sans Nova'; color: #BCBEC0"
          >Your Right to Cancel—You may cancel your subscription, without
          penalty or obligation, at any time prior to midnight of the third
          business day following the date you subscribed.
          <span class="GramE">In the event that</span> you die before the end of
          your subscription period, your estate shall be entitled to a refund of
          that portion of any payment you had made for your subscription which
          is allocable to the period after your death. In the event that you
          become disabled (such that you are unable to use our Services) before
          the end of your subscription period, you shall be entitled to a refund
          of that portion of any payment you had made for your subscription
          which is allocable to the period after your disability by providing
          the Company notice in the same manner as you request a refund as
          described above in Section 8.</span
        ><span lang="EN-US" style="font-family: 'Gill Sans Nova'"
          ><o:p></o:p
        ></span>
      </p>
    </div>
  </div>
`;

const TermAndConditions = ({
  onAcceptClicked,
  showAcceptButton = true,
  showCrossButton = false,
  maxheight = 50,
  showPageName = false,
  showBackButton = false,
  onBackClicked,
}) => {
  const nav = useNavigate();
  // const myProfileID = localStorage.getLocalStorageItem(
  //   localStorageKeys.profileId,
  // );
  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  return (
    <>
      <FullPageGridLayout height="85vh">
        {showBackButton && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end">
              <Grid item className={styles.logoContainer} marginTop={0}>
                {/* <Typography textAlign='center' className={styles.logoContainer}> */}
                <img
                  src={AppLogoMin}
                  alt={'App icon'}
                  className={styles.logo}
                  style={{
                    width: `90%`,
                    // marginBottom: '40px',
                    // marginTop: '-30px',
                    height: `${scaleByRatio(110)}px`,
                  }}
                />
                {/* </Typography> */}
              </Grid>
            </Grid>
            <Grid
              //marginTop={-5}
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Grid item className={styles.logoContainer}>
                <img
                  marginBottom={'2px'}
                  src={ArrowLeft}
                  alt={'Back icon'}
                  width={scaleByRatio(12)}
                  height={scaleByRatio(12)}
                  className={styles.logo}
                  onClick={onBackClicked}
                />
              </Grid>
              <Grid item>
                <Typography
                  textAlign="left"
                  className={styles.back}
                  onClick={onBackClicked}
                  style={{
                    fontSize: `${scaleByRatio(12)}px`,
                    paddingLeft: `${scaleByRatio(10)}px`,
                    paddingBottom: `${scaleByRatio(5)}px`,
                  }}>
                  Back
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {showPageName && (
          <Grid container display={'flex'} justifyContent={'space-between'}>
            <Grid
              // gap={5}
              // container
              direction="row"
              // justifyContent="space-between"
              // alignItems="flex-end"
              xs={10}>
              <Typography
                textAlign="left"
                className={styles.pageName}
                style={{
                  fontSize: `${scaleByRatio(21)}px`,
                  textAlign: `left`,
                  // color:'#04597B'
                  color: '#ECA43C',
                }}>
                Terms and Conditions
              </Typography>
            </Grid>
            <Grid >
              {showCrossButton && (
                <Grid
                  item
                  display={'flex'}
                  justifyContent={'flex-end'}
                  marginBottom={'-10px'}
                  // marginLeft={'50px'}
                  paddingRight={'5px'}
                  sx={{
                    top: 0,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                  onClick={handleClose}
                  className={styles.closeIcon}>
                  <Typography marginTop={'5px'}>
                    <img src={close_icon} alt="Close" />
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          marginLeft={'20px'}
          // marginRight={'20px'}
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end"
          className={styles.main}
          style={{ maxHeight: `${maxheight}vh` }}>
          <div dangerouslySetInnerHTML={{ __html: rawHtml }}></div>
        </Grid>
        {showAcceptButton && (
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="flex-end">
            <Grid item>
              <CustomButton
                text={'Accept'}
                variant={ButtonVariant.contained}
                type={ButtonType.primary}
                onClick={() => {
                  onAcceptClicked();
                }}
              />
            </Grid>
          </Grid>
        )}
      </FullPageGridLayout>
    </>
  );
};

export default TermAndConditions;
