import React, { useState, useEffect } from 'react';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import {
  useGetMyProfile,
  useUpdateMyProfile,
  useGetListOfPendingQuestions,
  useGetAllQuestions,
  useGetQuestionDetailsById,
  usePostMultiChoiceAnswer,
  usePatchMultiChoiceAnswer,
} from '../../services/mutations';
import {
  pendingFieldsMapping,
  routeUrls,
  Q_Identifier_WhoWouldYouLikeToMeet,
  AnswerType,
  pendingFieldsName,
} from '../../utils/constants';
import PrivacyPolicy from '../../organism/PrivacyPolicy';
import ProfileDataName from '../../organism/ProfileDataName';
import ProfileDataEmail from '../../organism/ProfileDataEmail';
import ProfileDataDOB from '../../organism/ProfileDataDOB';
import Questionare from '../../organism/Questionaire';
import NotificationPreference from '../../organism/NotificationPreference';
import Loader from '../../molecule/Loader';
import {
  localStorageKeys,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { useNavigate } from 'react-router-dom';
import LetsGetStarted from '../../organism/LetsGetStarted';
import { Grid } from '@mui/material';
import { scaleByRatio } from '../../utils/helper';
import Box from '@mui/material/Box';

const MyProfileUpdate = (props) => {
  const getMyProfile = useGetMyProfile();
  const updateMyProfile = useUpdateMyProfile();
  const localStorageItem = useLocalStorage();
  const getListOfPendingQuestions = useGetListOfPendingQuestions();
  const getAllQuestions = useGetAllQuestions();
  const getQuestionDetailsById = useGetQuestionDetailsById();
  const postMultiChoiceAnswer = usePostMultiChoiceAnswer();
  const patchMultiChoiceAnswer = usePatchMultiChoiceAnswer();

  const nav = useNavigate();

  const [payloadData, setPayloadData] = useState({
    user: {
      first_name: '',
      last_name: '',
      email: '',
    },
    age: null,
    dob: null,
    profile_pic: '',
    is_dater: true,
    push_notification_enabled: false,
    email_notification_enabled: false,
    sms_notification_enabled: false,
  });
  const [pendingFields, setPendingFields] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [formattedDob, setFormattedDob] = useState(null);
  const [error, setError] = useState('');
  const [age, setAge] = useState(0);
  const [question, setQuestion] = useState(null);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const getMyProfileData = () => {
    setLoading(true);
    let data = {};
    getMyProfile.mutate(data, {
      onSuccess: (response) => {
        console.log('getMyProfile onSuccess: ', response);
        let _pendingFields = response?.data?.payload?.pending_fields;

        let _payload = response?.data?.payload;
        let finalPayload = {
          user: {
            first_name: _payload?.user?.first_name || '',
            last_name: _payload?.user?.last_name || '',
            email: _payload?.user?.email || '',
          },
          dob: _payload?.age,
          profile_pic: _payload?.profile_pic,
          is_dater: _payload?.is_dater,
          push_notification_enabled: _payload?.push_notification_enabled,
          email_notification_enabled: _payload?.email_notification_enabled,
          sms_notification_enabled: _payload?.sms_notification_enabled,
        };
        setFirstName(finalPayload.user.first_name);
        setLastName(finalPayload.user.last_name);
        setEmail(_payload?.user?.email);
        setDob(_payload?.age < 18 ? '' : _payload?.age || '');
        setPayloadData(finalPayload);
        setLoading(false);
        setPendingFields(_pendingFields);
        setNotificationEnabled(_payload?.push_notification_enabled);
        localStorageItem.setLocalStorageItem(
          localStorageKeys.profileId,
          _payload?.id,
        );
        if (_pendingFields === pendingFieldsName.dob) {
          getPendingQuestionByIdentifier(Q_Identifier_WhoWouldYouLikeToMeet);
        }
        if (!_pendingFields) {
          localStorageItem.setLocalStorageItem(
            localStorageKeys.isLoggedIn,
            true,
          );
          setActiveStep(pendingFieldsMapping.lets_get_started);
          // nav(routeUrls.myProfileQuestion)
        }
      },
      onError: (err) => {
        console.log('getMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };
  const getPendingQuestionByIdentifier = (identifier) => {
    setLoading(true);
    getAllQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getPendingQuestionByIdentifier onSuccess: ', response);
          let questionList = response?.data?.payload?.results;
          let _question = questionList.find((question) => {
            return question.identifier === identifier;
          });
          if (_question) {
            setQuestion(_question);
            console.log('_question: ', _question);
            let qData = {
              id: _question?.id,
            };
            getQuestionDetailsById.mutate(qData, {
              onSuccess: (response) => {
                setLoading(false);
                setQuestionDetails(response?.data?.payload);
                console.log('question details: ', response?.data?.payload);
                return true;
              },
              onError: (error) => {
                setLoading(false);
                console.log('getQuestionDetailsById error: ', error);
                return false;
              },
            });
          } else {
            setLoading(false);
            return false;
          }
        },
        onError: (err) => {
          setLoading(false);
          console.log('getPendingQuestionByIdentifier error:', err);
          return false;
        },
      },
    );
  };
  useEffect(() => {
    if (localStorageItem.getLocalStorageItem(localStorageKeys.accessToken)) {
      getMyProfileData();
    } else {
      nav(routeUrls.join);
    }
  }, []);

  useEffect(() => {
    if (pendingFields) {
      // if(pendingFields === pendingFieldsName.dob && question && questionDetails){
      //     setActiveStep(pendingFieldsMapping[pendingFieldsName.q_whom_meet])
      // }else{
      //     setActiveStep(pendingFieldsMapping[pendingFields])
      // }
      setActiveStep(pendingFieldsMapping[pendingFields]);
      // if(pendingFields === pendingFieldsName.dob){
      //     setActiveStep(pendingFieldsMapping[pendingFieldsName.q_whom_meet])
      // }else{
      //     setActiveStep(pendingFieldsMapping[pendingFields])
      // }
    }
  }, [pendingFields, question, questionDetails]);

  useEffect(() => {
    if (activeStep === pendingFieldsMapping.q_whom_meet) {
      getPendingQuestionByIdentifier(Q_Identifier_WhoWouldYouLikeToMeet);
    }
  }, [activeStep]);

  const onFirstNameChange = (_name) => {
    setFirstName(_name);
    if (_name === '') {
      setError('Please enter your First Name');
    } else {
      setError('');
    }
  };

  const onLastNameChange = (_name) => {
    setError('');
    setLastName(_name);
  };

  const onNextNameClicked = () => {
    setError('');
    if (firstName.length === 0) {
      setError('Please enter your First Name.');
    } else if (lastName.length === 0) {
      setError('Please enter your Last Name.');
    } else {
      setError('');
      if (
        firstName === payloadData.user.first_name &&
        lastName === payloadData.user.last_name
      ) {
        setActiveStep(activeStep + 1);
      } else {
        payloadData.user.first_name = firstName;
        payloadData.user.last_name = lastName;
        setPayloadData(payloadData);
        setPendingFields('');
        updateProfile(payloadData);
      }
    }
  };

  const ValidateEmail = (email) => {
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const onEmailChange = (_email) => {
    setEmail(_email);
  };

  const onNextEmailClicked = () => {
    if (ValidateEmail(email)) {
      setError('');
      if (email === payloadData.user.email) {
        setActiveStep(activeStep + 1);
      } else {
        payloadData.user.email = email;
        setPayloadData(payloadData);
        setPendingFields('');
        updateProfile(payloadData);
      }
    } else {
      setError('Please enter a valid email');
    }
  };

  function calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  const onDateChange = (_date) => {
    console.log('onDateChange: ', _date);
    //console.log('onchange formatted date:', new Date(_date))

    setDob(_date);
    // setAge(calculate_age(new Date(_date)))
  };

  const onNextClickedDOB = () => {
    if (!dob) {
      setError('Please provide your age.');
      return false;
    } else if (dob < 18) {
      setError('Your age should be greater than 18.');
      return false;
    } else if (dob > 95) {
      setError('Your age should be less than 95.');
      return false;
    } else {
      if (dob) {
        // let objectDate = new Date(dob);
        // let day = objectDate.getDate();
        // let month = objectDate.getMonth()+1;
        // if(parseInt(month)<10){
        //     month='0'+month
        // }
        // let year = objectDate.getFullYear();

        // let formattedDate = year+"-"+month+"-"+day
        // setFormattedDob(formattedDate)
        setError('');
        if (dob === payloadData.age) {
          setActiveStep(activeStep + 1);
        } else {
          payloadData.age = dob;
          setPayloadData(payloadData);
          setPendingFields('');
          updateProfile(payloadData);
        }
      }
    }
  };

  const onBackClicked = () => {
    console.log('onBackClicked');
    setActiveStep(activeStep - 1);
  };
  const onNotificationSelected = (status) => {
    console.log('onNotificationSelected status:', status);
    setNotificationEnabled(status);
  };
  const onNextClickedNotification = () => {
    payloadData.push_notification_enabled = notificationEnabled;
    payloadData.email_notification_enabled = notificationEnabled;
    payloadData.sms_notification_enabled = notificationEnabled;
    setPayloadData(payloadData);
    setPendingFields('');
    updateProfile(payloadData);
  };
  const updateProfile = (data) => {
    console.log('updateProfile payloadData: ', payloadData);
    setLoading(true);
    updateMyProfile.mutate(data, {
      onSuccess: (response) => {
        console.log('updateMyProfile onSuccess: ', response);
        let _payload = response?.data?.payload;
        let finalPayload = {
          user: {
            first_name: _payload?.user?.first_name,
            last_name: _payload?.user?.last_name,
            email: _payload?.user?.email,
          },
          dob: _payload?.dob,
          profile_pic: _payload?.profile_pic,
          is_dater: _payload?.is_dater,
          push_notification_enabled: _payload?.push_notification_enabled,
          email_notification_enabled: _payload?.email_notification_enabled,
          sms_notification_enabled: _payload?.sms_notification_enabled,
        };
        setPayloadData(finalPayload);
        let _pendingFields = response?.data?.payload?.pending_fields;
        // setPendingFields(_pendingFields)
        setActiveStep(activeStep + 1);

        // if(_pendingFields === pendingFieldsName.dob){
        //     getPendingQuestionByIdentifier(Q_Identifier_WhoWouldYouLikeToMeet)
        // }
        // if(!_pendingFields){
        //    // nav(routeUrls.myProfileQuestion)
        //     setActiveStep(pendingFieldsMapping.lets_get_started)
        // }
        setLoading(false);
      },
      onError: (err) => {
        console.log('updateMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };

  const onNextClickedQuestion = (answers, answerType) => {
    setError('');
    if (answers && answerType === AnswerType.MC && answers.length > 0) {
      setLoading(true);
      //call api to store answers
      let multiChoiceAnswerData = [];
      answers.forEach((element) => {
        let answerObj = {
          value: '',
          visible: true,
          answer: element,
        };
        //find value
        let selectedAnswerObj = questionDetails?.question_choices.find(
          (choice) => {
            return choice.id === element;
          },
        );
        answerObj.value = selectedAnswerObj.value;
        multiChoiceAnswerData.push(answerObj);
      });
      let data = {
        id: question.id,
        multiChoiceAnswerData: multiChoiceAnswerData,
      };
      postMultiChoiceAnswer.mutate(data, {
        onSuccess: (response) => {
          setLoading(false);
          console.log('postMultiChoiceAnswer onsuccess: ', response);
          setActiveStep(activeStep + 1);
        },
        onError: (err) => {
          if (err?.response?.status === 400) {
            //answer.id = question?.patchId;
            patchMultiChoiceAnswer.mutate(data, {
              onSuccess: (response) => {
                setLoading(false);
                setActiveStep(activeStep + 1);
              },
              onError: (error) => {
                setLoading(false);
                setError('Something went wrong, please try again');
              },
            });
          } else {
            setLoading(false);
            setError('Something went wrong, please try again');
          }
        },
      });
    } else {
      setError('Please provide an answer.');
    }
  };

  const onNextClickedLetsGetStarted = () => {
    nav(routeUrls.myProfileQuestion);
  };

  const myProfilePrivacyOnBackClicked = () => {
    nav(routeUrls.join);
  };

  return (
    <>
      <Loader open={loading} />
      <CentralContentPageLayout>
        {activeStep === 0 && (
          <PrivacyPolicy
            onAcceptClicked={() => {
              setActiveStep(activeStep + 1);
            }}
            showBackbutton={true}
            onBackClicked={myProfilePrivacyOnBackClicked}
          />
        )}
        {activeStep === 1 && (
          <CentralGridLayout>
            <ProfileDataName
              first_name={firstName}
              last_name={lastName}
              onFirstNameChange={onFirstNameChange}
              onLastNameChange={onLastNameChange}
              onNextClicked={onNextNameClicked}
              error={error}
              onBackClicked={onBackClicked}
            />
          </CentralGridLayout>
        )}
        {activeStep === 2 && (
          <CentralGridLayout>
            <ProfileDataEmail
              email={email}
              onEmailChange={onEmailChange}
              onNextClicked={onNextEmailClicked}
              error={error}
              onBackClicked={onBackClicked}
            />
          </CentralGridLayout>
        )}
        {/* {activeStep === 3 && question && questionDetails && (
                    <CentralGridLayout>
                        <Questionare
                        questionText={questionDetails?.name}
                        answerType={questionDetails?.choice_type}
                        onBackClicked={onBackClicked}
                        error={error}
                        handleOnNextClicked={onNextClickedQuestion}
                        answerOptions={questionDetails?.question_choices}
                        />
                    </CentralGridLayout>
                )} */}
        {activeStep === pendingFieldsMapping.age && (
          <CentralGridLayout>
            <ProfileDataDOB
              dob={dob}
              onDateChange={onDateChange}
              onNextClicked={onNextClickedDOB}
              error={error}
              onBackClicked={onBackClicked}
              age={age}
            />
          </CentralGridLayout>
        )}
        {activeStep === pendingFieldsMapping.push_notification_enabled && (
          <CentralGridLayout>
            <NotificationPreference
              isEnabled={notificationEnabled}
              onNextClicked={onNextClickedNotification}
              error={error}
              onBackClicked={onBackClicked}
              onClickCallBack={onNotificationSelected}
            />
          </CentralGridLayout>
        )}
        {activeStep === pendingFieldsMapping.lets_get_started && (
          <CentralGridLayout>
            <LetsGetStarted
              onBackClicked={onBackClicked}
              onNextClicked={onNextClickedLetsGetStarted}
            />
          </CentralGridLayout>
        )}
      </CentralContentPageLayout>
    </>
  );
};

export default MyProfileUpdate;
