import React from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import AppLogo from '../../assets/AppLogoMin.svg';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import { scaleByRatio } from '../../utils/helper';

const LetsGetStarted = ({ onNextClicked, onBackClicked }) => {
  return (
    <>
       <Grid container direction="row" justifyContent="center" alignItems="flex-end"  >
            <Grid item className={styles.logoContainer} marginTop={-2} marginBottom="10px">
                {/* <Typography textAlign='center' className={styles.logoContainer}> */}
                    <img src={AppLogo} alt={'App icon'}
                    style={{
                        width:'90%',
                        height:`${scaleByRatio(110)}px`,
                    }}
                    />
                {/* </Typography> */}
            </Grid>
        </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item className={styles.logoContainer}>
          <img
            src={ArrowLeft}
            alt={'Back icon'}
            className={styles.logo}
            onClick={onBackClicked}
            width={scaleByRatio(12)}
            height={scaleByRatio(12)}
          />
        </Grid>
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.back}
            onClick={onBackClicked}
            style={{
              fontSize: `${scaleByRatio(12)}px`,
              paddingLeft: `${scaleByRatio(10)}px`,
              paddingBottom: `${scaleByRatio(2)}px`,
            }}>
            Back
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.heading}
          >
            {/* What's your Email? */}
            Let's get started
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap" style={{ paddingLeft:'15px' , paddingRight:'15px'}}>
  <Grid item style={{marginRight:'10px' , marginTop: '10px'}}>
    <Typography
      textAlign="left"
      className={styles.stepsCounter}
      style={{
        fontSize: `${scaleByRatio(24)}px`,
        lineHeight: `${scaleByRatio(35)}px`,
      }}>
      <span style={{ color: '#E66433' }}>{`1  `}</span>
    </Typography>
  </Grid>
  <Grid item xs>
    <Typography
      textAlign="left"
      className={styles.steps}
      style={{
        fontSize: `${scaleByRatio(24)}px`,
        lineHeight: `${scaleByRatio(35)}px`,
      }}>
      Set up your basic profile (a few minutes)
    </Typography>
  </Grid>
</Grid>

      <Grid
              marginLeft={'40px'}

        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Typography
          textAlign="left"
          className={styles.then}
        >
          then
        </Typography>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap" style={{ paddingLeft:'15px' , paddingRight:'15px'}}>
  <Grid item style={{ marginRight: '10px', marginTop: '10px'}}>
    <Typography
      textAlign="left"
      className={styles.stepsCounter}
      style={{
        fontSize: `${scaleByRatio(24)}px`,
        lineHeight: `${scaleByRatio(35)}px`,
      }}>
      <span style={{ color: '#E66433' }}>{`2 `}</span>
    </Typography>
  </Grid>
  <Grid item xs>
    <Typography
      textAlign="left"
      className={styles.steps}
      style={{
        fontSize: `${scaleByRatio(24)}px`,
        lineHeight: `${scaleByRatio(35)}px`,
      }}>
      Invite a friend or two to join you on your journey
    </Typography>
  </Grid>
</Grid>

      <Grid
        marginLeft={'40px'}
        marginRight={'60px'}

        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Typography
          textAlign="left"
          className={styles.smallText}
          >
          You'll be able to refine and enhance your profile over time. 
          <br />
          <br />
          Just note, your profile won't become active until atleast one friend is connected to you
        </Typography>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item>
          <CustomButton
            text={'Start'}
            customStyle='yes'
            variant={ButtonVariant.contained}
            type={ButtonType.nextButton}
            onClick={onNextClicked}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LetsGetStarted;
