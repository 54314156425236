import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import ArrowLeft from '../../assets/ArrowLeft.svg';

import List from '@mui/material/List';
import ListItemCheckBox from '../../molecule/ListItemCheckbox';
import { AnswerType } from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import ListItemRadio from '../../molecule/ListItemRadio';

let listItemClass = {
  display: 'flex',
  height: '48px',
  padding: '8px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '73px',
  borderBottom: '1px solid #F6F7F7',
};

let textClass = {
  color: '#ffffff', // Ensure the color value is correctly prefixed with '#'
  fontFamily: 'Gill Sans Nova', // Update the font family to 'Gill Sans Nova'
  fontSize: '14px', // Update the font size to 14px
  fontWeight: '500', // Update the font weight to 600
  lineHeight: '20.08px', // Add the line height property with a value of 20.08px
  letterSpacing: '0.1rem', // Add the letter spacing property with a value of 10%
  textTransform: 'uppercase', // Add the text transform property with a value of 'uppercase
};

const KidsPreference = ({
  arrQuestion,
  onNextClicked,
  error,
  showBackButton = true,
  onBackClicked,
  showSteps = false,
  currentStep,
  totalSteps,
  showAppIcon = true,
}) => {
  const [questionArr, setQuestionArr] = useState(arrQuestion);
  const [kidsPreferenceEror, setKidsPreferenceError] = useState(error);
  const handleNextClicked = () => {
    let err = '';
    for (let i = 0; i < questionArr.length; i++) {
      let question = questionArr[i];
      if (
        question?.choice_type === AnswerType.MC ||
        question?.choice_type === AnswerType.SC
      ) {
        if (question?.setStoredAnswerDetails.length === 0) {
          err = 'Please provide your input.';
          break;
        }
      } else {
        if (!question?.setStoredAnswerDetails.length) {
          err = 'Please provide your input.';
          break;
        }
      }
    }
    if (err.length > 0) {
      setKidsPreferenceError(err);
    } else {
      onNextClicked(questionArr);
    }
  };
  const handleAnswerChecked = (val, _question_id, _question_choice_type) => {
    //setLoading(false)
    setKidsPreferenceError('');
    console.log('handleAnswerChecked val: ', val);
    console.log('handleAnswerChecked _question_id: ', _question_id);
    console.log(
      'handleAnswerChecked _question_choice_type: ',
      _question_choice_type,
    );
    let selectedQuestionindex = questionArr.findIndex(
      (question) => question.id === _question_id,
    );
    if (_question_choice_type === AnswerType.SC) {
      let arrAnswerSC = [];
      arrAnswerSC.push(val);
      let arr = [];
      arr = [...questionArr];
      //questionArr[selectedQuestionindex].setStoredAnswerDetails = arrAnswerSC;
      arr[selectedQuestionindex].setStoredAnswerDetails = arrAnswerSC;
      console.log('handleAnswerChecked arrQuestion:', arr);
      setQuestionArr(arr);
    }
    if (_question_choice_type === AnswerType.MC) {
      const currentIndex =
        arrQuestion[selectedQuestionindex].setStoredAnswerDetails.indexOf(val);
      const newChecked = [
        ...arrQuestion[selectedQuestionindex].setStoredAnswerDetails,
      ];

      if (currentIndex === -1) {
        newChecked.push(val);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      let arr = [];
      arr = [...questionArr];

      arr[selectedQuestionindex].setStoredAnswerDetails = newChecked;
      setQuestionArr(arr);
    }
    //setLoading(true)
  };

  return (
    <>
      {showAppIcon && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer} marginTop={4}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                width: `90%`,
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
      )}
      {showBackButton && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logoback}
              onClick={onBackClicked}
              width={scaleByRatio(12)}
              height={scaleByRatio(12)}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={onBackClicked}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(2)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>
      )}
      {questionArr &&
        questionArr.map((_question, _index) => {
          return (
            <>
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="flex-end">
                <Grid item>
                  <Typography
                    textAlign="left"
                    className={styles.question}
                    style={{
                      // color:`${(_index===0)?'#F46B1B':'#00597B'}`,
                      color: `${_index === 0 ? '#76d9ff' : '#76d9ff'}`,
                      fontSize: `${scaleByRatio(22)}px`,
                      lineHeight: `${scaleByRatio(28.64)}px`,
                      letterSpacing: '0.05em', 
                      marginLeft: '15px'
                    }}>
                    {_question.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="flex-end">
                {(_question.choice_type === AnswerType.MC ||
                  _question.choice_type === AnswerType.SC) && (
                  <List dense sx={{ width: '100%' }}>
                    {_question.question_choices &&
                      _question.question_choices.map((question_choice) => {
                        return (
                          <ListItemRadio
                            value={question_choice.id}
                            onChange={(val) =>
                              handleAnswerChecked(
                                val,
                                _question.id,
                                _question.choice_type,
                              )
                            }
                            isChecked={
                              questionArr[
                                _index
                              ]?.setStoredAnswerDetails?.indexOf(
                                question_choice.id,
                              ) !== -1
                            }
                            id={question_choice.id}
                            displayText={question_choice.value}
                            listItemClass={listItemClass}
                            textClass={textClass}
                          />
                        );
                      })}
                  </List>
                )}
              </Grid>
            </>
          );
        })}
      {kidsPreferenceEror && kidsPreferenceEror?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(18)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {kidsPreferenceEror}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item xs={6}>
          {showSteps && (
            <Typography
              textAlign={'left'}
              className={styles.pagination}
              style={{
                padding: `${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                fontSize: `${scaleByRatio(25)}px`,
                lineHeight: `${scaleByRatio(34)}px`,
              }}>
              {currentStep}/{totalSteps}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="right"
          style={{
            display: 'flex',
          }}>
          <CustomButton
            text={'Next'}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={() => handleNextClicked()}
            customStyle={`yes`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default KidsPreference;
