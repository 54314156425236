import { Button, Typography, FormControl } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper';

const CheckBoxWithLabel = ({
    isChecked=false, 
    labelText,
    handleChange,
    labelPosition='end',
    isDisabled=false
}) => {
    return ( 
        <FormControl variant='standard'>
            <FormControlLabel
                control={
                    <Checkbox
                     disabled={isDisabled}
                     checked={isChecked}
                     onChange={(evt)=>{
                        handleChange(evt.target.checked)
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: scaleByRatio(20) }, color: "#F93",
                        '&.Mui-checked': {
                        color: "#F93",
                        },
                    }}
                     />
                }
                label={
                    <Typography className={styles.description} style={{
                        fontSize:`${scaleByRatio(12)}px`,
                        lineHeight: `${scaleByRatio(32)}px`
                    }}>{labelText}</Typography>
                }
                labelPlacement={labelPosition}
            />
        </FormControl>
     );
}
 
export default CheckBoxWithLabel;