import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import InputField from '../../atoms/InputField';

const DaterRequestIntrow = ({
  isOpen = false,
  onConfirmDaterRequest,
  onCancelClick,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleCancel = () => {
    setOpen(false);
    onCancelClick();
  };
  const handleConfirmClick = () => {
    onConfirmDaterRequest();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        className={styles.heading}
        style={{
          fontSize: `${scaleByRatio(32)}px`,
          lineHeight: `${scaleByRatio(38)}px`,
        }}>
        Request this{' '}
        <span
          style={{
            color: '#F46B1B',
          }}>
          Introw
        </span>
        ?
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{ background: '#FFFFFF' }}>
          <Grid
            item
            xs={12}
            justifyContent="left"
            style={{
              display: `flex`,
            }}>
            <Typography
              fullwidth
              textAlign={'left'}
              className={styles.subHeading}
              style={{
                fontSize: `${scaleByRatio(16)}px`,
              }}>
              Are you sure you want to request this user to an introw?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}>
        <Button
          onClick={handleCancel}
          autoFocus
          className={`${styles.button}  `}
          style={{
            fontSize: `${scaleByRatio(18)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
          }}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          className={`${styles.button} ${styles.primary}`}
          style={{
            fontSize: `${scaleByRatio(18)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
          }}>
          Confirm Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DaterRequestIntrow;
