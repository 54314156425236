import React, { useState } from 'react'
import styles from './index.module.scss'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { scaleByRatio } from '../../utils/helper'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { AnswerType } from '../../utils/constants'
import './styles.scss'
import InputField from '../../atoms/InputField'
import DownArrow from '../../assets/DownArrow.svg'
import UpArrow from '../../assets/UpArrow.svg'

const DatingPreferenceSelectDropDown = ({
  question,
  question_color,
  id,
  identifier,
  type,
  choice,
  selectedValue,
  handleOnChangeCallBack,
  question_type
}) => {
  const [selectValue, setSelectValue] = useState(selectedValue)
  const [showData, setShowData] = useState(false)

  const handleChange = (value) => {
    setSelectValue(value)
    handleOnChangeCallBack(value, id, identifier, question_type, AnswerType.Select)
    setShowData(false) // Hide dropdown after selecting an option
  }

  return (
    <>
      <Grid container direction="row" justifyContent="left" alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.question}
            style={{
              color: `${question_color}`,
              lineHeight: `${scaleByRatio(24)}px`,
              letterSpacing: '0.02em'
            }}
          >
            {question}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        padding={'20px'}
        paddingBottom={'15px'}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'flex-end'}
        onClick={() => setShowData(!showData)}
        style={{ cursor: 'pointer' }}
        mt={-5}
      >
        {showData ? (
          <img src={UpArrow} alt="dropIcon"  width={'16px'}
          height={'14px'} />
        ) : (
          <img src={DownArrow} alt="upIcon" width={'16px'}
          height={'14px'}/>
        )}
      </Grid>
      <Grid container direction="row" justifyContent="left" alignItems="flex-end">
        <FormControl fullWidth sx={{ m: 1, maxWidth: '80%' }}>
          <InputField
            value={`${selectValue} MILES`}
            onClick={() => setShowData(!showData)} // Toggle dropdown on click
            class_name={styles.inputHeight}
            tabIndex="1"
            readOnly
          />
          {showData && (
            <div className={styles.dropdown_menu} style={{ border: '1px solid #C4C4C4', borderRadius: '4px', backgroundColor: '#F1F6F8', position: 'absolute', zIndex: 1, width: '100%' }}>
              {choice &&
                choice.map((_choice) => (
                  <MenuItem
                    key={_choice.value}
                    value={_choice.value}
                    onClick={() => handleChange(_choice.value)}
                  >
                    {_choice.label}
                  </MenuItem>
                ))}
            </div>
          )}
        </FormControl>
      </Grid>
    </>
  )
}

export default DatingPreferenceSelectDropDown
