import * as React from 'react';
import Chip from '@mui/material/Chip';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';

const CustomChip = ({
  avatar = null,
  label = '',
  clickable = true,
  handleClick,
}) => {
  return (
    <Chip
      avatar={avatar}
      label={label}
      onClick={handleClick}
      clickable={clickable}
      sx={{
        '& .MuiChip-label': {
          justifyContent: 'center',
          alignItems: 'center',
          gap: `${scaleByRatio(10)}px`,
          // color: "#05232E",
          // color: '#cccccc',
          color: '#ffffff',
          textAlign: 'center',
          fontFamily: 'Gill Sans Nova',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          // fontSize: `${scaleByRatio(14)}px`,
          fontSize: `${scaleByRatio(16)}px`,
        },
      }}
      className={`${styles.chipLabels} customChipCls`}
    />
  );
};

export default CustomChip;
