import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import ImageGallery from '../../organism/ImageGallery'
import { scaleByRatio } from '../../utils/helper';

const MyProfilePhotos = ({
    arrImages=[],
    clickable,
    handleChipClick
}) => {
    const [showGallery, setShowGallery] = useState(false)
    const handleImageClick = ()=>{
        let chip={
            QuestionID: null,
            clickable: true,
            identifier: "photos",
            label: "",
            type: "Profile"
        }
        if(clickable){
            handleChipClick(chip)
        }else{
            setShowGallery(true)
        }
        
    }


    return ( 
    <>
    
    <Grid container direction={"row"} alignItems={"flex-start"} style={{marginTop:`${scaleByRatio(30)}px`}}>
        {arrImages && arrImages.length > 0 && arrImages.map((image)=>{
            return (
                <Typography textAlign='center' className={styles.imageContainer} style={{cursor:'pointer'}} onClick={()=>handleImageClick()}>
                    {/* <img src={image.image} alt="profile photos" width={scaleByRatio(72)} height={scaleByRatio(72)} className={styles.image}/> */}
                <img src={image.image} alt="profile photos" width={scaleByRatio(75)} height={scaleByRatio(75)} className={styles.image}/>
                </Typography>
               
            )
        })} 
        
    </Grid>
    {showGallery && (
        <ImageGallery
        images={arrImages}
        showGallery={showGallery}
        onCloseCallBack={setShowGallery}
        />
    )}
    </> 
    );
}
 
export default MyProfilePhotos;